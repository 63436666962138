import axios from 'axios';
import { TASK_API_URL } from '../../utils/settings';

class TaskServices {
    getTaskList(category, userid) {
        return axios.get(`${TASK_API_URL}/${category}/${userid}/tasks`);
    }

    addTask(data) {
        return axios({
          method: 'POST',
          url: `${TASK_API_URL}/addtask`,
          data,
        });
    }

    updateTask(data) {
        return axios({
          method: 'PUT',
          url: `${TASK_API_URL}/updatetask`,
          data,
        });
    }
}

export default new TaskServices();