import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import HistoryIcon from "@material-ui/icons/History";
import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';
import {
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Button,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { green } from '@material-ui/core/colors';
import DiagnosisHistory from "../diagnosis-history.component";
import employeeServices from '../../../../services/employee/employee.services';

const EDEDiagnosis = ({ formik, currentUser, employeeDetails, ...otherProps }) => {
  const [codesList, setCodesList] = useState([]);
  const [openDiagnosisHistory, setOpenDiagnosisHistory] = useState(false);
  const [diagnosisHistoryList, setDiagnosisHistoryList] = useState([]);
  const handleDiagnosisHistoryClose = () => {
    setOpenDiagnosisHistory(false);
  };
  const getDiagnosisCodes = () => {
    employeeServices
      .getDiagCodeList()
      .then((response) => {
        if (response && response.data) {
          setCodesList(response.data);
        }
      })
      .catch();
  };
   const handleHistoryDiagnosis = (value) => {
     employeeServices
       .getDiagnosisHistoryList(
         employeeDetails.employee.id,
         employeeDetails.claimNumber
       )
       .then((response) => {
         if (response && response.data) {
           setDiagnosisHistoryList(response.data);
         }
       })
       .catch();
     setOpenDiagnosisHistory(true);
   };

  useEffect(() => {
    getDiagnosisCodes();
  }, []);

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={green[50]}
      bgcolor="#fffde7"
      >
        <CardHeader
          title="Diagnosis"
          color="text.secondary"
          component={Box}
          action={
            <>
              <Button
                startIcon={<HistoryIcon />}
                onClick={() => handleHistoryDiagnosis()}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              >
                History
              </Button>
            </>
          }
        />
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12}>
            <Autocomplete
              multiple
              id="codes-auto-search"
              name={`diagnosis`}
              value={
                ifNullAndUndefined(formik.values.diagnosis)
                  ? ""
                  : formik.values.diagnosis
              }
              getOptionSelected={(option, value) =>
                option.code + " - " + option.name ===
                value.code + " - " + value.name
              }
              getOptionLabel={(option) => option.code + " - " + option.name}
              options={codesList}
              filterSelectedOptions
              size="small"
              onChange={(event, value) => {
                formik.setFieldValue("diagnosis", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Code"
                  placeholder="Select Codes..."
                />
              )}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>
        </Grid>
      </Paper>
      <DiagnosisHistory
        diagnosisHistoryList={diagnosisHistoryList}
        openDiagnosisHistory={openDiagnosisHistory}
        handleDiagnosisHistoryClose={handleDiagnosisHistoryClose}
        handleHistoryDiagnosis={handleHistoryDiagnosis}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEDiagnosis);
