import React, {useState, forwardRef} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MaterialTable from 'material-table';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EmployeeService from "../../services/employee/employee.services";
import ChangePassword from "../common/change-password/change-password.component";
 

function StaffList({
    staffList,
    handleClickOpen,
    setOpen,
    getStaffs,
    props,
    ...otherProps
  }) {
    const tableIcons = {
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      
  };

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [userId, setUserId] = useState();

  const handleClickDelete = (id) => {
  if (window.confirm("Are you sure you wish to remove ?")) {
    EmployeeService.removeStackholder(id)
      .then((response) => {
        if (response && response.data) {
          getStaffs();
          alert(response.data);
        }
      })
      .catch();
  } 
  };

  const handleClickChangePwd = (userId) => {
    setUserId(userId);
    setOpenChangePassword(true);
  };
    return (
      <div>
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={[
            {
              title: "Name",
              field: "name",
              render: (staffList) =>
                staffList.name != null ? staffList.name : "",
            },
            {
              title: "Role",
              field: "title",
              render: (staffList) =>
                staffList.title != null ? staffList.title : "",
            },
            {
              title: "Email",
              field: "email",
              render: (staffList) =>
                staffList.email != null ? staffList.email : "",
            },
            {
              title: "Phone",
              field: "phone",
              render: (staffList) =>
                staffList.phone != null ? staffList.phone : "",
            },
            {
              title: "Address",
              field: "address",
              render: (staffList) =>
                staffList.address != null ? staffList.address : "",
            },
          ]}
          data={staffList}
          options={{
            search: true,
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: "bold",
            },
          }}
          actions={[
            {
              icon: EditIcon,
              tooltip: "Edit",
              onClick: (event, rowData) =>
                handleClickOpen("EDIT", rowData, setOpen),
            },
            {
              icon: VpnKeyIcon,
              tooltip: "Change Password",
              onClick: (event, rowData) => handleClickChangePwd(rowData.user.id),
              hidden: props.currentUser.role === 'ADMIN' ? false : true,
            },
            {
              icon: DeleteForeverIcon,
              tooltip: "Remove",
              onClick: (event, rowData) => handleClickDelete(rowData.user.id),
            },
          ]}
        />
         {openChangePassword && <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
        userId={userId}
      />}
      </div>
    );
  }

  export default StaffList;