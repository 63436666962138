import axios from 'axios';

import {STAFF_API_URL } from '../../utils/settings';

class StaffServices {
    getStaffList() {
        return axios.get(`${STAFF_API_URL}/fetchstaffs`);
    }

    createStaff(data) {
        return axios({
            method: 'POST',
            url: `${STAFF_API_URL}/addstaff`,
            data,
        });
    }

    updateStaff(data) {
        return axios({
            method: 'POST',
            url: `${STAFF_API_URL}/updatestaff`,
            data,
        });
    }
}

export default new StaffServices();
