import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { ifRole, ifNullAndUndefined } from "../../../../utils/common-utils";
import { ROLE } from "../../../../utils/settings";
import { useState, useEffect } from "react";
import {
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@material-ui/core";
// import { green } from "@material-ui/core/colors";
import HistoryIcon from "@material-ui/icons/History";
import EmployeeServices from "../../../../services/employee/employee.services";
import PriorCareHistory from "../prior-care-history.component";

const EDEPriorCare = ({ formik, currentUser, employeeDetails, ...otherProps }) => {
  const [showOtherTextField, setShowOtherTextField] = useState(false);
  const [priorCareHistoryList, setPriorCareHistoryList] = useState([]);
  const [openPriorCareHistory, setOpenPriorCaredHistory] = useState(false);

  useEffect(() => {
    if (formik.values.priorCareDetails[0].typeOfService === "Others") {
      setShowOtherTextField(true);
    }
  }, [formik.values.priorCareDetails[0].typeOfService]);
  
  const handleOtherSelect = (e) => {
    if (formik.values.priorCareDetails[0].typeOfService === "Others") {
      setShowOtherTextField(true);
    } else {
      setShowOtherTextField(false);
      formik.values.priorCareDetails[0].othersText = null;
    }
  };

  const fetchPriorCareHistories = (value) => {
    EmployeeServices.getPriorCareHistoryList(
      employeeDetails.employee.id,
      employeeDetails.claimNumber
    ).then((response) => {
      if (response && response.data) {
        setPriorCareHistoryList(response.data);
      }
    }).catch();
    setOpenPriorCaredHistory(true);
  };
  
  const handlePriorCareHistoryClose = () => {
    setOpenPriorCaredHistory(false);
  };

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={green[50]}
      bgcolor="#e9fafa"
      >
        <CardHeader
          title="Prior Care"
          color="text.secondary"
          component={Box}
          action={
            <>
              <Button
                startIcon={<HistoryIcon />}
                onClick={() => fetchPriorCareHistories()}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              >
                History
              </Button>
            </>
          }
        />

        <Grid container spacing={1}>
          <Grid item sm={12} xs={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel htmlFor="prior-care-select">
                Select a Service
              </InputLabel>
              <Select
                native
                value={
                  ifNullAndUndefined(
                    formik.values.priorCareDetails[0].typeOfService
                  )
                    ? ""
                    : formik.values.priorCareDetails[0].typeOfService
                }
                onChange={formik.handleChange}
                onClick={handleOtherSelect}
                label="Select a Service"
                inputProps={{
                  name: "priorCareDetails[0].typeOfService",
                  id: "prior-care-select",
                }}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              >
                <option value="" />
                <option value="Imaging">Imaging</option>
                <option value="Chiropractic">Chiropractic</option>
                <option value="Surgery">Surgery</option>
                <option value="PhysicalTherapy">Physical Therapy</option>
                <option value="Psychology">Psychology</option>
                <option value="Others">Others</option>
              </Select>
            </FormControl>
          </Grid>
          {showOtherTextField ? (
            <Grid item sm={6} xs={6}>
              <TextField
                label="Others Text"
                name={`priorCareDetails[0].othersText`}
                value={
                  ifNullAndUndefined(
                    formik.values.priorCareDetails[0].othersText
                  )
                    ? ""
                    : formik.values.priorCareDetails[0].othersText
                }
                fullWidth
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
              />
            </Grid>
          ) : (
            ""
          )}
          <Grid item sm={12} xs={6}>
            <TextField
              label="File Upload"
              name={`priorCareDetails[0].fileUpload`}
              fullWidth
              variant="outlined"
              size="small"
              type="file"
              InputLabelProps={{ shrink: true }}
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={(event) => {
                formik.setFieldValue(
                  "priorCareDetails[0].fileUpload",
                  event.target.files[0]
                );
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      {openPriorCareHistory && (
        <PriorCareHistory
          priorCareHistoryList={priorCareHistoryList}
          openPriorCareHistory={openPriorCareHistory}
          handlePriorCareHistoryClose={handlePriorCareHistoryClose}
          fetchPriorCareHistories={fetchPriorCareHistories}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEPriorCare);
