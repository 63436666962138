import { InterceptorActionTypes } from './interceptor.types';

const INITIAL_STATE = {
  AXIOS_INTERCEPTOR: null,
};

const interceptorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InterceptorActionTypes.SET_AXIOS_INTERCEPTOR:
      return {
        ...state,
        AXIOS_INTERCEPTOR: action.payload,
      };

    default:
      return state;
  }
};

export default interceptorReducer;
