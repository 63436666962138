import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import DialogTitleWithClose from "../../common/dialog-title-close.component";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
function DiagnosisHistory({
  diagnosisHistoryList,
  openDiagnosisHistory,
  handleDiagnosisHistoryClose,
  handleHistoryDiagnosis,
  ...otherProps
}) {
  const classes = useStyles();
  const handleClickDelete = (id) => {
    employeeServices
      .removeHistory("diagnosis", id)
      .then((response) => {
        if (response && response.data) {
          handleHistoryDiagnosis();
          alert(response.data);
        }
      })
      .catch();
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openDiagnosisHistory}
        onClose={handleDiagnosisHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Diagnosis History"}</u>
        </DialogTitle>
        <DialogTitleWithClose
          onClose={() => handleDiagnosisHistoryClose()}
        ></DialogTitleWithClose>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Added On</b>
                  </TableCell>
                  <TableCell>
                    <b>Details</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diagnosisHistoryList != null &&
                diagnosisHistoryList.length > 0 ? (
                  diagnosisHistoryList.map((diagnosisHistory) => (
                    <TableRow key={diagnosisHistory.id}>
                      <TableCell>
                        {diagnosisHistory.addedOn !== null
                          ? moment(diagnosisHistory.addedOn).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>

                      <TableCell>
                        {diagnosisHistory.diagnosisCodeLookUp != null &&
                        diagnosisHistory.diagnosisCodeLookUp.length > 0
                          ? diagnosisHistory.diagnosisCodeLookUp.map(
                              (codelookup) => (
                                <>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography className={classes.heading}>
                                        {codelookup.name !== null
                                          ? codelookup.name
                                          : "--"}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        <TableContainer component={Paper}>
                                          <Table
                                            className={classes.table}
                                            aria-label="simple table"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>
                                                  <b>Category</b>
                                                </TableCell>
                                                <TableCell>
                                                  <b>Code</b>
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow key={codelookup.id}>
                                                <TableCell>
                                                  {codelookup.category !== null
                                                    ? codelookup.category
                                                    : "--"}
                                                </TableCell>
                                                <TableCell>
                                                  {codelookup.code !== null
                                                    ? codelookup.code
                                                    : "--"}
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </>
                              )
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() =>
                              handleClickDelete(diagnosisHistory.id)
                            }
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DiagnosisHistory;
