import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";

function PriorCareHistory({
  priorCareHistoryList,
  openPriorCareHistory,
  handlePriorCareHistoryClose,
  fetchPriorCareHistories,
  ...otherProps
}) {
  const handleClickDelete = (id) => {
if (
  window.confirm(
    "Are you sure you wish to delete this item,As it will also remove  prior injuries history ?"
  )
) {
  employeeServices
    .removeHistory("priorCare", id)
    .then((response) => {
      if (response && response.data) {
        fetchPriorCareHistories();
        alert(response.data);
      }
    })
    .catch();
} else {
  console.log("no");
}
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openPriorCareHistory}
        onClose={handlePriorCareHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Prior Care History</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Added On</b>
                  </TableCell>
                  <TableCell>
                    <b>Service Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priorCareHistoryList != null &&
                priorCareHistoryList.length > 0 ? (
                  priorCareHistoryList.map((pcdHistory) => (
                    <TableRow key={pcdHistory.id}>
                      <TableCell>
                        {pcdHistory.addedOn !== null
                          ? moment(pcdHistory.addedOn).format("YYYY-MM-DD")
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {pcdHistory.typeOfService !== null
                          ? pcdHistory.typeOfService
                          : "--"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleClickDelete(pcdHistory.id)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePriorCareHistoryClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default PriorCareHistory;
