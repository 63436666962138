import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from 'moment';
import DialogTitleWithClose from '../../common/dialog-title-close.component';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";
function ClaimsHistory({
  claimsHistoryList,
  openClaimsHistory,
  handleClaimsHistoryClose,
  handleHistoryClaims,
  ...otherProps
}) {
    const handleClickDelete = (id) => {
      employeeServices
        .removeHistory("claim", id)
        .then((response) => {
          if (response && response.data) {
            handleHistoryClaims();
            alert(response.data);
          }
        })
        .catch();
    };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openClaimsHistory}
        onClose={handleClaimsHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Claims History"}</u>
        </DialogTitle>
        <DialogTitleWithClose
          onClose={() => handleClaimsHistoryClose()}
        ></DialogTitleWithClose>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Added On</b>
                  </TableCell>
                  <TableCell>
                    <b>Claim Number</b>
                  </TableCell>
                  <TableCell>
                    <b>P&S/Anticipated Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Reason</b>
                  </TableCell>
                  <TableCell>
                    <b>Accepted Body Parts</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claimsHistoryList != null && claimsHistoryList.length > 0 ? (
                  claimsHistoryList.map((claimsHistory) => (
                    <TableRow key={claimsHistory.id}>
                      <TableCell>
                        {claimsHistory.addedOn !== null
                          ? moment(claimsHistory.addedOn).format("YYYY-MM-DD")
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {claimsHistory.claimNumber !== null
                          ? claimsHistory.claimNumber
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {claimsHistory.psAnticipatedDate !== null
                          ? moment(claimsHistory.psAnticipatedDate).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {claimsHistory.reason !== null
                          ? claimsHistory.reason
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {claimsHistory.acceptedBodyParts !== null
                          ? claimsHistory.acceptedBodyParts
                          : "--"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleClickDelete(claimsHistory.id)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ClaimsHistory;
