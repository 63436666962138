import axios from 'axios';

import {PROVIDER_API_URL,RFA_API_URL } from '../../utils/settings';

class ProviderServices {
  getProviderList() {
    return axios.get(`${PROVIDER_API_URL}/fetchproviders`);
  }

  createProvider(data) {
    return axios({
      method: 'POST',
      url: `${PROVIDER_API_URL}/addprovider`,
      data,
    });
  }

  updateProvider(data) {
    return axios({
      method: 'POST',
      url: `${PROVIDER_API_URL}/updateprovider`,
      data,
    });
  }

  createRFA(data)
  {
    return axios({
      method:'POST',
      url:`${RFA_API_URL}/form`,
      data
    })
  }
}

export default new ProviderServices();
