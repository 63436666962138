import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import AddIcon from '@material-ui/icons/Add';

import AttorneyManageInfo from './attorney-manage-info.component';
import AttorneyList from './attorney-list.component';
import ClaimsAdministratorServices from '../../services/claims-administrator/claims-administrator.services';

const Attorney = (props) => {
    const [attorneyInfo, setAttorneyInfo] = useState({
        caId : null,
        caEmail : '',
        caFax : '',
        caName : '',
        caPhone : '',
        caAddress: '',
        caTitleType:''
    });

    const [attorneyList, setAttorneyList] = useState([]);
    const [open, setOpen] = useState(false);
    const [manageType, setManageType] = useState('ADD');

    const getAttorneys = () => {
        ClaimsAdministratorServices.getClaimsAdministratorList('ATTORNEY')
          .then((response) => {
            setAttorneyList(response.data);
          })
          .catch();
    };

    useEffect(() => {
        getAttorneys();
    }, []);

    const handleClickOpen = (manageType, caData) => {
        if (manageType === 'EDIT') {
          const {
            id,
            email,
            name,
            phone,
            fax,
            address,
            titleType,
          } = caData;
    
          setAttorneyInfo({
            caId : id,  
            caEmail : email,
            caName : name,
            caPhone : phone,
            caFax : fax,
            caAddress: address,
            caTitleType: titleType,
          });
        }
    
        setOpen(true);
        setManageType(manageType);
    };

    const handleClose = () => {
        setOpen(false);
        setAttorneyInfo({
            caId : null,  
            caEmail : '',
            caName : '',
            caPhone : '',
            caFax : '',
            caAddress: '',
            caTitleType:'',
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAttorneyInfo({
          ...attorneyInfo,
          [name]: value,
        });
    };

    const createAttorney = () => {
        const {
            caEmail,
            caName,
            caPhone,
            caFax,
            caAddress,
            caTitleType,
        } = attorneyInfo;
    
        const attorneyPayload = {
            email : caEmail,
            name : caName,
            phone : caPhone,
            title : 'ATTORNEY',
            fax : caFax,
            address: caAddress,
            titleType: caTitleType,
            userId: props.currentUser.id
        };
    
        ClaimsAdministratorServices.createClaimsAdministrator(attorneyPayload).then((response) => {
            window.alert(response.data.message);
            getAttorneys();
            handleClose();
        });
    };

    const updateAttorney = () => {
        const {
            caId,
            caEmail,
            caName,
            caPhone,
            caFax,
            caAddress,
            caTitleType,
        } = attorneyInfo;
    
        const attorneyPayload = {
            id : caId,
            email : caEmail,
            name : caName,
            phone : caPhone,
            title : 'ATTORNEY',
            fax : caFax,
            address: caAddress,
            titleType: caTitleType,
            userId: props.currentUser.id
        };
    
        ClaimsAdministratorServices.updateClaimsAdministrator(attorneyPayload).then((response) => {
            window.alert(response.data.message);
            getAttorneys();
            handleClose();
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (manageType === 'ADD') {
            createAttorney();
        } else {
            updateAttorney();
        }
    };

    return (
      <div>
        <Card square>
          <CardHeader
            action={
              <Tooltip title="Add Attorney">
                <IconButton
                  aria-label="Add Attorney"
                  onClick={() => handleClickOpen("ADD", "")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
            title="Attorney"
          />

          <AttorneyList
            attorneyList={attorneyList}
            handleClickOpen={handleClickOpen}
            getAttorneys={getAttorneys}
            props={props}
          />
        </Card>

        <AttorneyManageInfo
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          attorneyInfo={attorneyInfo}
          manageType={manageType}
        />
      </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
  });
  
  export default connect(mapStateToProps)(Attorney);