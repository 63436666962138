import React, {forwardRef} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';

import MaterialTable from 'material-table';

 import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

 import EmployeeService from "../../services/employee/employee.services";

function InsuranceCompanyList({
    insuranceCompanyList,
    handleClickOpen,
    setOpen,
    getInsuranceCompanies,
    ...otherProps
}) {
    const tableIcons = {
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        
    };
    const handleClickDelete = (id) => {
    if (window.confirm("Are you sure you wish to remove ?")) {
      EmployeeService.removeInsurance(id)
        .then((response) => {
          if (response && response.data) {
            getInsuranceCompanies();
            alert(response.data);
          }
        })
        .catch();
    } 
    };
    return (
      <div>
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={[
            {
              title: "Name",
              field: "name",
              render: (insuranceCompanyList) =>
                insuranceCompanyList.name != null
                  ? insuranceCompanyList.name
                  : "",
            },
            {
              title: "Email",
              field: "email",
              render: (insuranceCompanyList) =>
                insuranceCompanyList.email != null
                  ? insuranceCompanyList.email
                  : "",
            },
            {
              title: "Phone",
              field: "phone",
              render: (insuranceCompanyList) =>
                insuranceCompanyList.phone != null
                  ? insuranceCompanyList.phone
                  : "",
            },
            {
              title: "Address",
              field: "address",
              render: (insuranceCompanyList) =>
                insuranceCompanyList.address != null
                  ? insuranceCompanyList.address
                  : "",
            },
          ]}
          data={insuranceCompanyList}
          options={{
            search: true,
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: "bold",
            },
          }}
          actions={[
            {
              icon: EditIcon,
              tooltip: "Edit",
              onClick: (event, rowData) =>
                handleClickOpen("EDIT", rowData, setOpen),
            },
            {
              icon: DeleteForeverIcon,
              tooltip: "Remove",
              onClick: (event, rowData) => handleClickDelete(rowData.id),
            },
          ]}
        />
      </div>
    );
}

export default InsuranceCompanyList;