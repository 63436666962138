import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Snackbar,
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  Paper,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';

import ClaimsAdministratorService from '../../services/claims-administrator/claims-administrator.services';
import EmployerService from '../../services/employer/employer.services';
import EmployeeService from '../../services/employee/employee.services';
import ProviderService from '../../services/provider/provider.services';
import InsuranceCompanyServices from '../../services/insurance-company/insurance-company.services';

import ProviderManageInfo from '../provider/provider-manage-info.component';
import AttorneyManageInfo from '../claims-administrator/attorney-manage-info.component';
import ClaimsExaminerManageInfo from '../claims-administrator/claims-examiner-manage-info.component';
import NurseCaseManagerManageInfo from '../claims-administrator/nurse-case-manager-manage-info.component';
import EmployerManageInfo from '../employer/employer-manage-info.component';

const EmployeeManageInfo = ({
  empManageOpen,
  setEmpManageOpen,
  getEmployees,
  props,
  ...otherProps
}) => {
  const [employeeInfo, setEmployeeInfo] = useState({
    createdBy: props.currentUser.id,
    empFName: '',
    empLName: '',
    empDOB: '',
    empEmail: '',
    empBPC: '',
    empProvider: null,
    empProviderSpecialty: '',
    empEmployer: null,
    empAttorney: null,
    empClaimExaminer: null,
    empNurseCaseManager: null,
    empClaimNum: '',
    empDOI: '',
  });

  const [attorneySearchVal, setAttorneySearchVal] = useState([]);
  const [claimExaminerSearchVal, setClaimExaminerSearchVal] = useState([]);
  const [nurseSearchVal, setNurseSearchVal] = useState([]);
  const [employerSearchVal, setEmployerSearchVal] = useState([]);
  const [providerSearchVal, setProviderSearchVal] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //-----provider add functionality states and function---------
  const [providerOpen, setProviderOpen] = useState(false);
  const [providerInfo, setProviderInfo] = useState({
    asActive: 1,
    asProfilePicId: null,
    createdBy: props.currentUser.id,
    asUserId: null,
    asFirstName: '',
    asLastName: '',
    asProTitle: '',
    asProSpeciality: '',
    asProType: '',
    asPreferredName: '',
    asGender: 'M',
    asNpin: '',
    asStreet1: '',
    asStreet2: '',
    asCity: '',
    asState: '',
    asCountry: '',
    asZipCode: '',
    asEmail: '',
    asPhone: '',
  });

  const handleProviderClose = () => {
    setProviderOpen(false);
    setProviderInfo({
      asActive: 1,
      asProfilePicId: null,
      createdBy: props.currentUser.id,
      asUserId: null,
      asFirstName: '',
      asLastName: '',
      asProTitle: '',
      asProSpeciality: '',
      asProType: '',
      asPreferredName: '',
      asGender: 'M',
      asNpin: '',
      asStreet1: '',
      asStreet2: '',
      asCity: '',
      asState: '',
      asCountry: '',
      asZipCode: '',
      asEmail: '',
      asPhone: '',
    });
  };

  const handleProviderChange = (event) => {
    const { name, value } = event.target;
    setProviderInfo({
      ...providerInfo,
      [name]: value,
    });
  };

  const createProvider = () => {
    const {
      asActive,
      asProfilePicId,
      createdBy,
      asFirstName,
      asLastName,
      asProTitle,
      asProSpeciality,
      asProType,
      asPreferredName,
      asGender,
      asNpin,
      asStreet1,
      asStreet2,
      asCity,
      asState,
      asCountry,
      asZipCode,
      asEmail,
      asPhone,
    } = providerInfo;

    const providerPayload = {
      active: asActive,
      city: asCity,
      country: asCountry,
      firstName: asFirstName,
      lastName: asLastName,
      providerTitle: asProTitle,
      specialty: asProSpeciality,
      providerType: asProType,
      preferredName: asPreferredName,
      phone: asPhone,
      email: asEmail,
      profilePicId: asProfilePicId,
      gender: asGender,
      npin: asNpin,
      state: asState,
      street1: asStreet1,
      street2: asStreet2,
      zipcode: asZipCode,
      userId: createdBy,
    };

    ProviderService.createProvider(providerPayload).then((response) => {
      window.alert(response.data.message);
      getProviderList();
      handleProviderClose();
    });
  };

  const handleProviderSubmit = (event) => {
    event.preventDefault();
    createProvider();
  };
  // -----------provider add functionality states and function end---------

  // ----------- Attorney add functionality and functions start -----------
  const [attorneyOpen, setAttorneyOpen] = useState(false);
  const [attorneyInfo, setAttorneyInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
  });
  
  const handleAttorneyClose = () => {
    setAttorneyOpen(false);
    setAttorneyInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
    });
  };

  const handleAttorneyChange = (event) => {
    const { name, value } = event.target;
    setAttorneyInfo({
      ...attorneyInfo,
      [name]: value,
    });
  };

  const createAttorney = () => {
    const { caEmail, caName, caPhone, caFax, caAddress } = attorneyInfo;

    const attorneyPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      title: 'ATTORNEY',
      fax: caFax,
      address: caAddress,
      userId: props.currentUser.id,
    };

    ClaimsAdministratorService.createClaimsAdministrator(attorneyPayload).then(
      (response) => {
        window.alert(response.data.message);
        getAttorneys();
        handleAttorneyClose();
      }
    );
  };

  const handleAttorneySubmit = (event) => {
    event.preventDefault();
    createAttorney();
  };

  // ----------- Attorney add functionality and functions ends ------------

  // ----------- Claim Examiner add functionality and functions start --------- 
  const [ceOpen, setCEOpen] = useState(false);
  const [insuranceList, setInsuranceList] = useState([]);
  const [claimsExaminerInfo, setClaimsExaminerInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
    caInsuranceCompanies: [],
  });

  const getInsuranceCompanies = () => {
    InsuranceCompanyServices.getInsuranceCompanyList().then((response) => {
      setInsuranceList(response.data);
    }).catch();
  }; 

  const handleCEClose = () => {
    setCEOpen(false);
    setClaimsExaminerInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
      caInsuranceCompanies: [],
    });
  };

  const handleCEChange = (event) => {
    const { name, value } = event.target;
    setClaimsExaminerInfo({
      ...claimsExaminerInfo,
      [name]: value,
    });
  };

  const createClaimsExaminer = () => {
    const { caEmail, caName, caPhone, caFax, caAddress, caInsuranceCompanies } =
      claimsExaminerInfo;

    const claimsExaminerPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      fax: caFax,
      title: 'CLAIMEXAMINER',
      address: caAddress,
      userId: props.currentUser.id,
      insuranceCompanyList: caInsuranceCompanies.map((item) => ({
        id: item,
      })),
    };

    ClaimsAdministratorService.createClaimsAdministrator(claimsExaminerPayload).then((response) => {
      window.alert(response.data.message);
      getClaimsExaminers();
      handleCEClose();
    });
  };

  const handleCESubmit = (event) => {
    event.preventDefault();
    createClaimsExaminer();
  };
  // ----------- Claim Examiner add functionality and functions end --------- 

  // ----------- NCM add functionality and functions starts --------- 
  const [ncmOpen, setNCMOpen] = useState(false);
  const [nurseCaseManagerInfo, setNurseCaseManagerInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
  });

  const handleNCMClose = () => {
    setNCMOpen(false);
    setNurseCaseManagerInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
    });
  };

  const handleNCMChange = (event) => {
    const { name, value } = event.target;
    setNurseCaseManagerInfo({
      ...nurseCaseManagerInfo,
      [name]: value,
    });
  };

  const createNurseCaseManager = () => {
    const { caEmail, caName, caPhone, caFax, caAddress } = nurseCaseManagerInfo;

    const nurseCaseManagerPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      title: 'NURSECASEMANAGER',
      fax: caFax,
      address: caAddress,
      userId: props.currentUser.id,
    };

    ClaimsAdministratorService.createClaimsAdministrator(nurseCaseManagerPayload).then((response) => {
      window.alert(response.data.message);
      getNurseCaseManagers();
      handleNCMClose();
    });
  };

  const handleNCMSubmit = (event) => {
    event.preventDefault();
    createNurseCaseManager();
  };
  // ----------- NCM add functionality and functions end --------- 

  // ---------- Employer add functionality and functions start --------
  const [employerOpen, setEmployerOpen] = useState(false);
  const [employerInfo, setEmployerInfo] = useState({
    asEmpId:0,
    asName: '',
    asEmail: '',
    asPhone: '',
    asCompanyName:'',
    asTitle: '',
    asFax: '',
    asAddress: '',
  });

  const handleEmployerClose = () => {
    setEmployerOpen(false);
    setEmployerInfo({
      asName: '',
      asEmail: '',
      asPhone: '',
      asCompanyName:'',
      asTitle: '',
      asFax: '',
      asAddress: '',
    });
  };

  const handleEmployerChange = (event) => {
    const { name, value } = event.target;
    setEmployerInfo({
      ...employerInfo,
      [name]: value,
    });
  };

  const createEmployer = () => {
    const {
      asName,
      asEmail,
      asPhone,
      asCompanyName,
      asTitle,
      asFax,
      asAddress,
    } = employerInfo;

    const employerPayload = {
      name:asName,
      email:asEmail,
      phone:asPhone,
      companyName:asCompanyName,
      title:asTitle,
      fax:asFax,
      address:asAddress,
      userId: props.currentUser.id
    };

    EmployerService.createEmployer(employerPayload).then((response) => {
      window.alert(response.data.message);
      getEmployerList();
      handleEmployerClose();
    });
  };

  const handleEmployerSubmit = (event) => {
    event.preventDefault();
    createEmployer();
  };

  // ---------- Employer add functionality and functions end --------

  const handleSnackBarClose = (event, reason) => {
    setSnackBarOpen(false);
  };

  const getAttorneys = () => {
    ClaimsAdministratorService.getClaimsAdministratorList('ATTORNEY').then((response) => {
      setAttorneySearchVal(response.data);
    }).catch();
  };

  const getClaimsExaminers = () => {
    ClaimsAdministratorService.getClaimsAdministratorList('CLAIMEXAMINER').then((response) => {
      setClaimExaminerSearchVal(response.data);
    }).catch();
  };

  const getNurseCaseManagers = () => {
    ClaimsAdministratorService.getClaimsAdministratorList('NURSECASEMANAGER').then((response) => {
      setNurseSearchVal(response.data);
    }).catch();
  };

  const getEmployerList = () => {
    EmployerService.getEmployerList().then((response) => {
      setEmployerSearchVal(response.data);
    }).catch();
  };

  const getProviderList = () => {
    ProviderService.getProviderList()
      .then((response) => {
        setProviderSearchVal(response.data);
      })
      .catch();
  };

  useEffect(() => {
    getProviderList();
    getEmployerList();
    getAttorneys();
    getClaimsExaminers();
    getNurseCaseManagers();
  }, []);

  const handleClaimAdministratorAutoCompleteChange = (event, value) => {
    event.persist();
    let name = '';

    if (event.target.id.includes('attorney-auto-search')) {
      name = 'empAttorney';
    } else if (event.target.id.includes('claimexaminer-auto-search')) {
      name = 'empClaimExaminer';
    } else if (event.target.id.includes('nurse-auto-search')) {
      name = 'empNurseCaseManager';
    }

    setEmployeeInfo({
      ...employeeInfo,
      [name]: value,
    });
  };

  const handleProviderAutoCompleteChange = (event, value) => {
    setEmployeeInfo({
      ...employeeInfo,
      empProvider: value,
      empProviderSpecialty: value!=null?value.specialty:"",
    });
  };

  const handleEmployerAutoCompleteChange = (event, value) => {
    setEmployeeInfo({
      ...employeeInfo,
      empEmployer: value,
    });
  };

  const handleClose = () => {
    setEmployeeInfo({
      createdBy: props.currentUser.id,
      empFName: '',
      empLName: '',
      empDOB: '',
      empEmail: '',
      empBPC: '',
      empProvider: null,
      empProviderSpecialty: '',
      empEmployer: null,
      empAttorney: null,
      empClaimExaminer: null,
      empNurseCaseManager: null,
      empClaimNum: '',
      empDOI: '',
    });

    getEmployees();
    setEmpManageOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmployeeInfo({
      ...employeeInfo,
      [name]: value,
    });
  };

  const addEmployee = () => {
    const {
      createdBy,
      empDOB,
      empEmail,
      empFName,
      empLName,
      empBPC,
      empProvider,
      empEmployer,
      empAttorney,
      empClaimExaminer,
      empNurseCaseManager,
      empClaimNum,
      empDOI,
    } = employeeInfo;

    const employeePayload = {
      active: 1,
      userIdVal: createdBy,
      dob: empDOB,
      emailId: empEmail,
      firstName: empFName,
      lastName: empLName,
      bodyPartsCovered: empBPC,
      claimNumber: empClaimNum,
      userEmployeePojo: [
        {
          user: {
            id:
              empProvider != null && empProvider.user != null
                ? empProvider.user.id
                : 0,
          },
        },
        {
          user: {
            id:
              empEmployer != null && empEmployer.user != null
                ? empEmployer.user.id
                : 0,
          },
        },
        {
          user: {
            id:
              empAttorney != null && empAttorney.user != null
                ? empAttorney.user.id
                : 0,
          },
        },
        {
          user: {
            id:
              empClaimExaminer != null && empClaimExaminer.user != null
                ? empClaimExaminer.user.id
                : 0,
          },
        },
        {
          user: {
            id:
              empNurseCaseManager != null && empNurseCaseManager.user != null
                ? empNurseCaseManager.user.id
                : 0,
          },
        },
      ],
      claimsDetailList: [
        {
          claimNumber: empClaimNum,
          injuryDate: empDOI,
        },
      ],
    };

    if (employeeInfo.empFName === "") {
      setSnackbarMessage("Enter first name.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empLName === "") {
      setSnackbarMessage("Enter last name.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empDOB === "") {
      setSnackbarMessage("Enter DOB.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empEmail === "") {
      setSnackbarMessage("Enter Email Id.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empDOI === "") {
      setSnackbarMessage("Enter Date of injury.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empClaimNum === "") {
      setSnackbarMessage("Enter Claim Number.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empBPC === "") {
      setSnackbarMessage("Enter Body parts covered.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empProvider === null) {
      setSnackbarMessage("Select Medical provider.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empEmployer === null) {
      setSnackbarMessage("Select Employer.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empAttorney === null) {
      setSnackbarMessage("Select Attorney.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empClaimExaminer === null) {
      setSnackbarMessage("Select ClaimExaminer.");
      setSnackBarOpen(true);
      return;
    }
    if (employeeInfo.empNurseCaseManager === null) {
      setSnackbarMessage("Select Nurse Case Manager.");
      setSnackBarOpen(true);
      return;
    }

    EmployeeService.addEmployee(employeePayload).then((response) => {
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addEmployee();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={empManageOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create Employee</DialogTitle>
        <DialogContent>
          <form id="add_employee_form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  name="empFName"
                  label="First Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={employeeInfo.empFName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  name="empLName"
                  label="Last Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={employeeInfo.empLName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  name="empDOB"
                  label="Date of Birth"
                  type="date"
                  variant="outlined"
                  value={employeeInfo.empDOB}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="dense"
                  name="empEmail"
                  label="Email"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={employeeInfo.empEmail}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="dense"
                  name="empDOI"
                  label="Injury Date"
                  type="date"
                  variant="outlined"
                  value={employeeInfo.empDOI}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="dense"
                  name="empClaimNum"
                  label="Claim Number"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={employeeInfo.empClaimNum}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="dense"
                  name="empBPC"
                  label="Body Parts Covered"
                  multiline
                  rowsMax={4}
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={employeeInfo.empBPC}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={4} component={Box}>
                <Paper elevation={0} square p={0.5} component={Box}>
                  <CardHeader
                    title="Employer"
                    color="text.secondary"
                    p={1}
                    component={Box}
                    action={
                      <Tooltip title="Add Employer">
                        <IconButton
                          aria-label="Add Employer"
                          onClick={() => setEmployerOpen(true)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <Autocomplete
                    name="empEmployer"
                    value={employeeInfo.empEmployer}
                    getOptionSelected={(option, value) =>
                      option.companyName === value.companyName
                    }
                    getOptionLabel={(option) => option.companyName}
                    options={employerSearchVal}
                    onChange={handleEmployerAutoCompleteChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Employer" variant="outlined"/>
                    )}
                    size="small"
                  />
                </Paper>
              </Grid>
              <Grid item md={4} component={Box}>
                <Paper elevation={0} square p={0.5} component={Box}>
                  <CardHeader
                    title="Primary TP"
                    color="text.secondary"
                    p={1}
                    component={Box}
                    action={
                      <Tooltip title="Add Primary TP">
                        <IconButton
                          aria-label="Add Primary TP"
                          onClick={() => setProviderOpen(true)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <Autocomplete
                    name="empProvider"
                    value={employeeInfo.empProvider}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    options={providerSearchVal}
                    onChange={handleProviderAutoCompleteChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Primary TP" />
                    )}
                    size="small"
                  />
                </Paper>
              </Grid>
              <Grid item md={4} component={Box}>
                <Paper elevation={0} square p={0.5} component={Box}>
                  <CardHeader
                    title="Attorney"
                    color="text.secondary"
                    p={1}
                    component={Box}
                    action={
                      <Tooltip title="Add Attorney">
                        <IconButton
                          aria-label="Add Attorney"
                          onClick={() => setAttorneyOpen(true)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />

                  <Autocomplete
                    id="attorney-auto-search"
                    name="empAttorney"
                    value={employeeInfo.empAttorney}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={attorneySearchVal}
                    onChange={handleClaimAdministratorAutoCompleteChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Attorney" />
                    )}
                    size="small"
                  />
                </Paper>
              </Grid>

              <Grid item md={4} component={Box}>
                <Paper elevation={0} square p={0.5} component={Box}>
                  <CardHeader
                    title="Claims Administrator"
                    color="text.secondary"
                    p={1}
                    component={Box}
                    action={
                      <Tooltip title="Add Claims Administrator">
                        <IconButton
                          aria-label="Add Claims Administrator"
                          onClick={() => {
                            setCEOpen(true);
                            getInsuranceCompanies();
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />

                  <Autocomplete
                    id="claimexaminer-auto-search"
                    name="empClaimExaminer"
                    value={employeeInfo.empClaimExaminer}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={claimExaminerSearchVal}
                    onChange={handleClaimAdministratorAutoCompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Claims Administrator"
                      />
                    )}
                    size="small"
                  />
                </Paper>
              </Grid>
              <Grid item md={4} component={Box}>
                <Paper elevation={0} square p={0.5} component={Box}>
                  <CardHeader
                    title="Nurse Case Manager"
                    color="text.secondary"
                    p={1}
                    component={Box}
                    action={
                      <Tooltip title="Add NCM">
                        <IconButton
                          aria-label="Add NCM"
                          onClick={() => setNCMOpen(true)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />

                  <Autocomplete
                    id="nurse-auto-search"
                    name="empNurseCaseManager"
                    value={employeeInfo.empNurseCaseManager}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={nurseSearchVal}
                    onChange={handleClaimAdministratorAutoCompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Nurse Case Manager"
                      />
                    )}
                    size="small"
                  />
                </Paper>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      
      {employerOpen && <EmployerManageInfo
        handleClose={handleEmployerClose}
        open={employerOpen}
        handleChange={handleEmployerChange}
        handleSubmit={handleEmployerSubmit}
        employerInfo={employerInfo}
        manageType="ADD"
      />}
      {providerOpen && <ProviderManageInfo
        handleClose={handleProviderClose}
        open={providerOpen}
        handleChange={handleProviderChange}
        handleSubmit={handleProviderSubmit}
        providerInfo={providerInfo}
        manageType="ADD"
      />}
      {attorneyOpen && <AttorneyManageInfo
        handleClose={handleAttorneyClose}
        open={attorneyOpen}
        handleChange={handleAttorneyChange}
        handleSubmit={handleAttorneySubmit}
        attorneyInfo={attorneyInfo}
        manageType="ADD"
      />}
      {ceOpen && <ClaimsExaminerManageInfo
        handleClose={handleCEClose}
        open={ceOpen}
        handleChange={handleCEChange}
        handleSubmit={handleCESubmit}
        claimsExaminerInfo={claimsExaminerInfo}
        insuranceList={insuranceList}
        manageType="ADD"
      />}
      {ncmOpen && <NurseCaseManagerManageInfo
        handleClose={handleNCMClose}
        open={ncmOpen}
        handleChange={handleNCMChange}
        handleSubmit={handleNCMSubmit}
        nurseCaseManagerInfo={nurseCaseManagerInfo}
        manageType="ADD"
      />}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default EmployeeManageInfo;
