import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ProviderManageInfo from './provider-manage-info.component';
import ProviderList from './provider-list.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
//import CommonServices from '../../services/common/common.services';
import ProviderServices from '../../services/provider/provider.services';

const Provider = (props) => {
  const [providerInfo, setProviderInfo] = useState({
    asActive: 1,
    asProfilePicId: null,
    createdBy: props.currentUser.id,
    asUserId: null,
    asFirstName: '',
    asLastName: '',
    asProTitle: '',
    asProSpeciality: '',
    asProType: '',
    asPreferredName: '',
    asGender: 'M',
    asNpin: '',
    asStreet1: '',
    asStreet2: '',
    asCity: '',
    asState: '',
    asCountry: '',
    asZipCode: '',
    asEmail: '',
    asPhone: '',
    // asFacility: [],
  });
  const [providerList, setProviderList] = useState([]);
  //const [facilityList, setFacilityList] = useState([]);
  const [open, setOpen] = useState(false);
  const [manageType, setManageType] = useState('ADD');

  const getProviders = () => {
    ProviderServices.getProviderList(0)
      .then((response) => {
        setProviderList(response.data);
      })
      .catch();
  };

  /* const getFacilities = () => {
    CommonServices.getFacilityList()
      .then((response) => {
        setFacilityList(response.data);
      })
      .catch();
  }; */

  useEffect(() => {
   // getFacilities();
    getProviders();
  }, []);

  const handleClickOpen = (manageType, providerData) => {
    if (manageType === 'EDIT') {
      const {
        id,
        active,
        firstName,
        lastName,
        providerTitle,
        providerType,
        specialty,
        preferredName,
        gender,
        npin,
        street1,
        street2,
        city,
        state,
        country,
        zipcode,
        email,
        phone,
     //   facilityList,
        profilePicId,
      } = providerData;

      setProviderInfo({
        asActive: active,
        asProfilePicId: profilePicId,
        asUserId: id,
        asFirstName: firstName,
        asLastName: lastName,
        asProTitle: providerTitle,
        asProSpeciality: specialty,
        asProType: providerType,
        asPreferredName: preferredName,
        asGender: gender,
        asNpin: npin,
        asStreet1: street1,
        asStreet2: street2,
        asCity: city,
        asState: state,
        asCountry: country,
        asZipCode: zipcode,
        asEmail: email,
        asPhone: phone,
     //   asFacility: facilityList.map((item) => item.id),
      });
    }

    setOpen(true);
    setManageType(manageType);
  };

  const handleClose = () => {
    setOpen(false);
    setProviderInfo({
      asActive: 1,
      asProfilePicId: null,
      createdBy: props.currentUser.id,
      asUserId: null,
      asFirstName: '',
      asLastName: '',
      asProTitle: '',
      asProSpeciality: '',
      asProType: '',
      asPreferredName: '',
      asGender: 'M',
      asNpin: '',
      asStreet1: '',
      asStreet2: '',
      asCity: '',
      asState: '',
      asCountry: '',
      asZipCode: '',
      asEmail: '',
      asPhone: '',
     // asFacility: [],
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProviderInfo({
      ...providerInfo,
      [name]: value,
    });
  };

  const createProvider = () => {
    const {
      asActive,
      asProfilePicId,
      createdBy,
      asFirstName,
      asLastName,
      asProTitle,
      asProSpeciality,
      asProType,
      asPreferredName,
      asGender,
      asNpin,
      asStreet1,
      asStreet2,
      asCity,
      asState,
      asCountry,
      asZipCode,
      asEmail,
      asPhone,
     // asFacility,
    } = providerInfo;

    const providerPayload = {
      active: asActive,
      city: asCity,
      country: asCountry,
      firstName: asFirstName,
      lastName: asLastName,
      providerTitle: asProTitle,
      specialty :asProSpeciality,
      providerType : asProType,
      preferredName: asPreferredName,
      phone: asPhone,
      email: asEmail,
      profilePicId: asProfilePicId,
      gender: asGender,
      npin: asNpin,
      state: asState,
      street1: asStreet1,
      street2: asStreet2,
      zipcode: asZipCode,
      userId: createdBy,
     /*  facilityList: asFacility.map((item) => ({
        id: item,
      })), */
    };

    ProviderServices.createProvider(providerPayload).then((response) => {
      window.alert(response.data.message);
      getProviders();
      handleClose();
    });
  };

  const updateProvider = () => {
    const {
      asUserId,
      asActive,
      asProfilePicId,
      createdBy,
      asFirstName,
      asLastName,
      asProTitle,
      asProSpeciality,
      asProType,
      asPreferredName,
      asGender,
      asNpin,
      asStreet1,
      asStreet2,
      asCity,
      asState,
      asCountry,
      asZipCode,
      asEmail,
      asPhone,
    //  asFacility,
    } = providerInfo;

    const providerPayload = {
      id: asUserId,
      active: asActive,
      city: asCity,
      country: asCountry,
      firstName: asFirstName,
      lastName: asLastName,
      providerTitle: asProTitle,
      specialty :asProSpeciality,
      providerType : asProType, 
      preferredName: asPreferredName,
      phone: asPhone,
      email: asEmail,
      profilePicId: asProfilePicId,
      gender: asGender,
      npin: asNpin,
      state: asState,
      street1: asStreet1,
      street2: asStreet2,
      zipcode: asZipCode,
      userId: createdBy,
    /*   facilityList: asFacility.map((item) => ({
        id: item,
      })), */
    };

    ProviderServices.updateProvider(providerPayload).then((response) => {
      window.alert(response.data.message);
      getProviders();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (manageType === 'ADD') {
      createProvider();
    } else {
      updateProvider();
    }
  };

  return (
    <div>
      <Card square>
        <CardHeader
          action={
            <Tooltip title="Add Provider">
              <IconButton
                aria-label="Add Provider"
                onClick={() => handleClickOpen("ADD", "")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
          title="Provider"
        />

        <ProviderList
          providerList={providerList}
          handleClickOpen={handleClickOpen}
          getProviders={getProviders}
          props={props}
        />
      </Card>

      <ProviderManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        providerInfo={providerInfo}
        manageType={manageType}
        // facilityList={facilityList}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Provider);
