import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../../redux/user/user.selectors';

import {
  TextField,
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  // Grid,
  // Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';

import UroManageInfo from '../../../../uro/uro-manage-info.component';
import UroServices from '../../../../../services/uro/uro.services';

const EdeUro = ({ formik, currentUser, ...otherProps }) => {
  const [uroSearchVal, setUroSearchVal] = useState([]);
  const [uroInfo, setUroInfo] = useState({
    urId: null,
    urEmail: '',
    urFax: '',
    urName: '',
    urPhone: '',
    urAddress: '',
    urTitle:'',
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setUroInfo({
        urId: null,
        urEmail: '',
        urFax: '',
        urName: '',
        urPhone: '',
        urAddress: '',
        urTitle:'',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUroInfo({
      ...uroInfo,
      [name]: value,
    });
  };

  const createUro = () => {
    const {
         urEmail,
        urFax,
        urName,
        urPhone,
        urAddress,
        urTitle,
    } = uroInfo;

    const uroPayload = {
        email: urEmail,
        name: urName,
        phone: urPhone,
        title: urTitle,
        fax: urFax,
        address: urAddress,
        userId: currentUser.id,
    };

    UroServices.createUro(uroPayload).then((response) => {
      window.alert(response.data.message);
      getUroList();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createUro();
  };

  const getUroList = () => {
    UroServices.getUroList()
      .then((response) => {
        if (response && response.data) {
          setUroSearchVal(response.data);
        }
      })
      .catch();
  };

  useEffect(() => {
    getUroList();
  }, []);

  return (
    <>
    {/*   <Grid item md={3} component={Box} bgcolor={green[50]}>
        <Paper elevation={0} square p={0.5} component={Box} bgcolor={green[50]}> */}
          <CardHeader
            title="Uro"
            color="text.secondary"
            p={1}
            component={Box}
            action={
              <Tooltip title="Add Uro">
                <IconButton
                  // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                  aria-label="Add Uro"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <Autocomplete
            name="uro"
            value={formik.values.uro}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              `${option.name}`
            }
            options={uroSearchVal}
            onChange={(event, value) => {
              formik.setFieldValue('uro', value);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="URO" />
            )}
            size="small"
          />
      {/*   </Paper>
      </Grid> */}
      <UroManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        uroInfo={uroInfo}
        manageType="ADD"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EdeUro);
