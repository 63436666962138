import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Paper,
} from '@material-ui/core';

function EmployerManageInfo({
  open,
  handleClose,
  handleChange,
  handleSubmit,
  employerInfo,
  manageType,
  ...otherProps
}) {
  const {
    asName,
    asEmail,
    asPhone,
    asCompanyName,
    asTitle,
    asFax,
    asAddress,
  } = employerInfo;

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {manageType === "EDIT" ? "Manage Employer" : "Create Employer"}
        </DialogTitle>
        <DialogContent>
          <form id="employer_maanage_form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <Grid container spacing={1}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asTitle"
                          label="Title"
                          type="text"
                          fullWidth
                          value={asTitle}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="asName"
                          label="Name"
                          type="text"
                          fullWidth
                          value={asName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="emailid"
                          margin="dense"
                          name="asEmail"
                          label="Email"
                          type="email"
                          fullWidth
                          value={asEmail}
                          onChange={handleChange}
                          disabled={manageType === "EDIT"}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asPhone"
                          label="Phone"
                          type="text"
                          fullWidth
                          value={asPhone}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <Grid container spacing={1}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asCompanyName"
                          label="Company Name"
                          type="text"
                          fullWidth
                          value={asCompanyName}
                          onChange={handleChange}
                        />
                      </Grid>
                      {/* <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asTitle"
                          label="Title"
                          type="text"
                          fullWidth
                          value={asTitle}
                          onChange={handleChange}
                        />
                      </Grid> */}
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asFax"
                          label="Fax"
                          type="text"
                          fullWidth
                          value={asFax}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          margin="dense"
                          name="asAddress"
                          label="Address"
                          type="text"
                          fullWidth
                          value={asAddress}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default EmployerManageInfo;
