import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { ifRole, ifNullAndUndefined } from "../../../../utils/common-utils";
import { ROLE } from "../../../../utils/settings";

import {
  Tooltip,
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Button,
  Select,
  Chip,
  FormControl,
  InputLabel,
  Snackbar,
  MenuItem,
} from "@material-ui/core";
// import { green } from "@material-ui/core/colors";
import HistoryIcon from "@material-ui/icons/History";
import FlagIcon from "@material-ui/icons/Flag";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MPNManageInfo from "./mpn-manage-info.component";
import InterpretersAndTransporterManageInfo from "./interpreters-transporter-manage-info.component";
import TreatmentHistory from "../treatment-history.component";
import EmployeeServices from "../../../../services/employee/employee.services";
import employeeServices from "../../../../services/employee/employee.services";

const EDETreatment = ({
  formik,
  currentUser,
  employeeDetails,
  setFlagCheck,
  ...otherProps
}) => {
  const [openTreatmentHistory, setOpenTreatmentHistory] = useState(false);
  const [treatmentHistoryList, setTreatmentHistoryList] = useState([]);
  const [interpretersList, setInterpretersList] = useState([]);
  const [transportersList, setTransportersList] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [open, setOpen] = useState(false);
  const [openMPNDialog, setOpenMPNDialog] = useState(false);
  const [openMPNAutoComplete, setOpenMPNAutoComplete] = useState(false);
  const [mpnList, setMPNList] = useState([]);
  const loading = openMPNAutoComplete && mpnList.length === 0;
  const [PRupload,setPRupload]=useState(false)
  const [RFAupload,setRFAupload]=useState(false)
  const [snackBarOpen,setSnackBarOpen]=useState(false)

  const onChangeHandle = async value => { 
    EmployeeServices.getMPNListBySearchStr(value).then((response) => {
      if (response && response.data) {
        setMPNList(response.data);
      }
    });
  };

 const handleSnackBarClose=()=>{
setSnackBarOpen(false)
  }

  useEffect(() => {
    if (!openMPNAutoComplete) {
      setMPNList([]);
    }
  }, [openMPNAutoComplete]);

  const handleClickOpenMPNDialog = () => {
    setOpenMPNDialog(true);
  };

  const handleClickCloseMPNDialog = () => {
    setOpenMPNDialog(false);
  };

  const handleClickOpen = (type) => {
    setVendorType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchVendors();
  };

  const handleHistoryTreatment = (value) => {
    EmployeeServices.getTreatmentHistoryList(employeeDetails.employee.id, employeeDetails.claimNumber)
      .then((response) => {
        if (response && response.data) {
          setTreatmentHistoryList(response.data);
        }
      }).catch();
    setOpenTreatmentHistory(true);
  };

  const fetchVendors = () => {
    EmployeeServices.getVendersList("interpreters").then((response) => {
      if (response && response.data) {
        setInterpretersList(response.data);
      }
    }).catch();

    EmployeeServices.getVendersList("transporters").then((response) => {
      if (response && response.data) {
        setTransportersList(response.data);
      }
    }).catch();
  };

  const handleTreatmentHistoryClose = () => {
    setOpenTreatmentHistory(false);
  };

  // console.log(employeeDetails)
  
  const handlePR2=()=>{
    var employeeId= employeeDetails.employee.id
    const messagePayload = {
      senderUser: { id: currentUser.id },
      message: 'This message is to notify PR2 File Uploaded for ' +
      employeeDetails.employee.firstName +
      ' ' +
      employeeDetails.employee.lastName +
      " 's" +
      ' Claim Number: ' +
      employeeDetails.claimNumber +
      '  \n' +
      'Employee Id:' +employeeDetails.employee.id
        };
      employeeServices
      .pr2fileupload(messagePayload, employeeId)
      .then((response) => setSnackBarOpen(true));
      setFlagCheck('Y');
  }

  const handleRFA=()=>{
    var employeeId= employeeDetails.employee.id
    const messagePayload = {
      senderUser: { id: currentUser.id },
      message: 'This message is to notify RFA File Uploaded for ' +
      employeeDetails.employee.firstName +
      ' ' +
      employeeDetails.employee.lastName +
      " 's" +
      ' Claim Number: ' +
      employeeDetails.claimNumber +
      '  \n' +
      'Employee Id:' +employeeDetails.employee.id
        };
      employeeServices
      .rfafileupload(messagePayload, employeeId)
      .then((response) =>setSnackBarOpen(true));
      setFlagCheck('Y');
  }

  useEffect(() => {
    fetchVendors();
  }, []);

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={green[50]} 
      bgcolor="#e9fafa"
      style={{borderRadius:"10px"}}>
        <CardHeader
          title="Treatments"
          color="text.secondary"
          component={Box}
          action={
            <>
            <Tooltip title="Click on RFA flag to send notification">
             <Button
                  startIcon={<FlagIcon color="secondary"/>}
            onClick={handleRFA}
            // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role) && PRupload===false}
            disabled={RFAupload===false}

            
            >RFA </Button>
            </Tooltip>
            <Tooltip title="Click on PR2 flag to send notification">
            <Button
                  startIcon={<FlagIcon color="secondary"/>}
            onClick={handlePR2}
            // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role) && PRupload===false}
            disabled={PRupload===false}

            
            >PR2 </Button>
            </Tooltip>
            <Button
              startIcon={<HistoryIcon />}
              onClick={() => handleHistoryTreatment()}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              
            >
              History
            </Button>
            </>
          }
        />
        <Grid container spacing={1}>
          <Grid item sm={3} xs={12}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel htmlFor="treatment-reqtype">
                Requested Type
              </InputLabel>
              <Select
                multiple
                label="Requested Type"
                inputProps={{
                  name: "treatRequestedType",
                  id: "treatment-reqtype",
                }}
                value={formik.values.treatRequestedType}
                onChange={formik.handleChange}
                renderValue={(selected) => (
                  <Box display="flex" flexWrap="wrap">
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        size="small"
                        style={{ marginRight: 2 }}
                      />
                    ))}
                  </Box>
                )}
                disabled={
                  !ifRole(
                    [
                      ROLE.PROVIDER,
                      ROLE.STAFF,
                      ROLE.NURSECASEMANAGER,
                      ROLE.CLAIMEXAMINER,
                    ],
                    currentUser.role
                  )
                }
              >
                <MenuItem value="Physical Therapy">Physical Therapy</MenuItem>
                <MenuItem value="Accupuncture">Accupuncture</MenuItem>
                <MenuItem value="Spine Injection">Spine Injection</MenuItem>
                <MenuItem value="Chiropractic">Chiropractic</MenuItem>
                <MenuItem value="Cognitive Behavior">
                  Cognitive Behavior
                </MenuItem>
                <MenuItem value="Orthopedic Consultation/Surgery">
                  Orthopedic Consultation/Surgery
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              label="Requested Date"
              name={`treatmentDetails[0].requestedDate`}
              value={moment(
                ifNullAndUndefined(
                  formik.values.treatmentDetails[0].requestedDate
                )
                  ? ""
                  : formik.values.treatmentDetails[0].requestedDate
              ).format("YYYY-MM-DD")}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [
                    ROLE.PROVIDER,
                    ROLE.STAFF,
                    ROLE.NURSECASEMANAGER,
                    ROLE.CLAIMEXAMINER,
                  ],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={3} xs={12} >
            <TextField
              label="Attach RFA"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`treatmentDetails[0].fileUpload`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "treatmentDetails[0].fileUpload",
                  event.target.files[0]
                );
                setRFAupload(true)
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              label="PR2"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`treatmentDetails[0].prFileupload`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "treatmentDetails[0].prFileupload",
                  event.target.files[0]
                );
                setPRupload(true)
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              label="Authorizations"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`treatmentDetails[0].authorizationsFileupload`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "treatmentDetails[0].authorizationsFileupload",
                  event.target.files[0]
                );
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              label="Denials"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`treatmentDetails[0].denialsFileupload`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "treatmentDetails[0].denialsFileupload",
                  event.target.files[0]
                );
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              label="RFI"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`treatmentDetails[0].rfiFile`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "treatmentDetails[0].rfiFile",
                  event.target.files[0]
                );
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              label="Miscellaneous"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`treatmentDetails[0].miscellaneousFileupload`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "treatmentDetails[0].miscellaneousFileupload",
                  event.target.files[0]
                );
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={2} xs={12}>
            <TextField
              label="Utilization Review"
              name={`treatmentDetails[0].utilizationReview`}
              value={
                ifNullAndUndefined(
                  formik.values.treatmentDetails[0].utilizationReview
                )
                  ? ""
                  : formik.values.treatmentDetails[0].utilizationReview
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [
                    ROLE.PROVIDER,
                    ROLE.STAFF,
                    ROLE.NURSECASEMANAGER,
                    ROLE.CLAIMEXAMINER,
                  ],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <TextField
              label="Date Sent to UR"
              name={`treatmentDetails[0].urDate`}
              value={moment(
                ifNullAndUndefined(formik.values.treatmentDetails[0].urDate)
                  ? ""
                  : formik.values.treatmentDetails[0].urDate
              ).format("YYYY-MM-DD")}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [
                    ROLE.PROVIDER,
                    ROLE.STAFF,
                    ROLE.NURSECASEMANAGER,
                    ROLE.CLAIMEXAMINER,
                  ],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <TextField
              label="IMR"
              name={`treatmentDetails[0].imReview`}
              value={
                ifNullAndUndefined(formik.values.treatmentDetails[0].imReview)
                  ? ""
                  : formik.values.treatmentDetails[0].imReview
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [
                    ROLE.PROVIDER,
                    ROLE.STAFF,
                    ROLE.NURSECASEMANAGER,
                    ROLE.CLAIMEXAMINER,
                  ],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={2} xs={6}>
            <TextField
              label="Date sent to IMR"
              name={`treatmentDetails[0].imrDate`}
              value={moment(
                ifNullAndUndefined(formik.values.treatmentDetails[0].imrDate)
                  ? ""
                  : formik.values.treatmentDetails[0].imrDate
              ).format("YYYY-MM-DD")}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [
                    ROLE.PROVIDER,
                    ROLE.STAFF,
                    ROLE.NURSECASEMANAGER,
                    ROLE.CLAIMEXAMINER,
                  ],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={2} xs={6}>
            <TextField
              label="Approved Date"
              name={`treatmentDetails[0].approvedDate`}
              value={moment(
                ifNullAndUndefined(
                  formik.values.treatmentDetails[0].approvedDate
                )
                  ? ""
                  : formik.values.treatmentDetails[0].approvedDate
              ).format("YYYY-MM-DD")}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.CLAIMEXAMINER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={2} xs={6}>
            <TextField
              label="Denied Date"
              name={`treatmentDetails[0].deniedDate`}
              value={moment(
                ifNullAndUndefined(formik.values.treatmentDetails[0].deniedDate)
                  ? ""
                  : formik.values.treatmentDetails[0].deniedDate
              ).format("YYYY-MM-DD")}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.CLAIMEXAMINER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={4} xs={6}>
            <Box display="flex">
              <Autocomplete
                multiple
                disabled
                fullWidth
                id="interpreters-auto-search"
                name={`treatmentDetails[0].interpretersLookUp`}
                value={
                  ifNullAndUndefined(
                    formik.values.treatmentDetails[0].interpretersLookUp
                  )
                    ? []
                    : formik.values.treatmentDetails[0].interpretersLookUp
                }
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={interpretersList}
                filterSelectedOptions
                size="small"
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "treatmentDetails[0].interpretersLookUp",
                    value
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Interpreters"
                    placeholder="Select Interpreters"
                  />
                )}
                // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              />
              <Tooltip title="Add Interpreters">
                <Button
                  variant="outlined"
                  onClick={() => handleClickOpen("interpreters")}
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.ADMIN],
                    currentUser.role
                  )}
                >
                  <AddCircleIcon />
                </Button>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item sm={4} xs={6}>
            <Box display="flex">
              <Autocomplete
                multiple
                disabled
                fullWidth
                id="transporters-auto-search"
                name={`treatmentDetails[0].transportersLookUp`}
                value={
                  ifNullAndUndefined(
                    formik.values.treatmentDetails[0].transportersLookUp
                  )
                    ? []
                    : formik.values.treatmentDetails[0].transportersLookUp
                }
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={transportersList}
                filterSelectedOptions
                size="small"
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "treatmentDetails[0].transportersLookUp",
                    value
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Transporters"
                    placeholder="Select Transporters"
                  />
                )}
                // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              />
              <Tooltip title="Add Transporters">
                <Button
                  variant="outlined"
                  onClick={() => handleClickOpen("transporters")}
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.ADMIN],
                    currentUser.role
                  )}
                >
                  <AddCircleIcon />
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item sm={4} xs={6}>
            <Box display="flex">
              <Autocomplete
                multiple
                fullWidth
                id="mpn-auto-search"
                name={`treatmentDetails[0].mpnLookup`}
                value={
                  ifNullAndUndefined(
                    formik.values.treatmentDetails[0].mpnLookup
                  )
                    ? []
                    : formik.values.treatmentDetails[0].mpnLookup
                }
                open={openMPNAutoComplete}
                onOpen={() => {
                  setOpenMPNAutoComplete(true);
                }}
                onClose={() => {
                  setOpenMPNAutoComplete(false);
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={mpnList}
                filterSelectedOptions
                size="small"
                loading={loading}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "treatmentDetails[0].mpnLookup",
                    value
                  );
                }}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="MPN"
                    variant="outlined"
                    onChange={ev => {
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        onChangeHandle(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Tooltip title="Add MPN">
                <Button
                  variant="outlined"
                  onClick={() => handleClickOpenMPNDialog()}
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.ADMIN],
                    currentUser.role
                  )}
                >
                  <AddCircleIcon />
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <InterpretersAndTransporterManageInfo
        open={open}
        handleClose={handleClose}
        venType={vendorType}
        fetchVendors={fetchVendors}
      />

      <MPNManageInfo 
        open={openMPNDialog}
        handleClose={handleClickCloseMPNDialog}
      />

      <TreatmentHistory
        treatmentHistoryList={treatmentHistoryList}
        openTreatmentHistory={openTreatmentHistory}
        handleTreatmentHistoryClose={handleTreatmentHistoryClose}
        handleHistoryTreatment={handleHistoryTreatment}
      />
         <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message="Notification sent successfully."
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDETreatment);
