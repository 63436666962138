import React, {useState, forwardRef} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MaterialTable from 'material-table';
import EmployeeService from "../../services/employee/employee.services";
import ChangePassword from "../common/change-password/change-password.component";

function EmployerList({
  employerList,
  handleClickOpen,
  setOpen,
  getEmployers,
  props,
  ...otherProps
}) {
  const tableIcons = {
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    
};

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [userId, setUserId] = useState();

  const handleClickDelete = (id) => {
    if (window.confirm("Are you sure you wish to remove ?")) {
      EmployeeService.removeStackholder(id).then((response) => {
        if (response && response.data) {
          getEmployers();
          alert(response.data);
        }
      }).catch();
    } 
  };

  const handleClickChangePwd = (userId) => {
    setUserId(userId);
    setOpenChangePassword(true);
  };

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        title=""
        columns={[
          {
            title: "Title",
            field: "title",
            render: (employerList) =>
              employerList.title != null ? employerList.title : "",
          },
          {
            title: "Name",
            field: "name",
            render: (employerList) => employerList.name,
          },
          {
            title: "Company Name",
            field: "companyName",
            render: (employerList) =>
              employerList.companyName != null ? employerList.companyName : "",
          },
          {
            title: "Email",
            field: "email",
            render: (employerList) =>
              employerList.email != null ? employerList.email : "",
          },
          {
            title: "Phone",
            field: "phone",
            render: (employerList) =>
              employerList.phone != null ? employerList.phone : "",
          },
          {
            title: "Address",
            field: "address",
            render: (employerList) => employerList.address,
          },
          {
            title: "Fax",
            field: "fax",
            render: (employerList) =>
              employerList.fax != null ? employerList.fax : "",
          },
        ]}
        data={employerList}
        options={{
          search: true,
          actionsColumnIndex: -1,
          headerStyle: {
            fontWeight: "bold",
          },
        }}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit",
            onClick: (event, rowData) =>
              handleClickOpen("EDIT", rowData, setOpen),
          },
          {
            icon: VpnKeyIcon,
            tooltip: "Change Password",
            onClick: (event, rowData) => handleClickChangePwd(rowData.user.id),
            hidden: props.currentUser.role === 'ADMIN' ? false : true,
          },
          {
            icon: DeleteForeverIcon,
            tooltip: "Remove",
            onClick: (event, rowData) => handleClickDelete(rowData.user.id),
          },
        ]}
      />
      {openChangePassword && <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
        userId={userId}
      />}
    </div>
  );
}

export default EmployerList;
