import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
} from '@material-ui/core';

function NurseCaseManagerManageInfo({
    open,
    handleClose,
    handleChange,
    handleSubmit,
    nurseCaseManagerInfo,
    manageType,
    ...otherProps
  }) {
    const {
        caEmail,
        caName,
        caPhone,
        caFax,
        caAddress,
    } = nurseCaseManagerInfo;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {" "}
            {manageType === "EDIT"
              ? "Manage Nurse Case Manager"
              : "Create Nurse Case Manager"}
          </DialogTitle>
          <DialogContent>
            <form id="nurseCaseManager_manage_form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caName"
                    label="Name"
                    type="text"
                    fullWidth
                    value={caName}
                    onChange={handleChange}
                  />
                </Grid>
                {/*               <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="ca-title-select-label">Title</InputLabel>
                                    <Select labelId="ca-title-select-label" id="ca-title-select"
                                        name="caTitle" value={caTitle} onChange={handleChange} >
                                        <MenuItem value="NURSECASEMANAGER">NURSE CASE MANAGER</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caEmail"
                    label="Email"
                    type="text"
                    fullWidth
                    value={caEmail}
                    onChange={handleChange}
                    disabled={manageType === "EDIT"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caPhone"
                    label="Phone"
                    type="text"
                    fullWidth
                    value={caPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caFax"
                    label="Fax"
                    type="text"
                    fullWidth
                    value={caFax}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caAddress"
                    label="Address"
                    type="text"
                    fullWidth
                    value={caAddress}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  export default NurseCaseManagerManageInfo;