import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import DialogTitleWithClose from "../../common/dialog-title-close.component";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";

function PriorInjuriesHistory({
  priorInjuriesHistoryList,
  openPcdHistory,
  handlePcdHistoryClose,
  fetchPriorCareHistoryList,
  ...otherProps
}) {
  const handleClickDelete = (id) => {
    if (
      window.confirm(
        "Are you sure you wish to delete this item,As it will also remove prior Care history?"
      )
    ) {
      employeeServices
        .removeHistory("priorInjuries", id)
        .then((response) => {
          if (response && response.data) {
            fetchPriorCareHistoryList();
            alert(response.data);
          }
        })
        .catch();
    } else {
      console.log("no");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openPcdHistory}
        onClose={handlePcdHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Prior Injuries History"}</u>
        </DialogTitle>
        <DialogTitleWithClose
          onClose={() => handlePcdHistoryClose()}
        ></DialogTitleWithClose>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Added On</b>
                  </TableCell>
                  <TableCell>
                    <b>Prior Injury Body Part</b>
                  </TableCell>
                  <TableCell>
                    <b>Prior Injury Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priorInjuriesHistoryList != null &&
                priorInjuriesHistoryList.length > 0 ? (
                  priorInjuriesHistoryList.map((pcdHistory) => (
                    <TableRow key={pcdHistory.id}>
                      <TableCell>
                        {pcdHistory.addedOn !== null
                          ? moment(pcdHistory.addedOn).format("YYYY-MM-DD")
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {pcdHistory.priorInjuryBodyPart !== null
                          ? pcdHistory.priorInjuryBodyPart
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {pcdHistory.priorInjuryDate !== null
                          ? moment(pcdHistory.priorInjuryDate).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleClickDelete(pcdHistory.id)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default PriorInjuriesHistory;
