import React from 'react';

// import { Card, CardContent, Grid } from '@material-ui/core';

import Header from '../../components/common/header/header.component';
import Login from '../../components/common/login/login.component';

function AppWelcomePage(props) {
  return (
    <div>
      <Header />

      <Login />
    </div>
  );
}
export default AppWelcomePage;
