import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';

import ProviderManageInfo from '../../../provider/provider-manage-info.component';
import ProviderServices from '../../../../services/provider/provider.services';

import {
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  Grid,
  Paper,
  InputLabel,
  Select,
  FormControl,
} from '@material-ui/core';
// import { indigo } from '@material-ui/core/colors';

import AddIcon from '@material-ui/icons/Add';
import ProviderService from '../../../../services/provider/provider.services';

const EDESecondaryTP = ({ formik, currentUser, ...otherProps }) => {
  const [providerSearchVal, setProviderSearchVal] = useState([]);
  const [providerInfo, setProviderInfo] = useState({
    asActive: 1,
    asProfilePicId: null,
    createdBy: currentUser.id,
    asUserId: null,
    asFirstName: '',
    asLastName: '',
    asProTitle: '',
    asProSpeciality: '',
    asProType: '',
    asPreferredName: '',
    asGender: 'M',
    asNpin: '',
    asStreet1: '',
    asStreet2: '',
    asCity: '',
    asState: '',
    asCountry: '',
    asZipCode: '',
    asEmail: '',
    asPhone: '',
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setProviderInfo({
      asActive: 1,
      asProfilePicId: null,
      createdBy: currentUser.id,
      asUserId: null,
      asFirstName: '',
      asLastName: '',
      asProTitle: '',
      asProSpeciality: '',
      asProType: '',
      asPreferredName: '',
      asGender: 'M',
      asNpin: '',
      asStreet1: '',
      asStreet2: '',
      asCity: '',
      asState: '',
      asCountry: '',
      asZipCode: '',
      asEmail: '',
      asPhone: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProviderInfo({
      ...providerInfo,
      [name]: value,
    });
  };

  const createProvider = () => {
    const {
      asActive,
      asProfilePicId,
      createdBy,
      asFirstName,
      asLastName,
      asProTitle,
      asProSpeciality,
      asProType,
      asPreferredName,
      asGender,
      asNpin,
      asStreet1,
      asStreet2,
      asCity,
      asState,
      asCountry,
      asZipCode,
      asEmail,
      asPhone,
    } = providerInfo;

    const providerPayload = {
      active: asActive,
      city: asCity,
      country: asCountry,
      firstName: asFirstName,
      lastName: asLastName,
      providerTitle: asProTitle,
      specialty: asProSpeciality,
      providerType: asProType,
      preferredName: asPreferredName,
      phone: asPhone,
      email: asEmail,
      profilePicId: asProfilePicId,
      gender: asGender,
      npin: asNpin,
      state: asState,
      street1: asStreet1,
      street2: asStreet2,
      zipcode: asZipCode,
      userId: createdBy,
    };

    ProviderServices.createProvider(providerPayload).then((response) => {
      window.alert(response.data.message);
      getProviderList();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createProvider();
  };

  const getProviderList = () => {
    ProviderService.getProviderList()
      .then((response) => {
        if (response && response.data) {
          setProviderSearchVal(response.data);
        }
      })
      .catch();
  };

  useEffect(() => {
    getProviderList();
  }, []);

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box}
      //  bgcolor={indigo[50]}
       bgcolor="#e1f5fe"
       >
        <CardHeader
          title="Secondary TP"
          color="text.secondary"
          component={Box}
          action={
            <Tooltip title="Add Secondary TP">
              <IconButton
                // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                aria-label="Add Secondary TP"
                onClick={() => setOpen(true)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <Grid container spacing={1}>
          <Grid item sm={12}>
            {providerSearchVal.length !== 0 && (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              >
                <InputLabel htmlFor="stp1-select">Select TP1</InputLabel>
                <Select
                  native
                  value={
                    ifNullAndUndefined(formik.values.stp1.id)
                      ? ''
                      : formik.values.stp1.id
                  }
                  onChange={formik.handleChange}
                  label="Select TP1"
                  inputProps={{
                    name: 'stp1.id',
                    id: 'stp1-select',
                  }}
                >
                  <option value="" />
                  {providerSearchVal.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.firstName} {item.lastName} - {item.specialty}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item sm={12}>
            {providerSearchVal.length !== 0 && (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              >
                <InputLabel htmlFor="stp2-select">Select TP2</InputLabel>
                <Select
                  native
                  value={
                    ifNullAndUndefined(formik.values.stp2.id)
                      ? ''
                      : formik.values.stp2.id
                  }
                  onChange={formik.handleChange}
                  label="Select TP2"
                  inputProps={{
                    name: 'stp2.id',
                    id: 'stp2-select',
                  }}
                >
                  <option value="" />
                  {providerSearchVal.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.firstName} {item.lastName} - {item.specialty}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item sm={12}>
            {providerSearchVal.length !== 0 && (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              >
                <InputLabel htmlFor="stp3-select">Select TP3</InputLabel>
                <Select
                  native
                  value={
                    ifNullAndUndefined(formik.values.stp3.id)
                      ? ''
                      : formik.values.stp3.id
                  }
                  onChange={formik.handleChange}
                  label="Select TP3"
                  inputProps={{
                    name: 'stp3.id',
                    id: 'stp3-select',
                  }}
                >
                  <option value="" />
                  {providerSearchVal.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.firstName} {item.lastName} - {item.specialty}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Paper>

      <ProviderManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        providerInfo={providerInfo}
        manageType="ADD"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDESecondaryTP);
