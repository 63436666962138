import React, { useState, forwardRef } from "react";
import { Dialog, DialogContent, Link } from "@material-ui/core";
import moment from "moment";
import SendIcon from "@material-ui/icons/Send";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import EmployeeService from "../../services/employee/employee.services";
import EmployeeEditComponent from "./employee-detail/employee-detail-edit.component";
import MessageSendingStakeHolders from "../message/message-sending-stakeholders.component";
import patientDemographicsService from "../../services/patient-demographics/patient-demographics.service";
import PersonalInjuryEmployeeList from "../personal-injury/personal-injury-employee-list.component";

function EmployeeList({ employeeList, getEmployees, props, ...otherProps }) {
  const tableIcons = {
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
  };

  const [employeeDetailInfo, setEmployeeDetailInfo] = useState();
  const [openSentMessage, setOpenSentMessage] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [empDetailsForMessage, setEmpDetailsForMessage] = useState();
  const { currentUser } = props;

  const handleClickPIOpen = (Id) => {
    if (Id) {
      patientDemographicsService.getPatientById(Id).then((response) => {
        setEmployeeDetailInfo(response.data);
      });
    }
  };

  const handleClickOpen = (employee, claimNum) => {
    EmployeeService.getEmployeeChartData({
      employee: employee,
      claimNumber: claimNum,
      userId: props.currentUser.id,
    })
      .then((response) => {
        setEmployeeDetailInfo(response.data);
        setOpenDetail(true);
      })
      .catch();
  };

  const handleMessagesClose = () => {
    setOpenSentMessage(false);
  };

  const handleMessagesOpen = (employeeDetails) => {
    setEmpDetailsForMessage(employeeDetails);
    setOpenSentMessage(true);
  };

  const handleClickDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      EmployeeService.removeStackholder(id)
        .then((response) => {
          if (response && response.data) {
            getEmployees();
            alert(response.data);
          }
        })
        .catch();
    }
  };

  return (
    <div>
      {currentUser.role === "ATTORNEY" || currentUser.role === "PROVIDER" ? (
        <PersonalInjuryEmployeeList
          employeeList={employeeList}
          handleClickPIOpen={handleClickPIOpen}
          handleClickOpen={handleClickOpen}
        />
      ) : (
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={[
            { title: "Employee", field: "employee.message" },
            {
              title: "DOB",
              field: "dob",
              render: (rowData) =>
                rowData.employee.dob != null
                  ? moment(rowData.employee.dob).format("MM-DD-YYYY")
                  : "",
            },
            { title: "Email", field: "employee.emailId" },
            {
              title: "Claim Number/Chart Id",
              field: "claimNumber",
              render: (rowData) => (
                <Link
                  href="#"
                  onClick={() =>
                    handleClickOpen(rowData.employee, rowData.claimNumber)
                  }
                >
                  {rowData.claimNumber}
                </Link>
              ),
            },
            {
              title: "Injury Date",
              field: "injuryDate",
              render: (rowData) =>
                rowData.injuryDate != null
                  ? moment(rowData.injuryDate).format("MM-DD-YYYY")
                  : "",
            },
          ]}
          data={employeeList}
          options={{
            search: true,
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: "bold",
            },
          }}
          actions={[
            {
              icon: SendIcon,
              tooltip: "Compose Message",
              onClick: (event, rowData) => handleMessagesOpen(rowData.employee),
            },
            {
              icon: DeleteForeverIcon,
              tooltip: "Remove",
              onClick: (event, rowData) =>
                handleClickDelete(rowData.employee.user.id),
            },
          ]}
        />
      )}

      {employeeDetailInfo && (
        <Dialog open={openDetail} fullScreen>
          <DialogContent dividers={true} style={{ padding: "8px" }}>
            <EmployeeEditComponent
              employeeDetails={employeeDetailInfo}
              setOpenDetail={setOpenDetail}
              getEmployees={getEmployees}
            />
          </DialogContent>
        </Dialog>
      )}

      <MessageSendingStakeHolders
        empDetails={empDetailsForMessage}
        open={openSentMessage}
        handleMessagesClose={handleMessagesClose}
      />
    </div>
  );
}

export default EmployeeList;
