import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Tab,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  // Box,
  // Chip,
  // MenuItem,
} from "@material-ui/core";
import employeeServices from "../../../../services/employee/employee.services";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
function MedicationAndOthersManageInfo({
  open,
  handleClose,
  getMedications,
  medType,
  ...otherProps
}) {
  const [medication, setMedication] = useState({
    id: 0,
    name: "",
    medicationType: "",
    ndcCode: "",
  });
   const [condition, setCondition] = useState({
     id: 0,
     name: "",
     conditionType: "",
     category: "",
   });
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeMedication = (event) => {
    const { name, value } = event.target;
    setMedication({
      ...medication,
      [name]: value,
    });
  };
   const handleChangeCondition = (event) => {
     const { name, value } = event.target;
     setCondition({
       ...condition,
       [name]: value,
     });
   };
  const handleSubmitMedications = (event) => {
      if (medication.name === "") {
        window.alert("Enter name.");
        return;
      }
      if (medication.medicationType === "") {
        window.alert("Select medication Type.");
        return;
      }
    employeeServices.addMedicationlookup(medication).then((response) => {
      window.alert(response.data.message);
      getMedications(medType);
      handleReset();
    });
    event.preventDefault();
  };
   const handleSubmitConditionlookup = (event) => {
     if (condition.name === "") {
       window.alert("Enter name.");
       return;
     }
     if (condition.conditionType === "") {
       window.alert("Select Condition Type.");
       return;
     }
     employeeServices.addConditionlookup(condition).then((response) => {
       window.alert(response.data.message);
       getMedications(medType);
       handleReset();
     });
     event.preventDefault();
   };
  const handleReset = () => {
    setMedication({
      ...medication,
      name: "",
      medicationType: "",
      ndcCode: "",
    });
    setCondition({
      ...condition,
      name: "",
      conditionType: "",
      category: "",
    });
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Add Medications / Conditions
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <TabContext value={value}>
              <AppBar position="static">
                <TabList
                  onChange={handleChangeTab}
                  aria-label="simple tabs example"
                >
                  <Tab label="Medications" value="1" />
                  <Tab label="Conditions" value="2" />
                </TabList>
              </AppBar>
              <TabPanel value="1">
                {" "}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="name"
                      label="Name"
                      type="text"
                      fullWidth
                      value={medication.name}
                      onChange={handleChangeMedication}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small" fullWidth>
                      <InputLabel htmlFor="medicationType-History">
                        Medication Type
                      </InputLabel>
                      <Select
                        label="Medication Type"
                        native
                        value={medication.medicationType}
                        onChange={handleChangeMedication}
                        inputProps={{
                          name: "medicationType",
                          id: "medicationType-History",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value="NonIndustrial">Non Industrial</option>
                        <option value="Industrial">Industrial</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="ndcCode"
                      label="Ndc Code (Optional)"
                      type="text"
                      fullWidth
                      value={medication.ndcCode}
                      onChange={handleChangeMedication}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={function (event) {
                      handleClose();
                      handleReset();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={handleSubmitMedications}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </TabPanel>
              <TabPanel value="2">
                {" "}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="name"
                      label="Name"
                      type="text"
                      fullWidth
                      value={condition.name}
                      onChange={handleChangeCondition}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small" fullWidth>
                      <InputLabel htmlFor="conditionType-History">
                        Condition Type
                      </InputLabel>
                      <Select
                        label="Condition Type"
                        native
                        value={condition.conditionType}
                        onChange={handleChangeCondition}
                        inputProps={{
                          name: "conditionType",
                          id: "conditionType-History",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value="NonIndustrial">Non Industrial</option>
                        <option value="Industrial">Industrial</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="category"
                      label="Category (Optional)"
                      type="text"
                      fullWidth
                      value={condition.category}
                      onChange={handleChangeCondition}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={function (event) {
                      handleClose();
                      handleReset();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={handleSubmitConditionlookup}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </TabPanel>
            </TabContext>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default MedicationAndOthersManageInfo;
