import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from 'moment';
import DialogTitleWithClose from '../../common/dialog-title-close.component';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";
function VisitsHistory({
  visitsHistoryList,
  openVisitsHistory,
  handleVisitsHistoryClose,
  handleHistoryVisits,
  ...otherProps
}) {
  					      const handleClickDelete = (id) => {
                    employeeServices
                      .removeHistory("visit", id)
                      .then((response) => {
                        if (response && response.data) {
                          handleHistoryVisits();
                          alert(response.data);
                        }
                      })
                      .catch();
                  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openVisitsHistory}
        onClose={handleVisitsHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Visits History"}</u>
        </DialogTitle>
        <DialogTitleWithClose
          onClose={() => handleVisitsHistoryClose()}
        ></DialogTitleWithClose>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Added On</b>
                  </TableCell>
                  <TableCell>
                    <b>First Office Visit</b>
                  </TableCell>
                  <TableCell>
                    <b>Next Office Visit </b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitsHistoryList != null && visitsHistoryList.length > 0 ? (
                  visitsHistoryList.map((visitsHistory) => (
                    <TableRow key={visitsHistory.id}>
                      <TableCell>
                        {visitsHistory.addedOn !== null
                          ? moment(visitsHistory.addedOn).format("YYYY-MM-DD")
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {visitsHistory.firstOfficeVisit !== null
                          ? moment(visitsHistory.firstOfficeVisit).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {visitsHistory.nextOfficeVisit !== null
                          ? moment(visitsHistory.nextOfficeVisit).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleClickDelete(visitsHistory.id)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default VisitsHistory;
