import React, { useState, forwardRef, useEffect } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DialogTitleWithClose from "../common/dialog-title-close.component";
import moment from "moment";
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Chip,
  // Paper,
  // Button,
  Box,
  Typography
} from "@material-ui/core";
// import { indigo } from "@material-ui/core/colors";


import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import EmployeeService from "../../services/employee/employee.services"
import MaterialTable from 'material-table';
import EmployeeEditComponent from "../../components/employee/employee-detail/employee-detail-edit.component"
function MessageList({ messageList,showFlag,settingRuFlag, ...otherProps }) {
  const [viewMessageInfo, setViewMessageInfo] = useState("");
  const [openviewMessage, setOpenviewMessage] = useState(false);
  const [openclaiminfo,setopenclaiminfo]=useState(false)
  const[EmployeeList,setEmployeeList]=useState([])
  const [OpenDetail,setOpenDetail]=useState(false)
  //Get emmployee from employeelist
  const [employeeDetailInfo, setEmployeeDetailInfo] = useState();
  const [claimClickable,setclaimClaickable]=useState(true)
  const [firstHalf,setFirstMessage]=useState("")
  const [lastHalf,setLastHalfMessage]=useState("");
  const [claimId,setclaimId]=useState("");
  const[visible,setvisible]=useState(false)
  const [valid,setvalid]=useState(false)


  const handleClickOpen = (ml) => {
    if(showFlag===false){
    settingRuFlag(ml.id);
 }
    
    setViewMessageInfo(ml.message);
    setOpenviewMessage(true);

    setopenclaiminfo(false)
  };


  useEffect(()=>{

    if(viewMessageInfo!=="")
    {

      const claim_start = viewMessageInfo.indexOf("Number") + 8;
      const claim_end = viewMessageInfo.indexOf("\n") - 2;
      const emp_start=viewMessageInfo.indexOf("Id")+3;

      setFirstMessage(viewMessageInfo.slice(0,claim_start))
      // setLastHalfMessage(viewMessageInfo.slice(claim_end,viewMessageInfo.length))
      setLastHalfMessage(viewMessageInfo.slice(claim_end,viewMessageInfo.indexOf("Id")-9))


      let temp_employee=viewMessageInfo.slice(emp_start,viewMessageInfo.length)
      if(viewMessageInfo.includes("Id"))
      {
        setvisible(true)
      }
      else{
        setvisible(false)
      }
let employee_original;
      if(EmployeeList.length>0)
      {
      EmployeeList.forEach((employee)=>{
        if(employee.employee.id===Number(temp_employee))
        {
          employee_original=employee.employee
        
        }
        else{

          console.log("false")
        }
      })
      
    }else{
      setvisible(false)
    }

    if(employee_original!==undefined)
    {
      setvalid(true)
    }
    else{

      setvalid(false)
    }

      if(claim_end>=0 )
      {

   
        const claim_number = viewMessageInfo.slice(claim_start, claim_end);
      setclaimId(claim_number)


        EmployeeService.getEmployeeChartData({
          employee : employee_original, 
          claimNumber : claim_number, 
          userId : otherProps.currentUser.id,
        }).then((response) => {
          setEmployeeDetailInfo(response.data)
        }).catch();
        setclaimClaickable(true)
      }
      else {
        setclaimClaickable(false)
      }
    }
  },[viewMessageInfo])
//calling employee service


const getEmployees = () => {
  if (otherProps.currentUser.role === 'ADMIN') {
    EmployeeService.getEmployeeList().then((response) => {
      setEmployeeList(response.data);
    }).catch();
  } else {
    EmployeeService.getEmployeeListByUser(otherProps.currentUser.id).then((response) => {
      setEmployeeList(response.data);
    }).catch();
  }
};

useEffect(()=>{
  getEmployees()
},[])
  //handle input function to open modal
  const handlemessage=()=>{
    setopenclaiminfo(true)
    setOpenDetail(true)
    setOpenviewMessage(false);

  }

  const tableIcons = {
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    
};

  return (
    <div>
      <MaterialTable
                icons={tableIcons}
                title=""
                columns={[
                    { title: 'Name' , field: 'senderuserDetails.userName', render: (messageList) => messageList.senderuserDetails.userName != null ? messageList.senderuserDetails.userName : '' },
                    { title: 'Email' , field: 'senderuserDetails.userEmail', render: (messageList) => messageList.senderuserDetails.userEmail != null ? messageList.senderuserDetails.userEmail : '' },
                    { title: 'Role', field: 'senderuserDetails.role', render: (messageList) => messageList.senderuserDetails.role != null ? messageList.senderuserDetails.role : '' },
                    { title: 'Message', field: 'messageList', render: (messageList) =>  <Tooltip title="View Messages">
                    <IconButton
                      aria-label="View Messages"
                      onClick={() => handleClickOpen(messageList)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip> },
                    
                    { title: 'Date', field: 'createDateTime', render: (messageList) => moment(messageList.createDateTime).format('YYYY-MM-DD hh:mm:ss')},
                    { title: 'Status', field: 'flag', render: (messageList) => messageList.flag != null ? (
                      messageList.flag === 0 ? (
                        <Chip size="small" label="Unread" color="secondary" />
                      ) : (
                        <Chip size="small" label="Read" color="primary" />
                      )
                    ) : (
                      ""
                    )},
                ]}
                data={messageList}        
                options={{
                    search: true,
                    actionsColumnIndex: -1,
                    headerStyle: {
                          fontWeight: 'bold'
                    }
                      
                }}
            />
      <Dialog
        open={openviewMessage}
        scroll="paper"
        fullWidth={false}
        maxWidth="lg"
      >
        <DialogTitleWithClose
          onClose={() => setOpenviewMessage(false)}
        ></DialogTitleWithClose>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
<Box style={{width: "auto", height: "auto", padding:"10px 20px 25px 0px"}}>
 {
  claimClickable?
  
    <Typography variant="subtitle1"> 
    <span>{firstHalf}</span>
  { visible && valid? <Tooltip title="click to view patient information">
    <strong onClick={handlemessage}  style={{cursor:"pointer",color:"blue"}}>{claimId}</strong>
    </Tooltip>:<span>{claimId}</span>}
    <br/>
    <span>{lastHalf}</span>
    
    </Typography>
:
 <p> {viewMessageInfo}</p>
 
 }
</Box>
              
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>


{/* Dialog box to show patient data */}
      {(
        <Dialog open={OpenDetail} fullScreen >
          <DialogContent dividers={true} style={{ padding: "5px" }}>
            {/* <Box style={{width:"100%",padding:"5px"}}>
            <Button variant="outlined" color="primary"   onClick={()=>setopenclaiminfo(false)}>← Back</Button>
              
            </Box> */}
            <EmployeeEditComponent
              employeeDetails={employeeDetailInfo}
              setOpenDetail={setOpenDetail}
              getEmployees={getEmployees}
            />
          </DialogContent>
        </Dialog>
      )}

    </div>
  );
}

export default MessageList;
