import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import DialogTitleWithClose from "../../common/dialog-title-close.component";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";
function WorkStatusHistory({
  workStatusHistoryList,
  openWorkStatusHistory,
  handleWorkStatusHistoryClose,
  handleHistoryWorkStatus,
  ...otherProps
}) {
  const handleClickDelete = (id) => {
    employeeServices
      .removeHistory("workStatus", id)
      .then((response) => {
        if (response && response.data) {
          handleHistoryWorkStatus();
          alert(response.data);
        }
      })
      .catch();
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openWorkStatusHistory}
        onClose={handleWorkStatusHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Work Status History"}</u>
        </DialogTitle>
        <DialogTitleWithClose
          onClose={() => handleWorkStatusHistoryClose()}
        ></DialogTitleWithClose>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Added On</b>
                  </TableCell>
                  <TableCell>
                    <b>Limited Carrying</b>
                  </TableCell>
                  <TableCell>
                    <b>Limited Pulling</b>
                  </TableCell>
                  <TableCell>
                    <b>Limited Lifting </b>
                  </TableCell>
                  <TableCell>
                    <b>Limited Pushing </b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workStatusHistoryList != null &&
                workStatusHistoryList.length > 0 ? (
                  workStatusHistoryList.map((workStatusHistory) => (
                    <TableRow key={workStatusHistory.id}>
                      <TableCell>
                        {workStatusHistory.addedOn !== null
                          ? moment(workStatusHistory.addedOn).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {workStatusHistory.limitedCarrying !== null &&
                        workStatusHistory.limitedCarrying !== ""
                          ? workStatusHistory.limitedCarrying
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {workStatusHistory.limitedPulling !== null &&
                        workStatusHistory.limitedPulling !== ""
                          ? workStatusHistory.limitedPulling
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {workStatusHistory.limitedLifting !== null &&
                        workStatusHistory.limitedLifting !== ""
                          ? workStatusHistory.limitedLifting
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {workStatusHistory.limitedPushing !== null &&
                        workStatusHistory.limitedPushing !== ""
                          ? workStatusHistory.limitedPushing
                          : "--"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() =>
                              handleClickDelete(workStatusHistory.id)
                            }
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default WorkStatusHistory;
