import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { Typography, TextField, Box, Button } from '@material-ui/core';

import {
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD,
  PROVIDE_VALID_EMAIL,
  PATTERN_EMAIL,
  INVALID_CREDENTIAL,
} from '../../../utils/strings';

import AuthenticationServices from '../../../services/common/authentication.services';
import CommonServices from '../../../services/common/common.services';
import { setCurrentUser } from '../../../redux/user/user.actions';
import { setAxiosInterceptor } from '../../../redux/interceptor/interceptor.actions';
import { conditionalRedirect } from '../../../utils/common-utils';

const Login = (props) => {
  const [state, setState] = React.useState({
    email: '',
    password: '',
    // email: '',
    // password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { email, password } = state;

    if (email === '') {
      alert(REQUIRED_EMAIL);
      return;
    }

    if (
      email !== '' &&
      !(function () {
        return PATTERN_EMAIL.test(email);
      })()
    ) {
      alert(PROVIDE_VALID_EMAIL);
      return;
    }

    if (password === '') {
      alert(REQUIRED_PASSWORD);
      return;
    }

    AuthenticationServices.executeOAuthAuthenicationService(email, password)
      .then((response) => {
        const token = '?access_token=' + response.data['access_token'];

        const AXIOS_INTERCEPTOR = axios.interceptors.request.use((config) => {
          config.url = config.url + token;
          return config;
        });

        props.setAxiosInterceptor(AXIOS_INTERCEPTOR);

        CommonServices.getUserByEmail(email).then((response) => {
          let { id, role, userEmail, userName, profilePic } = response.data;
          props.setCurrentUser({
            id,
            role,
            userEmail,
            userName,
            profilePic,
            token,
          });

          conditionalRedirect({ role });
        });
      })
      .catch((error) => alert(INVALID_CREDENTIAL));
  };

  return (
    <Box m={3} width="40%">
      <Typography mb={5} color="textSecondary" gutterBottom>
        Login with your id and password
      </Typography>

      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Box component="div" mb={1}>
          <TextField
            autoFocus
            id="login-email"
            label="Email"
            variant="filled"
            fullWidth
            required
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
        </Box>
        <Box component="div" my={1}>
          <TextField
            id="login-password"
            label="Password"
            variant="filled"
            fullWidth
            required
            autoComplete="true"
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
          />
        </Box>

        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </form>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setAxiosInterceptor: (interceptor) =>
    dispatch(setAxiosInterceptor(interceptor)),
});

export default withRouter(connect(null, mapDispatchToProps)(Login));
