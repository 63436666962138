import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormik } from "formik";

import { ifRole } from "../../../utils/common-utils";
import { ROLE } from "../../../utils/settings";

import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  Button,
  Snackbar,
} from "@material-ui/core";


import { selectCurrentUser } from "../../../redux/user/user.selectors";
import employeeServices from "../../../services/employee/employee.services";

import EDEDemographics from "./employee-detail-edit/ede-demographics";
import EDEClaims from "./employee-detail-edit/ede-claims";
import EDEEmployer from "./employee-detail-edit/ede-employer";
import EDEVisits from "./employee-detail-edit/ede-visits";
import EDEPriorInjuries from "./employee-detail-edit/ede-prior-injuries.component";
import EDEDiagnosis from "./employee-detail-edit/ede-diagnosis";
import EDEPrimaryTP from "./employee-detail-edit/ede-primarytp";
import EDESecondaryTP from "./employee-detail-edit/ede-secondarytp";
import EDECurrentCare from "./employee-detail-edit/ede-current-care";
import EDEPriorCare from "./employee-detail-edit/ede-prior-care";
import EDEWorkStatus from "./employee-detail-edit/ede-work-status";
import EDETreatment from "./employee-detail-edit/ede-treatment";
import EDEDocumentHistory from "./employee-detail-edit/ede-document-history";
// import EDEStackHolders from "./employee-detail-edit/ede-stackholders";
import EdeNcm from './employee-detail-edit/edit-stakeholders/ede-ncm.component';
import EdeAttorney from './employee-detail-edit/edit-stakeholders/ede-attorney.component';
import EdeClaimExaminer from './employee-detail-edit/edit-stakeholders/ede-claim-examiner.component';
import EdePrimaryTpSh from './employee-detail-edit/edit-stakeholders/ede-primarytp-sh.component';
import EdeUro from './employee-detail-edit/edit-stakeholders/ede-uro.component';
const EmployeeEditComponent = ({
  employeeDetails,
  currentUser,
  setOpenDetail,
  getEmployees,
  ...otherProps
}) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [flagCheck, setFlagCheck] = useState("");

  const blankEmployeeData = {
    claimsDetail: [
      {
        acceptedBodyParts: "",
        addedOn: "",
        claimNumber: "",
        disputedBodyParts: "",
        id: "",
        injuryDate: "",
        psAnticipatedDate: "",
        reason: "",
      },
    ],

    nurseCaseManager: {},
    attorney: {},
    claimExaminer: {},

    jobDetailsFileupload: null,
    rtwFileUpload: null,
    jobDetails: [
      {
        jobTitle: "",
        department: "",
        dateOfHire: "",
        supervisor: "",
        hrContact: "",
        fileUpload: null,
        rtwPolicyfileUpload: null,
      },
    ],

    visitDetails: [
      {
        firstOfficeVisit: "",
        nextOfficeVisit: "",
      },
    ],

    diagnosis: [],

    stp1: {
      id: "",
    },
    stp2: {
      id: "",
    },
    stp3: {
      id: "",
    },

    currentCareDetails: [
      {
        medications: "",
        bmi: "",
        height: "",
        weight: "",
        pastMedicalHistoryList: [],
        familyHistoryList: [],
        socialHistoryList: [],
        comorbiditiesList: [],

        medicationsLookUp: [],
      },
    ],

    priorCareFileupload: null,

    priorCareDetails: [
      {
        addedOn: "",
        fileUpload: null,
        id: "",
        typeOfService: "",
        priorInjuryBodyPart: "",
        priorInjuryDate: "",
        othersText: "",
      },
    ],

    treatmentRfaFileupload: null,

    treatmentPR2Fileupload: null,
    treatmentAuthorizationsFileupload: null,
    treatmentDenialsFileupload: null,
    treatmentMiscellaneousFileupload: null,
    treatmentRfiDocumentFileupload: null,
    treatmentDetails: [
      {
        fileUpload: null,
        requestedType: [],
        requestedDate: "",
        approvedDate: "",
        approvedType: "",
        deniedDate: "",
        deniedType: "",
        utilizationReview: "",
        urDate: "",
        prFileupload: null,
        authorizationsFileupload: null,
        denialsFileupload: null,
        miscellaneousFileupload: null,
        rfiFile: null,
        interpretersLookUp: [],
        transportersLookUp: [],
        mpnLookup: [],
      },
    ],
    workStatusDocumentFileupload: null,
    workStatusDetails: [
      {
        limitedLifting: "",
        limitedCarrying: "",
        limitedPulling: "",
        limitedPushing: "",
        targetLifting: "",
        targetCarrying: "",
        targetPulling: "",
        targetPushing: "",
        liftingAcc: "",
        carryingAcc: "",
        pullingAcc: "",
        pushingAcc: "",
        liftingErr: "",
        carryingErr: "",
        pullingErr: "",
        pushingErr: "",
        hoursWorkedLifting: "",
        hoursWorkedCarrying: "",
        hoursWorkedPulling: "",
        hoursWorkedPushing: "",
        others: "",
        othersAcc: "",
        othersErr: "",
        // accomodation: '',
        // recommendation: '',
        // fullDuty: '',
        // employerRestrictionResponse: '',
        disabilitySdi: "",
        disabilityLtd: "",
        disabilitySsdi: "",
        workStatusFileupload: null,
      },
    ],
    employer: {},
  };

  const formik = useFormik({
    initialValues: {
      ...employeeDetails,
      claimNumber: employeeDetails.claimsDetail[0].claimNumber,
      claimsDetail:
        employeeDetails.claimsDetail === null ||
        employeeDetails.claimsDetail === undefined ||
        employeeDetails.claimsDetail.length === 0
          ? blankEmployeeData.claimsDetail
          : employeeDetails.claimsDetail,
      nurseCaseManager: (() => {
        if (
          employeeDetails.claimsAdminList === null ||
          employeeDetails.claimsAdminList === undefined ||
          employeeDetails.claimsAdminList.length === 0
        ) {
          return null;
        } else {
          let newObject = employeeDetails.claimsAdminList.find(
            (item) => item.title === "NURSECASEMANAGER"
          );

          if (newObject) {
            return newObject;
          }

          return null;
        }
      })(),
      attorney: (() => {
        if (
          employeeDetails.claimsAdminList === null ||
          employeeDetails.claimsAdminList === undefined ||
          employeeDetails.claimsAdminList.length === 0
        ) {
          return null;
        } else {
          let newObject = employeeDetails.claimsAdminList.find(
            (item) => item.title === "ATTORNEY"
          );

          if (newObject) {
            return newObject;
          }

          return null;
        }
      })(),
      claimExaminer: (() => {
        if (
          employeeDetails.claimsAdminList === null ||
          employeeDetails.claimsAdminList === undefined ||
          employeeDetails.claimsAdminList.length === 0
        ) {
          return null;
        } else {
          let newObject = employeeDetails.claimsAdminList.find(
            (item) => item.title === "CLAIMEXAMINER"
          );

          if (newObject) {
            return newObject;
          }

          return null;
        }
      })(),
      jobDetailsFileupload:
        employeeDetails.jobDetailsFileupload === null ||
        employeeDetails.jobDetailsFileupload === undefined
          ? blankEmployeeData.jobDetailsFileupload
          : employeeDetails.jobDetailsFileupload,
      rtwFileUpload:
        employeeDetails.rtwFileUpload === null ||
        employeeDetails.rtwFileUpload === undefined
          ? blankEmployeeData.rtwFileUpload
          : employeeDetails.rtwFileUpload,
      jobDetails:
        employeeDetails.jobDetails === null ||
        employeeDetails.jobDetails === undefined ||
        employeeDetails.jobDetails.length === 0
          ? blankEmployeeData.jobDetails
          : employeeDetails.jobDetails,
      stp1:
        employeeDetails.stp1 === null || employeeDetails.stp1 === undefined
          ? blankEmployeeData.stp1
          : employeeDetails.stp1,
      stp2:
        employeeDetails.stp2 === null || employeeDetails.stp2 === undefined
          ? blankEmployeeData.stp2
          : employeeDetails.stp2,
      stp3:
        employeeDetails.stp3 === null || employeeDetails.stp3 === undefined
          ? blankEmployeeData.stp3
          : employeeDetails.stp3,
      visitDetails:
        employeeDetails.visitDetails === null ||
        employeeDetails.visitDetails === undefined ||
        employeeDetails.visitDetails.length === 0
          ? blankEmployeeData.visitDetails
          : employeeDetails.visitDetails,
      diagnosis:
        employeeDetails.diagnosis === null ||
        employeeDetails.diagnosis === undefined
          ? blankEmployeeData.diagnosis
          : employeeDetails.diagnosis,
      priorCareFileupload:
        employeeDetails.priorCareFileupload === null ||
        employeeDetails.priorCareFileupload === undefined
          ? blankEmployeeData.priorCareFileupload
          : employeeDetails.priorCareFileupload,
      currentCareDetails:
        employeeDetails.currentCareDetails === null ||
        employeeDetails.currentCareDetails === undefined ||
        employeeDetails.currentCareDetails.length === 0
          ? blankEmployeeData.currentCareDetails
          : employeeDetails.currentCareDetails,
      priorCareDetails:
        employeeDetails.priorCareDetails === null ||
        employeeDetails.priorCareDetails === undefined ||
        employeeDetails.priorCareDetails.length === 0
          ? blankEmployeeData.priorCareDetails
          : employeeDetails.priorCareDetails,
      treatmentRfaFileupload:
        employeeDetails.treatmentRfaFileupload === null ||
        employeeDetails.treatmentRfaFileupload === undefined
          ? blankEmployeeData.treatmentRfaFileupload
          : employeeDetails.treatmentRfaFileupload,

      treatmentPR2Fileupload:
        employeeDetails.treatmentPR2Fileupload === null ||
        employeeDetails.treatmentPR2Fileupload === undefined
          ? blankEmployeeData.treatmentPR2Fileupload
          : employeeDetails.treatmentPR2Fileupload,

      treatmentAuthorizationsFileupload:
        employeeDetails.treatmentAuthorizationsFileupload === null ||
        employeeDetails.treatmentAuthorizationsFileupload === undefined
          ? blankEmployeeData.treatmentAuthorizationsFileupload
          : employeeDetails.treatmentAuthorizationsFileupload,

      treatmentDenialsFileupload:
        employeeDetails.treatmentDenialsFileupload === null ||
        employeeDetails.treatmentDenialsFileupload === undefined
          ? blankEmployeeData.treatmentDenialsFileupload
          : employeeDetails.treatmentDenialsFileupload,

      treatmentMiscellaneousFileupload:
        employeeDetails.treatmentMiscellaneousFileupload === null ||
        employeeDetails.treatmentMiscellaneousFileupload === undefined
          ? blankEmployeeData.treatmentMiscellaneousFileupload
          : employeeDetails.treatmentMiscellaneousFileupload,
      treatmentRfiDocumentFileupload:
        employeeDetails.treatmentRfiDocumentFileupload === null ||
        employeeDetails.treatmentRfiDocumentFileupload === undefined
          ? blankEmployeeData.treatmentRfiDocumentFileupload
          : employeeDetails.treatmentRfiDocumentFileupload,
      treatmentDetails:
        employeeDetails.treatmentDetails === null ||
        employeeDetails.treatmentDetails === undefined ||
        employeeDetails.treatmentDetails.length === 0
          ? blankEmployeeData.treatmentDetails
          : employeeDetails.treatmentDetails,
      treatRequestedType: (() => {
        if (
          employeeDetails.treatmentDetails === null ||
          employeeDetails.treatmentDetails === undefined ||
          employeeDetails.treatmentDetails.length === 0
        ) {
          return [];
        } else {
          let reqTypeValue =
            employeeDetails.treatmentDetails[0].requestedType !== null &&
            employeeDetails.treatmentDetails[0].requestedType !== undefined
              ? employeeDetails.treatmentDetails[0].requestedType
              : [];

          if (typeof reqTypeValue === "string") {
            return reqTypeValue.split(",");
          } else {
            return reqTypeValue;
          }
        }
      })(),
      employer:
        employeeDetails.employer === null ||
        employeeDetails.employer === undefined
          ? blankEmployeeData.employer
          : employeeDetails.employer,

      claimsDetail:
        employeeDetails.claimsDetail === null ||
        employeeDetails.claimsDetail === undefined ||
        employeeDetails.claimsDetail.length === 0
          ? blankEmployeeData.claimsDetail
          : employeeDetails.claimsDetail,

      workStatusDetails:
        employeeDetails.workStatusDetails === null ||
        employeeDetails.workStatusDetails === undefined ||
        employeeDetails.workStatusDetails.length === 0
          ? blankEmployeeData.workStatusDetails
          : employeeDetails.workStatusDetails,

      workStatusDocumentFileupload:
        employeeDetails.workStatusDocumentFileupload === null ||
        employeeDetails.workStatusDocumentFileupload === undefined
          ? blankEmployeeData.workStatusDocumentFileupload
          : employeeDetails.workStatusDocumentFileupload,
    },

    onSubmit: async (values) => {
      values["employee"]["userIdVal"] = currentUser.id;
      values.claimNumber = values.claimsDetail[0].claimNumber;
      if (values.jobDetails[0].fileUpload) {
        const jobDetailsBase64 = await getBase64(
          values.jobDetails[0].fileUpload
        );

        values.jobDetailsFileupload = {
          content: jobDetailsBase64,
          contentType: values.jobDetails[0].fileUpload.type,
          description: "jobDetails",
          name: values.jobDetails[0].fileUpload.name,
        };
      }
      // Policy //
      if (values.jobDetails[0].rtwPolicyfileUpload) {
        const jobDetailsBase64 = await getBase64(
          values.jobDetails[0].rtwPolicyfileUpload
        );

        values.rtwFileUpload = {
          content: jobDetailsBase64,
          contentType: values.jobDetails[0].rtwPolicyfileUpload.type,
          description: "Accommodate RTW policy",
          name: values.jobDetails[0].rtwPolicyfileUpload.name,
        };
      }

      // Policy //
      if (values.priorCareDetails[0].fileUpload) {
        const priorCareBase64 = await getBase64(
          values.priorCareDetails[0].fileUpload
        );

        values.priorCareFileupload = {
          content: priorCareBase64,
          contentType: values.priorCareDetails[0].fileUpload.type,
          description: "priorCare",
          name: values.priorCareDetails[0].fileUpload.name,
        };
      }

      if (values.treatmentDetails[0].fileUpload) {
        const treatmentRfaBase64 = await getBase64(
          values.treatmentDetails[0].fileUpload
        );

        values.treatmentRfaFileupload = {
          content: treatmentRfaBase64,
          contentType: values.treatmentDetails[0].fileUpload.type,
          description: "treatmentRfa",
          name: values.treatmentDetails[0].fileUpload.name,
        };
      }

      //file upload new added
      if (values.workStatusDetails[0].workStatusFileupload) {
        const workStatusDocumentBase64 = await getBase64(
          values.workStatusDetails[0].workStatusFileupload
        );

        values.workStatusDocumentFileupload = {
          content: workStatusDocumentBase64,
          contentType: values.workStatusDetails[0].workStatusFileupload.type,
          description: "workStatusDocument",
          name: values.workStatusDetails[0].workStatusFileupload.name,
        };
      }

      if (values.treatmentDetails[0].prFileupload) {
        const treatmentpR2Base64 = await getBase64(
          values.treatmentDetails[0].prFileupload
        );

        values.treatmentPR2Fileupload = {
          content: treatmentpR2Base64,
          contentType: values.treatmentDetails[0].prFileupload.type,
          description: "treatmentPR2",
          name: values.treatmentDetails[0].prFileupload.name,
        };
      }

      if (values.treatmentDetails[0].authorizationsFileupload) {
        const treatmentAuthorizationsBase64 = await getBase64(
          values.treatmentDetails[0].authorizationsFileupload
        );

        values.treatmentAuthorizationsFileupload = {
          content: treatmentAuthorizationsBase64,
          contentType: values.treatmentDetails[0].authorizationsFileupload.type,
          description: "treatmentAuthorizations",
          name: values.treatmentDetails[0].authorizationsFileupload.name,
        };
      }

      if (values.treatmentDetails[0].denialsFileupload) {
        const treatmentDenialsBase64 = await getBase64(
          values.treatmentDetails[0].denialsFileupload
        );

        values.treatmentDenialsFileupload = {
          content: treatmentDenialsBase64,
          contentType: values.treatmentDetails[0].denialsFileupload.type,
          description: "treatmentDenials",
          name: values.treatmentDetails[0].denialsFileupload.name,
        };
      }

      if (values.treatmentDetails[0].miscellaneousFileupload) {
        const treatmentMiscellaneousBase64 = await getBase64(
          values.treatmentDetails[0].miscellaneousFileupload
        );

        values.treatmentMiscellaneousFileupload = {
          content: treatmentMiscellaneousBase64,
          contentType: values.treatmentDetails[0].miscellaneousFileupload.type,
          description: "treatmentMiscellaneous",
          name: values.treatmentDetails[0].miscellaneousFileupload.name,
        };
      }
       if (values.treatmentDetails[0].rfiFile) {
         const treatmentRFIBase64 = await getBase64(
           values.treatmentDetails[0].rfiFile
         );
         values.treatmentRfiDocumentFileupload = {
           content: treatmentRFIBase64,
           contentType: values.treatmentDetails[0].rfiFile.type,
           description: "treatmentRFI",
           name: values.treatmentDetails[0].rfiFile.name,
         };
       }
      values.treatmentDetails[0].requestedType =
        values.treatRequestedType.join(",");
      employeeServices
        .editEmployee(values)
        .then((response) => setSnackBarOpen(true));
      setFlagCheck("N");
    },
  });

  const handleSnackBarClose = (event, reason) => {
    setSnackBarOpen(false);
  };

  //* get base64 from file data
  const getBase64 = (fileData) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve(reader.result.split(";")[1].split(",")[1]);

      reader.onerror = reject;
      reader.readAsDataURL(fileData);
    });
  };

  const handleChangeStatusFlag = () => {
    employeeServices
      .changeStatusFlag(currentUser.id, employeeDetails.employee.id)
      .then((response) => {
        if (response && response.data === "SUCCESS") {
          //code
        }
      })
      .catch();
  };

  const [openDocumentHistory, setOpenDocumentHistory] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const handleDocumentHistory = () => {
    employeeServices
      .getDocumentHistory(
        employeeDetails.employee.id,
        employeeDetails.claimNumber
      )
      .then((response) => {
        if (response && response.data) {
          setDocumentList(response.data);
        }
      })
      .catch();

    setOpenDocumentHistory(true);
  };

  const handleDocumentHistoryClose = () => {
    setOpenDocumentHistory(false);
  };


  let border={
    borderRadius:"10px",
    border:"1px solid lightgrey"
  }
  let first_row={
    minHeight:"255px",
    borderRadius:"10px",
    border:"1px solid lightgrey"

  }

  let second_row={
    borderRadius:"10px",
    minHeight:"150px",
    border:"1px solid lightgrey"


  }
let third_row={
minHeight:"230px",
borderRadius:"10px",
border:"1px solid lightgrey"



}
let marginBtm={
  marginBottom:"4px"

}
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box style={{ paddingBottom: 70 }}>
          {ifRole([ROLE.EMPLOYER], currentUser.role) && (
            <>
  <Grid container spacing={1} style={marginBtm}>
    <Grid item xs={12} md={5}>
      <Box 
      bgcolor="#e1f5fe"
      padding={1} style={first_row}>
        <EDEDemographics formik={formik} />
      </Box>
    </Grid>

    <Grid item xs={12} md={4}>
      <Box
      bgcolor="#fffde7"
        padding={1} style={first_row}>
        <EDEClaims formik={formik} />
      </Box>
    </Grid>

    <Grid item xs={12} md={3}>
      <Box 
      bgcolor="#e9fafa"
       padding={1} style={first_row}>
        <EDEEmployer formik={formik} employeeDetails={employeeDetails} setFlagCheck={setFlagCheck} />
      </Box>
    </Grid>
  </Grid>

                <Grid container spacing={1} style={marginBtm}>
                  <Grid item md={4} sm={6} xs={12}  >
                   <Box
                      bgcolor="#e9fafa"

                     padding={1} style={third_row}>
                    <EDEVisits
                      formik={formik}
                      employeeDetails={employeeDetails}
                    />
                    </Box>
                  </Grid>

                  <Grid item md={4} sm={6} xs={12} >
                    <Box  
                      bgcolor="#fffde7"

                    padding={1} style={third_row}>
                    <EDEPrimaryTP formik={formik} />
                    </Box>
                  </Grid>

                  <Grid item md={4}  >
                    <Box 
                    bgcolor="#e1f5fe"
                     padding={1} style={third_row}>
                    <EDESecondaryTP formik={formik} />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={marginBtm}>
                  <Grid item md={12}  >
                   <Box
                      bgcolor="#e1f5fe"
                      padding={1} style={border}>
                   <EDEWorkStatus
                      formik={formik}
                      employeeDetails={employeeDetails}
                      setFlagCheck={setFlagCheck}
                    />
                   </Box>
                   
                  </Grid>
                </Grid>
            </>
          )}

          {!ifRole([ROLE.EMPLOYER], currentUser.role) && (
            <>
        
  <Grid container spacing={1} style={marginBtm}>
    <Grid item xs={12} md={5}>
      <Box 
         bgcolor="#e1f5fe"

       padding={1} style={border}>
        <EDEDemographics formik={formik} />
      </Box>
    </Grid>

    <Grid item xs={12} md={4}>
      <Box 
      bgcolor="#fffde7"

      padding={1} style={first_row}>
        <EDEClaims formik={formik} />
      </Box>
    </Grid>

    <Grid item xs={12} md={3}>
      <Box 
      bgcolor="#e9fafa"
       padding={1} style={first_row}>
        <EDEEmployer formik={formik} employeeDetails={employeeDetails} setFlagCheck={setFlagCheck} />
      </Box>
    </Grid>
  </Grid>
                <Grid container spacing={1} style={marginBtm}>
                  <Grid item md={3} sm={6} xs={12} >
                    <Box 
                       bgcolor="#e1f5fe"
                    padding={1} style={second_row}>

                    <EdeNcm formik={formik} />
                    </Box>
				       

                  </Grid>

                  <Grid item md={3} sm={6} xs={12} >
                    <Box 
                      bgcolor="#fffde7"

                    padding={1} style={second_row}>
                    <EdeClaimExaminer formik={formik} />

                    </Box>

                  </Grid>

                  <Grid item md={2} sm={4} xs={12}>
                    <Box 
                        bgcolor="#e9fafa"

                     padding={1} style={second_row}>
                    <EdeAttorney formik={formik} />
                    </Box>
				      

                  </Grid>
				   <Grid item md={2} sm={4} xs={12} >
				        <Box
                    bgcolor="#e1f5fe"
                  padding={1} style={second_row}>
                 <EdePrimaryTpSh formik={formik} />

                </Box>

                  </Grid>

                  <Grid item md={2} sm={4} xs={12}>
                    <Box 
                        bgcolor="#fffde7"

                      padding={1} style={second_row}>
                    <EdeUro formik={formik} />

                    </Box>

                  </Grid>
                </Grid>


                <Grid container spacing={1} style={marginBtm}>
                  <Grid item md={3} sm={6} xs={12}>
                    <Box 
                      bgcolor="#e9fafa"

                    padding={1} style={third_row}>
                    <EDEVisits
                      formik={formik}
                      employeeDetails={employeeDetails}
                    />
                    </Box>
                   
                  </Grid>

                  <Grid item md={3} sm={6} xs={12} >
                   <Box 
                       bgcolor="#e1f5fe"
                    padding={1} style={third_row}>
                    <EDEPriorInjuries
                      formik={formik}
                      employeeDetails={employeeDetails}
                    />
                    </Box>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}  >
                    <Box
                        bgcolor="#fffde7"

                     padding={1} style={third_row}>
                    <EDEDiagnosis
                      formik={formik}
                      employeeDetails={employeeDetails}
                    />
                    </Box>
                   
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}  >
                    <Box
                        bgcolor="#e1f5fe"
                     padding={1} style={third_row}>
                    <EDESecondaryTP formik={formik} />
                    </Box>
                   
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={marginBtm}>
                  <Grid item md={3} sm={12} xs={12}>
                    <Box
                        bgcolor="#e9fafa"
                       padding={1} style={border}>
                    <EDEPriorCare formik={formik} employeeDetails={employeeDetails}/>
                    <EDECurrentCare formik={formik} />
                    </Box>
                   
                  </Grid>
                  <Grid item md={9} >
                    <Box
                        bgcolor="#e1f5fe"


                      padding={1} style={border}>
                    <EDEWorkStatus
                      formik={formik}
                      employeeDetails={employeeDetails}
                      setFlagCheck={setFlagCheck}
                    />
                    </Box>
                   
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box style={border}>
                  <EDETreatment
                  formik={formik}
                  employeeDetails={employeeDetails}
                  setFlagCheck={setFlagCheck}

                />
                </Box>
                  </Grid>
                </Grid>
               
            </>
          )}
        </Box>

        <AppBar
          position="fixed"
          style={{
            top: "auto",
            bottom: 0,
          }}
        >
          <Toolbar>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              type="submit"
              disabled={ifRole([ROLE.EMPLOYEE], currentUser.role)}
            >
              Submit
            </Button>
            {setOpenDetail && (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  if (flagCheck === "Y") {
                    if (
                      window.confirm(
                        "Since you have notified the stakeholders about the changes, they will be saved."
                      )
                    ) {
                      formik.handleSubmit();
                      handleChangeStatusFlag();
                      getEmployees();
                      return;
                    }
                  } else {
                    handleChangeStatusFlag();
                    setOpenDetail(false);
                    if (getEmployees) getEmployees();
                  }
                }}
              >
                Close
              </Button>
            )}
            <Button
              color="inherit"
              size="small"
              onClick={() => handleDocumentHistory()}
            >
              Document History
            </Button>
          </Toolbar>
        </AppBar>
      </form>

      {openDocumentHistory && (
        <EDEDocumentHistory
          openDocumentHistory={openDocumentHistory}
          documentList={documentList}
          handleDocumentHistoryClose={handleDocumentHistoryClose}
          handleDocumentHistory={handleDocumentHistory}
          currentUser={currentUser}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message="Submitted successfully."
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EmployeeEditComponent);
