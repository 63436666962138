import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import TabPanel from "../../components/common/tab-panel.component";
import { a11yProps } from "../../utils/common-utils";
import Employee from "../../components/employee/employee.component";
// import Employer from "../../components/employer/employer.component";
// import Uro from "../../components/uro/uro.component";
// import Provider from "../../components/provider/provider.component";
// import Attorney from "../../components/claims-administrator/attorney.component";
// import ClaimsExaminer from "../../components/claims-administrator/claims-examiner.component";
// import NurseCaseManager from "../../components/claims-administrator/nurse-case-manager.component";
// import InsuranceCompany from "../../components/insurance-company/insurance-company.component";
// import Staff from "../../components/staff/staff.component";
import Message from "../../components/message/message.component";
import Task from "../../components/task/task.component";
import Dashboard from "../../components/common/dashboard.component";
import Header from "../../components/common/header/header.component";

const StaffHomePage = () => {
  const [value, setValue] = React.useState(0);
  const idString = "home";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />

      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Dashboard" {...a11yProps(idString, 0)} />
          <Tab label="Employee" {...a11yProps(idString, 1)} />
          {/* <Tab label="Employer" {...a11yProps(idString, 2)} />
          <Tab label="Provider" {...a11yProps(idString, 3)} />
          <Tab label="HCP Staff" {...a11yProps(idString, 4)} />
          <Tab label="Insurance Company" {...a11yProps(idString, 5)} />
          <Tab label="Claims Administrator" {...a11yProps(idString, 6)} />
          <Tab label="Nurse Case Manager" {...a11yProps(idString, 7)} />
          <Tab label="Attorney" {...a11yProps(idString, 8)} />
          <Tab label="Uro" {...a11yProps(idString, 9)} /> */}
          <Tab label="Messages" {...a11yProps(idString, 2)} />
          <Tab label="Task" {...a11yProps(idString, 3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} idString={idString}>
        <Dashboard />
      </TabPanel>
      <TabPanel value={value} index={1} idString={idString}>
        <Employee />
      </TabPanel>
      {/* <TabPanel value={value} index={2} idString={idString}>
        <Employer />
      </TabPanel>
      <TabPanel value={value} index={3} idString={idString}>
        <Provider />
      </TabPanel>
      <TabPanel value={value} index={4} idString={idString}>
        <Staff />
      </TabPanel>
      <TabPanel value={value} index={5} idString={idString}>
        <InsuranceCompany />
      </TabPanel>
      <TabPanel value={value} index={6} idString={idString}>
        <ClaimsExaminer />
      </TabPanel>
      <TabPanel value={value} index={7} idString={idString}>
        <NurseCaseManager />
      </TabPanel>
      <TabPanel value={value} index={8} idString={idString}>
        <Attorney />
      </TabPanel>
      <TabPanel value={value} index={9} idString={idString}>
        <Uro />
      </TabPanel> */}
      <TabPanel value={value} index={2} idString={idString}>
        <Message />
      </TabPanel>
      <TabPanel value={value} index={3} idString={idString}>
        <Task />
      </TabPanel>
    </>
  );
};

export default StaffHomePage;
