import axios from 'axios';

import { INSURANCE_COMPANY_API_URL } from '../../utils/settings';

class InsuranceCompanyServices {
    getInsuranceCompanyList() {
        return axios.get(`${INSURANCE_COMPANY_API_URL}/insurancecompanies`);
    }

    createInsuranceCompany(data) {
        return axios({
            method: 'POST',
            url: `${INSURANCE_COMPANY_API_URL}/insurancecompany`,
            data,
        });
    }

    updateInsuranceCompany(data) {
        return axios({
            method: 'PUT',
            url: `${INSURANCE_COMPANY_API_URL}/insurancecompany`,
            data,
        });
    }
}

export default new InsuranceCompanyServices();