import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import EmployeeEditComponent from '../../components/employee/employee-detail/employee-detail-edit.component';
import EmployeeService from '../../services/employee/employee.services';
import Header from '../../components/common/header/header.component';

const EmployeeHomePage = (props) => {
  const [employeeDetail, setEmployeeDetail] = useState('');

  const getEmployee = () => {
    EmployeeService.getEmployee(props.currentUser.id)
      .then((response) => {
        setEmployeeDetail(response.data);
      })
      .catch();
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <>
      <Header />
      {employeeDetail !== '' && (
        <EmployeeEditComponent
          employeeDetails={employeeDetail}
          setOpenDetail={false}
          getEmployees={false}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EmployeeHomePage);
