import axios from 'axios';

import { USER_API_URL, MESSAGE_API_URL } from '../../utils/settings';

class MessageServices {
  getUserByRole(role) {
    return axios.get(`${USER_API_URL}/${role}/fetchuserbyrole`);
  }

  sendMessages(data) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/sendmessages`,
      data,
    });
  }

  readUnread(Tid) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/flagupdate/${Tid}`,
    });
  }

  getMessagesByUserId(userid) {
    return axios.get(`${MESSAGE_API_URL}/fetchMessages/${userid}`);
  }

  getMessagesBySenderUserId(userid) {
    return axios.get(`${MESSAGE_API_URL}/fetchsendmessage/${userid}`);
  }

  sendMessagesToStakeHolders(data) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/messagestostakeholder`,
      data,
    });
  }
}

export default new MessageServices();