import {Dialog,DialogContent,Button, Typography, Grid,FormControlLabel,Checkbox,Snackbar,AppBar,Toolbar,TextField, Tooltip} from "@material-ui/core"
import {  useState } from "react";
import AddIcon from '@material-ui/icons/Add';
import providerServices from "../../services/provider/provider.services";
import CancelIcon from "@material-ui/icons/Cancel"

export default function EmployeeRFA({openDetail,setOpenDetail})
{
    const handleClose=()=>{
        setOpenDetail(false);
    }

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [RFA,setRFA]=useState({
        NewRequest:false,
        Resubmission:false,
        ExpeditedReview:false,
        OralRequest:false,
        EmpName:"",
        EmpInjuryDate:"",
        EmpBirthDate:"",
        EmpClaimNumber:"",
        EmpEmployer:"",
        PhysicianName:"",
        PhysicianPracticeName:"",
        PhysicianContact:"",
        PhysicianAddress:"",
        PhysicianCity:"",
        PhysicianState:"",
        PhysicianZip:"",
        PhysicianPhone:"",
        PhysicianFax:"",
        PhysicianSpeciality:"",
        PhysicianNPI:"",
        PhysicianEmail:"",
        ClaimCompanyName:"",
        ClaimContactName:"",
        ClaimAddress:"",
        ClaimCity:"",
        ClaimState:"",
        ClaimZip:"",
        ClaimPhone:"",
        ClaimFax:"",
        TreatmentDate:"",
        URDApproved:false,
        URDDenied:false,
        URDDelay:false,
        URDRequestTreatment:false,
        URDLiabilityTreatment:false,
        AuthorizationNo:"",
        URDDate:"",
        URDAgentName:"",
        URDPhone:"",
        URDFax:"",
        URDEmail:"",
        URDComments:""
    })
    
    const handlechange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        setRFA({ ...RFA, [name]: value });
      };


      const Style={
        height:"50px",
        color:"white",
        display:"flex",
        alignItems:"center",
        paddingLeft:"20px",
        backgroundColor:"#3f51b5",
        borderRadius:"5px",

      }



      const [diagnosisFields, setDiagnosisFields] = useState(1);
        const [diagnosis, setDiagnosis] = useState([]);
// console.log(diagnosis);
      const handleDiagnosisField = () => {
        setDiagnosisFields((prevNumFields) => prevNumFields + 1);
      };
      const handleDiagnosisChange = (e, index) => {
        const { name, value } = e.target;
        setDiagnosis((prevFormData) => {
          const newFormData = [...prevFormData];
          newFormData[index] = { ...newFormData[index], [name]: value };
          return newFormData;
        });
      };
      const handleDeleteDiagnosis = (index) => {
        setDiagnosisFields((prevNumFields) => prevNumFields - 1);
        setDiagnosis((prevFormData) => {
          const newFormData = [...prevFormData];
          newFormData.splice(index, 1);
          return newFormData;
        });
      };
      const DiagnosisFields = () => {
        const textFields = [];
        for (let i = 0; i < diagnosisFields; i++) {
          textFields.push(
            <div key={i} style={{display:"flex",justifyContent:"space-between"}}>
              <TextField
                label={`Enter Diagnosis`}
                variant="outlined"
                name={`diagnosis-${i}`}
                value={diagnosis[i]?.[`diagnosis-${i}`] || ""}
                onChange={(e) => handleDiagnosisChange(e, i)}
                margin="normal"
                fullWidth
                required
              />
             {
 diagnosisFields>1?<Button onClick={() => handleDeleteDiagnosis(i)}>
 <CancelIcon />
</Button>:null
             }
            </div>
          );
        }
        return textFields;
      };
      

// ICD starts here


const [ICDFields, setICDFields] = useState(1);
const [ICDCode, setICDCode] = useState([]);
// console.log(ICDCode);
const handleICDField = () => {
setICDFields((prevNumFields) => prevNumFields + 1);
};
const handleICDChange = (e, index) => {
const { name, value } = e.target;
setICDCode((prevFormData) => {
  const newFormData = [...prevFormData];
  newFormData[index] = { ...newFormData[index], [name]: value };
  return newFormData;
});
};
const handleDeleteICDField = (index) => {
  setICDFields((prevNumFields) => prevNumFields - 1);
  setICDCode((prevFormData) => {
    const newFormData = [...prevFormData];
    newFormData.splice(index, 1);
    return newFormData;
  });
};
const renderICDFields = () => {
  const fields = [];
  for (let i = 0; i < ICDFields; i++) {
    fields.push(
      <div key={i} style={{display:"flex",justifyContent:"space-between"}}>
        <TextField
          label="Enter ICD-Code"
          variant="outlined"
          name={`ICDCode-${i}`}
          value={ICDCode[i]?.[`ICDCode-${i}`] || ""}
          onChange={(e) => handleICDChange(e, i)}
          margin="normal"
          fullWidth
          required
        />
    { ICDFields>1?
 <Button
 onClick={() => handleDeleteICDField(i)}
>
 <CancelIcon/>
</Button>:null
    }   
      </div>
    );
  }
  return fields;
};


// Service Field


const [ServiceFields, setServiceFields] = useState(1);
const [Service, setService] = useState([]);
// console.log(Service);
const handleServiceField = () => {
setServiceFields((prevNumFields) => prevNumFields + 1);
};
const handleServiceChange = (e, index) => {
const { name, value } = e.target;
setService((prevFormData) => {
  const newFormData = [...prevFormData];
  newFormData[index] = { ...newFormData[index], [name]: value };
  return newFormData;
});
};
const handleDeleteServiceField = (index) => {
  setServiceFields((prevNumFields) => prevNumFields - 1);
  setService((prevFormData) => {
    const newFormData = [...prevFormData];
    newFormData.splice(index, 1);
    return newFormData;
  });
};
const renderServiceFields = () => {
  const fields = [];
  for (let i = 0; i < ServiceFields; i++) {
    const value = Service[i]?.[`Service-${i}`] || "";
    fields.push(
      <div key={i} style={{display:"flex",justifyContent:"space-between"}}>
        <TextField
          label={`Enter Service`}
          variant="outlined"
          name={`Service-${i}`}
          value={value}
          onChange={(e) => handleServiceChange(e, i)}
          margin="normal"
          fullWidth
          required
        />
      { ServiceFields>1?  <Button onClick={() => handleDeleteServiceField(i)}>
          <CancelIcon />
        </Button>:null }
      </div>
    );
  }
  return fields;
};

// CPT Starts HEre


const [CPTFields, setCPTFields] = useState(1);
const [CPT, setCPT] = useState([]);
// console.log(CPT);
const handleCPTField = () => {
setCPTFields((prevNumFields) => prevNumFields + 1);
};
const handleCPTChange = (e, index) => {
const { name, value } = e.target;
setCPT((prevFormData) => {
  const newFormData = [...prevFormData];
  newFormData[index] = { ...newFormData[index], [name]: value };
  return newFormData;
});
};
const handleDeleteCPTField = (index) => {
  setCPTFields((prevNumFields) => prevNumFields - 1);
  setCPT((prevFormData) => {
    const newFormData = [...prevFormData];
    newFormData.splice(index, 1);
    return newFormData;
  });
};
const renderCPTFields = () => {
  const fields = [];
  for (let i = 0; i < CPTFields; i++) {
    const value = CPT[i]?.[`CPT-${i}`] || "";
    fields.push(
      <div key={i} style={{display:"flex",justifyContent:"space-between"}}>
        <TextField
          label={`Enter CPT/HCPCS Code`}
          variant="outlined"
          name={`CPT-${i}`}
          value={value}
          onChange={(e) => handleCPTChange(e, i)}
          margin="normal"
          fullWidth
          required
        />
        {
  CPTFields>1?<Button onClick={() => handleDeleteCPTField(i)}>
  <CancelIcon />
</Button>:null
        }
      
      </div>
    );
  }
  return fields;
};
 

// Other info starts here


const [OtherInfoFields, setOtherInfoFields] = useState(1);
const [OtherInfo, setOtherInfo] = useState([]);
// console.log(OtherInfo);
const handleOtherInfoField = () => {
setOtherInfoFields((prevNumFields) => prevNumFields + 1);
};
const handleOtherInfoChange = (e, index) => {
const { name, value } = e.target;
setOtherInfo((prevFormData) => {
  const newFormData = [...prevFormData];
  newFormData[index] = { ...newFormData[index], [name]: value };
  return newFormData;
});
};
const handleOtherInfoDelete = (index) => {
  setOtherInfoFields((prevNumFields) => prevNumFields - 1);
  setOtherInfo((prevFormData) => {
    const newFormData = [...prevFormData];
    newFormData.splice(index, 1);
    return newFormData;
  });
};
const renderOtherInfoFields = () => {
  const textFields = [];
  for (let i = 0; i < OtherInfoFields; i++) {
    textFields.push(
      <div key={i}  style={{display:"flex",justifyContent:"space-between"}}>
        <TextField
          label={`Other Information`}
          variant="outlined"
          name={`OtherInfo-${i}`}
          value={OtherInfo[i]?.[`OtherInfo-${i}`] || ""}
          onChange={(e) => handleOtherInfoChange(e, i)}
          margin="normal"
          fullWidth
          required
        />
       {
 OtherInfoFields>1?<Button onClick={() => handleOtherInfoDelete(i)}>
 <CancelIcon />
</Button>:null
       }
      </div>
    );
  }
  return textFields;
};
const handleSnackBarClose = (event, reason) => {
  setSnackBarOpen(false);
};


const handleSubmit=async(e)=>{
  e.preventDefault();
  RFA.TreatmentDiagnosis=diagnosis;
  RFA.TreatmentICDCode=ICDCode;
  RFA.TreatmentService=Service;
  RFA.TreatmentCPT=CPT;
  RFA.TreatmentOtherInfo=OtherInfo;

  providerServices.createRFA({formDetails:RFA}).then((response)=>{
  if(response.status===200)
  {
    setSnackBarOpen(true);
  }
  else{
    alert("Something went wrong")
  }
 })

// console.log(RFA)

}

    return(
        <Dialog open={openDetail} fullScreen>
          <DialogContent dividers={true} style={{ padding: "40px"}}>
          <form onSubmit={handleSubmit}>
        
            <Typography variant="caption" align="center" style={{display:"flex",justifyContent:"center"}}>State of California, Division of Workers&#39; Compensation</Typography>
            <Typography variant="h6" align="center" >REQUEST FOR AUTHORIZATION</Typography>
            <Typography variant="h6" align="center" >DWC Form RFA</Typography>
            <Typography variant="caption" align="center" style={{display:"flex",justifyContent:"center"}}>
            Attach the Doctor&#39;s First Report of Occupational Injury or Illness, Form DLSR 5021, a Treating Physician&#39;s Progress
Report, DWC Form PR-2, or equivalent narrative report substantiating the requested treatment.
            </Typography>
           
    <hr />
         <Grid container spacing={3} justify="space-between">
            <Grid item md={6} sm={12} xs={12} >
            <FormControlLabel
              control={
                <Checkbox
                  name="NewRequest"
                  checked={RFA.NewRequest}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="New Request"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="ExpeditedReview"
                  checked={RFA.ExpeditedReview}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Expedited Review: Check box if employee faces an imminent and serious threat to his or her health"
            />

            </Grid>
            <Grid item md={6} sm={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Resubmission"
                  checked={RFA.Resubmission}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Resubmission - Change in Material Facts"
            />
             <FormControlLabel
              control={
                <Checkbox
                  name="OralRequest"
                  checked={RFA.OralRequest}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Check box if request is a written confirmation of a prior oral request"
            />
            </Grid>
         </Grid>
         <br />
         <hr/>
         <Grid container style={{marginTop:"20px"}} spacing={4}>
            <Grid item md={12} sm={12} xs={12} style={Style}>
            <Typography variant="h6">Employee Information</Typography>

            </Grid>
            <Grid item md={4} sm={12} xs={12} >
              <Typography variant="h6">Employee Name</Typography>

            <TextField
              id="outlined-basic"
              label="Employee Name"
              variant="outlined"
              type="text"
              name="EmpName"
              value={RFA.EmpName}
              fullWidth
              required
              onChange={handlechange}
            />
            <br />

              <Typography variant="h6">Claim Number</Typography>            
            <TextField
              id="outlined-basic"
              label="Claim Number"
              variant="outlined"
              type="text"
              name="EmpClaimNumber"
              value={RFA.EmpClaimNumber}
              fullWidth
              required
              onChange={handlechange}
            />
            </Grid>
            <Grid item md={4} sm={12} xs={12} >
              <Typography variant="h6">Date of Injury</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="date"
              name="EmpInjuryDate"
              value={RFA.EmpInjuryDate}
              fullWidth
              required
              onChange={handlechange}

            />
            
            <br />
            <Typography variant="h6">Employer Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Employer Name"
              variant="outlined"
              type="text"
              name="EmpEmployer"
              value={RFA.EmpEmployer}
              fullWidth
              required
              onChange={handlechange}

            />

            </Grid>
            <Grid item md={4} sm={12} xs={12}  >
              <Typography variant="h6">Date of Birth</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="date"
              name="EmpBirthDate"
              value={RFA.EmpBirthDate}
              fullWidth
              required
              onChange={handlechange}

            />

            </Grid>
         </Grid>
         <br /><br />
<hr />
{/* Requesting Physician Information */}
<Grid container spacing={3} style={{marginTop:"20px"}}>
<Grid item md={12} sm={12} xs={12} style={Style}>
            <Typography variant="h6">Requesting Physician Information</Typography>

            </Grid>
<Grid item md={3} sm={12} xs={12}>
<Typography variant="h6">Physician Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Physician Name"
              variant="outlined"
              type="text"
              name="PhysicianName"
              value={RFA.PhysicianName}
              fullWidth
              required
              onChange={handlechange}

            />
            <Typography variant="h6">Speciality</Typography>            
            <TextField
              id="outlined-basic"
              label="Speciality"
              variant="outlined"
              type="text"
              name="PhysicianSpeciality"
              value={RFA.PhysicianSpeciality}
              fullWidth
              required
              onChange={handlechange}

            />
             <Typography variant="h6">Address</Typography>            
            <TextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              type="text"
              name="PhysicianAddress"
              value={RFA.PhysicianAddress}
              fullWidth
              required

              onChange={handlechange}
            />



</Grid>

         <Grid item md={3} sm={12} xs={12}>
<Typography variant="h6">Practice Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Practice Name"
              variant="outlined"
              type="text"
              name="PhysicianPracticeName"
              value={RFA.PhysicianPracticeName}
              fullWidth
              required
              onChange={handlechange}

            />

<Typography variant="h6">NPI Number</Typography>            
            <TextField
              id="outlined-basic"
              label="NPI Number"
              variant="outlined"
              type="text"
              name="PhysicianNPI"
              value={RFA.PhysicianNPI}
              fullWidth
              required

              onChange={handlechange}
            />


<Typography variant="h6">City</Typography>            
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              type="text"
              name="PhysicianCity"
              value={RFA.PhysicianCity}
              fullWidth
              required

              onChange={handlechange}
            />
   

</Grid>
<Grid item md={3} sm={12} xs={12}>
<Typography variant="h6">Contact Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Contact Name"
              variant="outlined"
              type="text"
              name="PhysicianContact"
              value={RFA.PhysicianContact}
              fullWidth
              required
              onChange={handlechange}

            />
               <Typography variant="h6">Phone</Typography>            
            <TextField
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              type="number"
              name="PhysicianPhone"
              value={RFA.PhysicianPhone}
              fullWidth
              required
              onChange={handlechange}

            />
           
                     
<Typography variant="h6">State</Typography>            
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              type="text"
              name="PhysicianState"
              value={RFA.PhysicianState}
              fullWidth
              required
              onChange={handlechange}

            />
           


</Grid>
<Grid item md={3} sm={12} xs={12}>
    
<Typography variant="h6">Email</Typography>            
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="email"
              name="PhysicianEmail"
              value={RFA.PhysicianEmail}
              fullWidth
              required
              onChange={handlechange}

            />


<Typography variant="h6">Fax Number</Typography>            
            <TextField
              id="outlined-basic"
              label="Fax Number"
              variant="outlined"
              type="number"
              name="PhysicianFax"
              value={RFA.PhysicianFax}
              fullWidth
              required
              onChange={handlechange}

            />

<Typography variant="h6">Zip Code</Typography>            
            <TextField
              id="outlined-basic"
              label="Zip Code"
              variant="outlined"
              type="number"
              name="PhysicianZip"
              value={RFA.PhysicianZip}
              fullWidth
              required
              onChange={handlechange}

            />
            
</Grid>   
</Grid>
<br /><br />
<hr />

{/* Claims Administrator Information */}

<Grid container spacing={3} style={{marginTop:"20px"}}>
<Grid item md={12} sm={12} xs={12} style={Style}>
            <Typography variant="h6">Claims Administrator Information</Typography>

            </Grid>
    <Grid item md={3} sm={12} xs={12}>
    <Typography variant="h6">Company Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Company Name"
              variant="outlined"
              type="text"
              name="ClaimCompanyName"
              value={RFA.ClaimCompanyName}
              fullWidth
              required
              onChange={handlechange}

            />
               <Typography variant="h6">State</Typography>            
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              type="text"
              name="ClaimState"
              value={RFA.ClaimState}
              fullWidth
              required
              onChange={handlechange}
            />
    </Grid>
    <Grid item md={3} sm={12} xs={12}>
    <Typography variant="h6">Contact Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Contact Name"
              variant="outlined"
              type="text"
              name="ClaimContactName"
              value={RFA.ClaimContactName}
              fullWidth
              required
              onChange={handlechange}
            />
              <Typography variant="h6">Zip Code</Typography>            
            <TextField
              id="outlined-basic"
              label="Zip Code"
              variant="outlined"
              type="number"
              name="ClaimZip"
              value={RFA.ClaimZip}
              fullWidth
              required
              onChange={handlechange}
            />
    </Grid>
    <Grid item md={3} sm={12} xs={12}>
    <Typography variant="h6">Address</Typography>            
            <TextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              type="text"
              name="ClaimAddress"
              value={RFA.ClaimAddress}
              fullWidth
              required
              onChange={handlechange}
            />
                <Typography variant="h6">Phone</Typography>            
            <TextField
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              type="number"
              name="ClaimPhone"
              value={RFA.ClaimPhone}
              fullWidth
              required
              onChange={handlechange}
            />
    </Grid>
    <Grid item md={3} sm={12} xs={12}>
    <Typography variant="h6">City</Typography>            
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              type="text"
              name="ClaimCity"
              value={RFA.ClaimCity}
              fullWidth
              required
              onChange={handlechange}
            />

<Typography variant="h6">Fax Number</Typography>            
            <TextField
              id="outlined-basic"
              label="Fax Number"
              variant="outlined"
              type="number"
              name="ClaimFax"
              value={RFA.ClaimFax}
              fullWidth
              required
              onChange={handlechange}
            />
    </Grid>

</Grid>
<br /><br />
<hr />
{/* Requested Treatment */}
<Grid container spacing={3} style={{marginTop:"20px"}}>
<Grid item md={12} sm={12} xs={12} style={Style}>
            <Typography variant="h6">Requested Treatment</Typography>
           

            </Grid>
          
<Grid item md={4} sm={12} xs={12}>

    <Grid container>
      <Grid item sm={6} md={6}>
      <Typography variant="h6">Diagnosis</Typography>            
      </Grid>
      <Grid item sm={6} md={6} >
        <Tooltip title="Add more diagnosis">
      <Button variant="contained" color="primary" style={{float:"right"}} onClick={handleDiagnosisField}>
           <AddIcon/>
            </Button>
            </Tooltip>
      </Grid>
    </Grid>
     {DiagnosisFields()}
            
           
           
              <Grid container>
      <Grid item sm={6} md={6}>
      <Typography variant="h6">CPT/HCPCS Code</Typography>            
      </Grid>
      <Grid item sm={6} md={6}>
      <Tooltip title="Add more CPT/HCPCS Code">
      <Button variant="contained" color="primary" style={{float:"right"}} onClick={handleCPTField}>
             <AddIcon/>
            </Button>
          </Tooltip>
      </Grid>
    </Grid>
     {renderCPTFields()}
</Grid>
<Grid item md={4} sm={12} xs={12}>

<Grid container>
      <Grid item sm={6} md={6}>
      <Typography variant="h6">ICD-Code</Typography>            
      </Grid>
      <Grid item sm={6} md={6}>
        <Tooltip title="Add more ICD-Code">
      <Button variant="contained" color="primary" style={{float:"right"}} onClick={handleICDField}>
              <AddIcon/>
            </Button>
            </Tooltip>
      </Grid>
    </Grid>
     {renderICDFields()}


     <Grid container>
      <Grid item sm={8} md={8}>
      <Typography variant="h6">Other Information</Typography>            
      </Grid>
      <Grid item sm={4} md={4}>
        <Tooltip title="Add more Other Information">
      <Button variant="contained" color="primary" style={{float:"right"}} onClick={handleOtherInfoField}>
           <AddIcon/>
            </Button>
            </Tooltip>
      </Grid>
    </Grid>
     {renderOtherInfoFields()}


</Grid>
<Grid item md={4} sm={12} xs={12}>

<Grid container>
      <Grid item sm={8} md={8}>
      <Typography variant="h6">Service/ Good Requested</Typography>            
      </Grid>
      <Grid item sm={4} md={4}>
        <Tooltip title="Add more Service/ Good Requested">
      <Button variant="contained" color="primary" style={{float:"right"}} onClick={handleServiceField}>
             <AddIcon/>
            </Button>
            </Tooltip>
      </Grid>
    </Grid>
     {renderServiceFields()}
            <Typography variant="h6">Treatment Date</Typography>   
     <br />

            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              type="date"
              name="TreatmentDate"
              value={RFA.TreatmentDate}
              fullWidth
              required
              onChange={handlechange}
            />
</Grid>
</Grid>
<br /><br />
<hr />

{/* Claims Administrator */}

<Grid container spacing={3} style={{marginTop:"20px",marginBottom:"20px"}}>
<Grid item md={12} sm={12} xs={12} style={Style}>
            <Typography variant="h6">Claims Administrator/ Utilization Review Organization (URO) Response</Typography>
            </Grid>
<Grid item md={3} sm={12} xs={12}>
<FormControlLabel
              control={
                <Checkbox
                  name="URDApproved"
                  checked={RFA.URDApproved}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Approved"
            />
</Grid>
<Grid item md={4} sm={12} xs={12}>
<FormControlLabel
              control={
                <Checkbox
                  name="URDDenied"
                  checked={RFA.URDDenied}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Denied or Modified (See separate decision letter)"
            />
</Grid>

<Grid item md={5} sm={12} xs={12}>
<FormControlLabel
              control={
                <Checkbox
                  name="URDDelay"
                  checked={RFA.URDDelay}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Delay (See separate notification of delay)"
            />
</Grid>
<Grid item md={4} sm={12} xs={12}>
<FormControlLabel
              control={
                <Checkbox
                  name="URDRequestTreatment"
                  checked={RFA.URDRequestTreatment}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Requested treatment has been previously denied"
            />
</Grid>
<Grid item md={8} sm={12} xs={12}>
<FormControlLabel
              control={
                <Checkbox
                  name="URDLiabilityTreatment"
                  checked={RFA.URDLiabilityTreatment}
                  value="true"
                  onChange={handlechange}
                />
              }
              label="Liability for treatment is disputed (See separate letter)"
            />
</Grid>

<Grid item md={4} sm={12} xs={12}>
<Typography variant="h6">Authorization Number</Typography>            
            <TextField
              id="outlined-basic"
              label="Authorization Number"
              variant="outlined"
              type="text"
              name="AuthorizationNo"
              value={RFA.AuthorizationNo}
              fullWidth
              required
              onChange={handlechange}
            />
<Typography variant="h6">Phone</Typography>            
            
              <TextField
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              type="number"
              name="URDPhone"
              value={RFA.URDPhone}
              fullWidth
              required
              onChange={handlechange}
            />
            <Typography variant="h6">Comments</Typography>            
            
            <TextField
            id="outlined-basic"
            label="Comments"
            variant="outlined"
            type="number"
            multiline
            rows={4}
            name="URDComments"
            value={RFA.URDComments}
            fullWidth
            required
            onChange={handlechange}
          />
</Grid>
<Grid item md={4} sm={12} xs={12}>
<Typography variant="h6">Authorized Agent Name</Typography>            
            <TextField
              id="outlined-basic"
              label="Authorized Agent Name:"
              variant="outlined"
              type="text"
              name="URDAgentName"
              value={RFA.URDAgentName}
              fullWidth
              required
              onChange={handlechange}
            />
            <Typography variant="h6">Email</Typography>            
            
            <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            name="URDEmail"
            value={RFA.URDEmail}
            fullWidth
              required
            onChange={handlechange}
          />

</Grid>
<Grid item md={4} sm={12} xs={12}>
<Typography variant="h6">Date</Typography>            
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              type="date"
              name="URDDate"
              value={RFA.URDDate}
              fullWidth
              required
              onChange={handlechange}
            />
            <Typography variant="h6">Fax Number</Typography>            
            <TextField
              id="outlined-basic"
              label="Fax Number"
              variant="outlined"
              type="number"
              name="URDFax"
              value={RFA.URDFax}
              fullWidth
              required
              onChange={handlechange}
            />

</Grid>
</Grid>
<br /><br />
     
    
     
     
     
<AppBar
          position="fixed"
          style={{
            top: "auto",
            bottom: 0,
          }}
        >
          <Toolbar>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              type="submit"
            
            >
              Submit
            </Button>
            <Button style={{marginLeft:"20px"}}
            variant="outlined"
             color="inherit"
             size="small"
             onClick={handleClose}
              >Close</Button>
           
          </Toolbar>
        </AppBar>
     
     
     
        </form>
     
        <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message="RFA Form Submitted Successfully."
      />
          </DialogContent>
        </Dialog>
    )
}