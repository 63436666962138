import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, Button, Tooltip, Grid, Divider, Menu, MenuItem, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import TaskManageInfo from './task-manage-info.component';
import TaskList from './task-list.component';
import TaskService from '../../services/task/task.services';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const Task = (props) => {
    const classes = useStyles();
    const [taskList, setTaskList] = useState([]);
    const [taskManageOpen, setTaskManageOpen] = useState(false);
    const [categoryVal, setCategoryVal] = useState('CREATED');
    const options = ['Created By', 'Assigned To', 'Assigned For',];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (options[index] !== undefined) {
            if (options[index] === 'Created By') {
                setCategoryVal('CREATED');
            } else if (options[index] === 'Assigned To') {
                setCategoryVal('ASSIGNED_TO');
            } else if (options[index] === 'Assigned For') {
                setCategoryVal('ASSIGNED_FOR');
            }
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getTaskList = () => {
        TaskService.getTaskList(categoryVal, props.currentUser.id).then((response) => {
            setTaskList(response.data);
        }).catch();
    };

    useEffect(() => {
        getTaskList();
    }, [categoryVal]);

    const handleTaskManageClickOpen = () => {
        setTaskManageOpen(true);
    };

    return (
        <div>
          <Card square>
            <CardHeader
              action={
                <div>
                    <Grid container alignItems="center">
                        <List component="nav" aria-label="Device settings">
                            <ListItem button aria-haspopup="true" aria-controls="lock-menu"
                                aria-label="View Task" onClick={handleClickListItem} >
                                <ListItemText primary="View Task" secondary={options[selectedIndex]} />
                            </ListItem>
                        </List>
                        <Menu id="lock-menu" anchorEl={anchorEl} keepMounted 
                            open={Boolean(anchorEl)} onClose={handleMenuClose} >
                            {options.map((option, index) => (
                                <MenuItem key={option} selected={index === selectedIndex} 
                                    onClick={(event) => handleMenuItemClick(event, index)}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                        <Divider orientation="vertical"/>
                        <Tooltip title="Add Task">
                            <Button onClick={() => handleTaskManageClickOpen()} variant="contained"
                                startIcon={<AddIcon />} className={classes.button} size="small" >
                                Add Task
                            </Button>
                        </Tooltip>
                    </Grid>
                </div>  
              }
              title="Task Management"
            />
    
            <TaskList 
                taskList={taskList} 
                getTaskList={getTaskList}
            />
          </Card>
          <TaskManageInfo
            taskManageOpen={taskManageOpen}
            setTaskManageOpen={setTaskManageOpen}
            getTaskList={getTaskList}
          />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Task);