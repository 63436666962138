import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  FormControl,
  IconButton,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import EmployeeList from "./employee-list.component";
import EmployeeManageInfo from "./employee-manage-info.component";
import EmployeeService from "../../services/employee/employee.services";
import { ifRole } from "../../utils/common-utils";
import { ROLE } from "../../utils/settings";
import patientDemographicsService from "../../services/patient-demographics/patient-demographics.service";
import GetAppIcon from "@material-ui/icons/GetApp";

const Employee = (props) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [empManageOpen, setEmpManageOpen] = useState(false);
  const { currentUser } = props;
  const [filterPatient, setfilterPatient] = useState("All");
  const userId = currentUser.id;

  const getEmployees = () => {
    if (props.currentUser.role === "ADMIN") {
      EmployeeService.getEmployeeList()
        .then((response) => {
          setEmployeeList(response.data);
        })
        .catch();
    } else {
      EmployeeService.getEmployeeListByUser(props.currentUser.id)
        .then((response) => {
          setEmployeeList(response.data);
        })
        .catch();
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleEmpManageClickOpen = () => {
    setEmpManageOpen(true);
  };

  const handleFilterChange = (event) => {
    const claimType = event.target.value === "All" ? "" : event.target.value;
    setfilterPatient(event.target.value);
    EmployeeService.getEmployeeListByUser(props.currentUser.id, claimType)
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch();
  };

  const handleDownloadReport = async () => {
    await patientDemographicsService.getDownloadReport(userId).then((res) => {
      if (res.status === 200) {
        const base64Data = res.data;
        var binaryData = atob(base64Data);

        var arrayBuffer = new ArrayBuffer(binaryData.length);
        var uint8Array = new Uint8Array(arrayBuffer);

        for (var i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        var blob = new Blob([uint8Array], { type: "text/csv" });

        var downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "PI-Report";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    });
  };

  return (
    <div>
      <Card square>
        <CardHeader
          action={
            <div style={{ display: "flex", alignItems: "center" }}>
              {ifRole([ROLE.PROVIDER, ROLE.ATTORNEY], currentUser.role) && (
                <>
                  <FormControl>
                    <RadioGroup
                      aria-label="patient-filter"
                      name="patient-filter"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "15px",
                      }}
                      row
                      value={filterPatient}
                      onChange={handleFilterChange}
                    >
                      <FormLabel
                        style={{
                          marginRight: "15px",
                          fontSize: "16px",
                          color: "#000000de",
                        }}
                      >
                        Filter Patients :
                      </FormLabel>
                      <FormControlLabel
                        value="All"
                        control={<Radio color="primary" size="small" />}
                        label="All"
                      />
                      <FormControlLabel
                        value="WORKERS_COMP"
                        control={<Radio color="primary" size="small" />}
                        label="Workers Comp"
                      />
                      <FormControlLabel
                        value="PERSONAL_INJURY"
                        control={<Radio color="primary" size="small" />}
                        label="Personal Injury"
                      />
                    </RadioGroup>
                  </FormControl>
                 {ifRole([ROLE.ATTORNEY], currentUser.role) &&  <Tooltip title="Download Report">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{marginRight:"5px"}}
                    onClick={handleDownloadReport}
                  >
                    Report
                    <GetAppIcon
                      style={{ fontSize: "18px", margin: "5px" }}
                    />
                  </Button>
                  </Tooltip>}
                </>
              )}

              {!ifRole([ROLE.PROVIDER, ROLE.ATTORNEY], currentUser.role) && (
                <Tooltip title="Add Employee">
                  <IconButton
                    aria-label="Add Employee"
                    onClick={() => handleEmpManageClickOpen()}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          }
          title={
            ifRole([ROLE.PROVIDER, ROLE.ATTORNEY], currentUser.role)
              ? "Patient Roster"
              : "Employee"
          }
        />

        <EmployeeList
          employeeList={employeeList}
          getEmployees={getEmployees}
          props={props}
        />
      </Card>
      {empManageOpen && (
        <EmployeeManageInfo
          empManageOpen={empManageOpen}
          setEmpManageOpen={setEmpManageOpen}
          getEmployees={getEmployees}
          props={props}
        />
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Employee);
