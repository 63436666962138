import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../../redux/user/user.selectors';

import {
  TextField,
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  // Grid,
  // Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';

import NurseCaseManagerManageInfo from '../../../../claims-administrator/nurse-case-manager-manage-info.component';
import ClaimsAdministratorServices from '../../../../../services/claims-administrator/claims-administrator.services';

const EdeNcm = ({ formik, currentUser, ...otherProps }) => {
  const [nurseCaseManagerInfo, setNurseCaseManagerInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
  });

  const [nurseCaseManagerList, setNurseCaseManagerList] = useState([]);
  const [open, setOpen] = useState(false);

  const getNurseCaseManagers = () => {
    ClaimsAdministratorServices.getClaimsAdministratorList('NURSECASEMANAGER')
      .then((response) => {
        setNurseCaseManagerList(response.data);
      })
      .catch();
  };

  const handleClose = () => {
    setOpen(false);
    setNurseCaseManagerInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNurseCaseManagerInfo({
      ...nurseCaseManagerInfo,
      [name]: value,
    });
  };

  const createNurseCaseManager = () => {
    const { caEmail, caName, caPhone, caFax, caAddress } = nurseCaseManagerInfo;

    const nurseCaseManagerPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      title: 'NURSECASEMANAGER',
      fax: caFax,
      address: caAddress,
      userId: currentUser.id,
    };

    ClaimsAdministratorServices.createClaimsAdministrator(
      nurseCaseManagerPayload
    ).then((response) => {
      window.alert(response.data.message);
      getNurseCaseManagers();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createNurseCaseManager();
  };

  useEffect(() => {
    getNurseCaseManagers();
  }, []);

  return (
    <>
     {/*  <Grid item md={3} component={Box} bgcolor={green[50]}>
        <Paper elevation={0} square p={0.5} component={Box} bgcolor={green[50]}> */}
          <CardHeader
            title="Nurse Case Manager"
            color="text.secondary"
            p={1}
            component={Box}
            action={
              <Tooltip title="Add NCM">
                <IconButton
                  // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                  aria-label="Add NCM"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <Autocomplete
            name="nurseCaseManager"
            value={formik.values.nurseCaseManager}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={nurseCaseManagerList}
            onChange={(event, value) => {
              formik.setFieldValue('nurseCaseManager', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Nurse Case Manager"
              />
            )}
            size="small"
          />
     {/*    </Paper>
      </Grid> */}

      <NurseCaseManagerManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        nurseCaseManagerInfo={nurseCaseManagerInfo}
        manageType="ADD"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EdeNcm);
