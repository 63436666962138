import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../redux/user/user.selectors';

import AuthenticatedRoute from './authenticated-route';
import {
  FE_ADMIN_PAGE_URL,
  FE_ATTORNEY_PAGE_URL,
  FE_CLAIM_EXAMINER_PAGE_URL,
  FE_EMPLOYER_PAGE_URL,
  FE_URO_PAGE_URL,
  FE_NURSE_CASE_MANAGER_PAGE_URL,
  FE_PROVIDER_PAGE_URL,
  FE_SETPASSWORD_URL,
  FE_LOGIN_PAGE_URL,
  FE_STAFF_PAGE_URL,
  FE_EMPLOYEE_PAGE_URL,
  FE_WEBSITE_PAGE_URL,
  FE_TELEHEALTHMEETING_URL,
} from './strings';
import { conditionalRedirect } from './common-utils';

import WCPWebsite from '../pages/wcp-website/wcp-website.component';
import AppWelcomePage from '../pages/welcomepage/welcomepage.component';
import AdminHomePage from '../pages/admin-homepage/admin-homepage.component';
import { ROLE, WEB_URL } from './settings';
import setPassword from '../components/common/set-password/set-password.component';
import EmployerHomePage from '../pages/employer-homepage/employer-homepage.component';
import UroHomePage from '../pages/uro-homepage/uro-homepage.component';
import ProviderHomePage from '../pages/provider-homepage/provider-homepage.component';
import ExternalAdminHomePage from '../pages/external-admin-homepage/external-admin-homepage.component';
import StaffHomePage from '../pages/staff-homepage/staff-homepage.component';
import EmployeeHomePage from '../pages/employee-homepage/employee-homepage.component';
import EmployeeTelehealth from '../components/employee/employee-telehealth/employee-telehealth.component';
import AttorneyHomePage from "../pages/attorney-homepage/attorney-homepage.component"
const RoutesList = ({ currentUser, ...otherProps }) => {
  const {
    ADMIN,
    EMPLOYER,
    PROVIDER,
    CLAIMEXAMINER,
    ATTORNEY,
    NURSECASEMANAGER,
    STAFF,
    EMPLOYEE,
    URO,
  } = ROLE;

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          conditionalRedirect(
            currentUser,
            <Redirect to={FE_WEBSITE_PAGE_URL} />
          )
        }
      ></Route>
      <Route
        exact
        path={WEB_URL}
        render={() =>
          conditionalRedirect(
            currentUser,
            <Redirect to={FE_WEBSITE_PAGE_URL} />
          )
        }
      ></Route>
      <Route
        path={FE_WEBSITE_PAGE_URL}
        render={() => conditionalRedirect(currentUser, <WCPWebsite />)}
      ></Route>

      <Route
        path={FE_LOGIN_PAGE_URL}
        render={() => conditionalRedirect(currentUser, <AppWelcomePage />)}
      ></Route>

      <Route path={FE_SETPASSWORD_URL} component={setPassword} />

      <Route
        path={`${FE_TELEHEALTHMEETING_URL}/:role/:id`}
        component={EmployeeTelehealth}
      />

      <AuthenticatedRoute
        path={FE_ADMIN_PAGE_URL}
        role={ADMIN}
        component={AdminHomePage}
      />
      <AuthenticatedRoute
        path={FE_EMPLOYER_PAGE_URL}
        role={EMPLOYER}
        component={EmployerHomePage}
      />
      <AuthenticatedRoute
        path={FE_PROVIDER_PAGE_URL}
        role={PROVIDER}
        component={ProviderHomePage}
      />
      <AuthenticatedRoute
        path={FE_CLAIM_EXAMINER_PAGE_URL}
        role={CLAIMEXAMINER}
        component={ExternalAdminHomePage}
      />
      <AuthenticatedRoute
        path={FE_ATTORNEY_PAGE_URL}
        role={ATTORNEY}
        component={AttorneyHomePage}
      />
      <AuthenticatedRoute
        path={FE_NURSE_CASE_MANAGER_PAGE_URL}
        role={NURSECASEMANAGER}
        component={ExternalAdminHomePage}
      />
      <AuthenticatedRoute
        path={FE_STAFF_PAGE_URL}
        role={STAFF}
        component={StaffHomePage}
      />
      <AuthenticatedRoute
        path={FE_EMPLOYEE_PAGE_URL}
        role={EMPLOYEE}
        component={EmployeeHomePage}
      />
            <AuthenticatedRoute
        path={FE_URO_PAGE_URL}
        role={URO}
        component={UroHomePage}
      />
    </Switch>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(RoutesList);
