import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { Chip, Divider, Grid, Link, Tooltip } from "@material-ui/core";
import patientDemographicsService from "../../services/patient-demographics/patient-demographics.service";
import PdfViewer from "./personal-injury-pdf-viewer";
import { makeStyles } from "@material-ui/core/styles";
import { ifNullAndUndefined } from "../../utils/common-utils";

const useStyles = makeStyles((theme) => ({
  chip: {
    width: "70px",
    alignItems: "center",
  },
  actionBtn: {
    minWidth: "50px",
  },
  accordionPanel: {
    padding: 0,
    backgroundColor: "#eaeaea0d",
  },
  accordionContainer: {
    padding: "25px 50px 30px 50px",
    height: "auto",
  },
  summaryContainer: {
    borderBottom: "1px solid lightgray",
    paddingBottom: "15px",
  },
  verticalDivider: {
    borderRight: "1px solid lightgray",
    textAlign: "center",
  },
  horizontalDivider: {
    padding: "20px 0px",
    borderBottom: "1px solid lightgray",
  },
  subTitle: {
    fontWeight: 500,
    marginTop: "5px",
  },
  alignBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputSearch: {
    float: "right",
    width: "300px",
  },
}));

function Row(props) {
  const {
    row,
    handleClickOpen,
    handleClickAccordion,
    accordionData,
    upcomingAppointnemtsList,
    visitHistoryList,
    isOpen,
    openRow,
    setOpenRow,
    policyLimit,
    totalCharges,
    handleDocPreview,
    handleDocDownload,
    classes,
  } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left" component="th" scope="row">
          <Typography variant="body1">{row.patientName}</Typography>
        </TableCell>
        <TableCell align="center">
          <Chip
            size="small"
            variant="outlined"
            label={row.caseType}
            className={classes.chip}
          />
        </TableCell>
        <TableCell align="center">
          {row.caseType === "WCP" && (
            <Link
              href="#"
              onClick={() => {
                handleClickOpen(row.employee, row.claimNumberChartId);
              }}
            >
              <Typography variant="body1">{row.claimNumberChartId}</Typography>
            </Link>
          )}

          {row.caseType === "PI" && (
            <Typography variant="body1">{row.claimNumberChartId}</Typography>
          )}
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1">{row.injuryDate}</Typography>
        </TableCell>
        <TableCell align="center">
          {row.caseType === "PI" && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                if (!isOpen || row.claimNumberChartId !== openRow) {
                  handleClickAccordion(row.claimNumberChartId);
                } else {
                  setOpenRow(null);
                }
              }}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        {accordionData && (
          <TableCell className={classes.accordionPanel} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Grid container className={classes.accordionContainer}>
                <Grid item md={12} className={classes.summaryContainer}>
                  <Grid container>
                    <Grid item md={2} className={classes.verticalDivider}>
                      <Typography variant="body1">
                        Total Visits -{" "}
                        <strong>
                          {visitHistoryList.length > 0
                            ? visitHistoryList.length
                            : 0}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item md={2} className={classes.verticalDivider}>
                      <Typography variant="body1">
                        Total Charges -{" "}
                        <strong>
                          {!ifNullAndUndefined(totalCharges)
                            ? "$" + totalCharges
                            : "N/A"}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item md={2} style={{ textAlign: "center" }}>
                      <Typography variant="body1">
                        Policy Limit -{" "}
                        <strong>
                          {!ifNullAndUndefined(policyLimit)
                            ? "$" + policyLimit
                            : "N/A"}
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} className={classes.horizontalDivider}>
                  <Typography variant="h6" gutterBottom>
                    Appointments -
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subTitle}
                    gutterBottom
                  >
                    Upcoming
                  </Typography>
                  <TableContainer
                    component={Paper}
                    style={{ marginTop: "15px" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <strong>Date</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Provider Name</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Location</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Status</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {upcomingAppointnemtsList?.length > 0 ? (
                          upcomingAppointnemtsList?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.date)
                                  ? moment(row.date).format("YYYY-MM-DD")
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.provider)
                                  ? row.provider
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.location)
                                  ? row.location
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.status)
                                  ? row.status
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <Box minHeight={100} className={classes.alignBox}>
                                <Typography variant="body2">
                                  No upcoming appointments.
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item md={12} style={{ marginTop: "15px" }}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    History
                  </Typography>
                  <TableContainer
                    component={Paper}
                    style={{ marginTop: "15px" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <strong>Date</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Provider Name</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Billed Amount</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Visit Note</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>Billing Summary</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {visitHistoryList?.length > 0 ? (
                          visitHistoryList?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.date)
                                  ? moment(row.date).format("YYYY-MM-DD")
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.provider)
                                  ? row.provider
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                {!ifNullAndUndefined(row.billedAmount)
                                  ? "$" + row.billedAmount
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Preview Visit Note">
                                  <Button
                                    size="small"
                                    className={classes.actionBtn}
                                    onClick={() =>
                                      handleDocPreview(
                                        row.visitNoteId,
                                        "VisitNote"
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Download Visit Note">
                                  <Button
                                    size="small"
                                    className={classes.actionBtn}
                                    onClick={() =>
                                      handleDocDownload(
                                        row.visitNoteId,
                                        "VisitNote"
                                      )
                                    }
                                  >
                                    <GetAppIcon />
                                  </Button>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Preview Billing Summary">
                                  <Button
                                    size="small"
                                    className={classes.actionBtn}
                                    onClick={() =>
                                      handleDocPreview(
                                        row.billingSummaryId,
                                        "BillingSummary"
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Download Billing Summary">
                                  <Button
                                    size="small"
                                    className={classes.actionBtn}
                                    onClick={() =>
                                      handleDocDownload(
                                        row.billingSummaryId,
                                        "BillingSummary"
                                      )
                                    }
                                  >
                                    <GetAppIcon />
                                  </Button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <Box minHeight={100} className={classes.alignBox}>
                                <Typography variant="body2">
                                  No records to display
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    patientName: PropTypes.string,
    caseType: PropTypes.string,
    claimNumberChartId: PropTypes.string,
    injuryDate: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  openRow: PropTypes.string,
  setOpenRow: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleClickAccordion: PropTypes.func,
  accordionData: PropTypes.object,
  upcomingAppointnemtsList: PropTypes.array,
  visitHistoryList: PropTypes.array,
  policyLimit: PropTypes.number,
  totalCharges: PropTypes.number,
  handleDocPreview: PropTypes.func,
  handleDocDownload: PropTypes.func,
  classes: PropTypes.object,
};

export default function PersonalInjuryEmployeeList({
  employeeList,
  handleClickOpen,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openRow, setOpenRow] = useState(null);
  const [accordionData, setAccordionData] = useState();
  const [upcomingAppointnemtsList, setUpcomingAppointmentsList] = useState([]);
  const [visitHistoryList, setVisitHistoryList] = useState([]);
  const [policyLimit, setPolicyLimit] = useState(null);
  const [totalCharges, setTotalCharges] = useState(null);
  const [isPdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfContent, setPdfContent] = useState("");
  const rows = employeeList.map((employee) => {
    return createData(employee);
  });

  function createData(employeeData) {
    const patientName = employeeData.employee.message;
    const caseType = getCaseType(employeeData.claimType);
    const claimNumberChartId = getClaimNumber(employeeData);
    const injuryDate = getInjuryDate(employeeData.injuryDate);

    return {
      patientName,
      caseType,
      claimNumberChartId,
      injuryDate,
      employee: employeeData.employee,
    };
  }

  function getCaseType(claimType) {
    switch (claimType) {
      case "PERSONAL_INJURY":
        return "PI";
      case "WORKERS_COMP":
        return "WCP";
    }
  }

  function getClaimNumber(employeeData) {
    switch (employeeData.claimType) {
      case "PERSONAL_INJURY":
        return employeeData.chartNumber;
      case "WORKERS_COMP":
        return employeeData.claimNumber;
    }
  }

  function getInjuryDate(injuryDate) {
    return injuryDate !== null ? moment(injuryDate).format("YYYY-MM-DD") : "";
  }

  function upcomingAppointmentListData(date, provider, location, status) {
    return { date, provider, location, status };
  }

  function visitHistoryListData(
    date,
    provider,
    billedAmount,
    visitNoteId,
    billingSummaryId
  ) {
    return { date, provider, billedAmount, visitNoteId, billingSummaryId };
  }

  const handleClickAccordion = async (chartNumber) => {
    await patientDemographicsService
      .getPatientById(chartNumber)
      .then((response) => {
        setAccordionData(response.data);
        const upcomingAppointmentData =
          response.data?.upcomingAppointments?.map((appointment) =>
            upcomingAppointmentListData(
              appointment.date,
              appointment.provider,
              appointment.location,
              appointment.status
            )
          ) || [];
        setUpcomingAppointmentsList(upcomingAppointmentData);

        const visitHistoryData =
          response.data?.visitNotes?.map((visitNote) =>
            visitHistoryListData(
              visitNote.date,
              visitNote.provider,
              visitNote.paidBalance + visitNote.openBalance,
              visitNote.id,
              visitNote.billingSummary.id
            )
          ) || [];
        setVisitHistoryList(visitHistoryData);
        setPolicyLimit(response.data?.policyLimit);

        const totalCharges = response.data?.visitNotes?.reduce(
          (total, visitNote) => {
            const billedAmount =
              (visitNote.paidBalance || 0) + (visitNote.openBalance || 0);
            return total + billedAmount;
          },
          0
        );
        setTotalCharges(totalCharges);
        setOpenRow((prevOpenRow) =>
          prevOpenRow === chartNumber ? null : chartNumber
        );
      });
  };

  const handleDocPreview = async (id, fileType) => {
    try {
      const res = await patientDemographicsService.getPatientDocDownload(
        id,
        fileType
      );
      if (res.status === 200) {
        if (res.data.file !== null) {
          const content = res.data.file.fileData;
          setPdfContent(content);
          setPdfModalOpen(true);
        } else {
          alert(
            "It seems like the file you are looking for does not exist. Please try another."
          );
        }
      }
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
    }
  };

  const handleClosePdfModal = () => {
    setPdfModalOpen(false);
  };

  const handleDocDownload = async (id, fileType) => {
    await patientDemographicsService
      .getPatientDocDownload(id, fileType)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.file !== null) {
            const content = res.data.file.fileData;
            const name = res.data.name;
            handleDocumentDownload(content, name);
          } else {
            alert(
              "It seems like the file you are looking for does not exist. Please try another."
            );
          }
        }
      });
  };

  const handleDocumentDownload = (content, name) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf;base64,${content}`;
    downloadLink.download = name;
    downloadLink.click();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter(
    (row) =>
      row.patientName.toLowerCase().includes(search.toLowerCase()) ||
      row.caseType.toLowerCase().toString().includes(search.toLowerCase()) ||
      row.claimNumberChartId
        .toLowerCase()
        .toString()
        .includes(search.toLowerCase()) ||
      row.injuryDate.toString().includes(search)
  );
  const classes = useStyles();
  return (
    <div>
      <Divider />
      <TextField
        margin="normal"
        placeholder="Search"
        className={classes.inputSearch}
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton size="small">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left" width="25%">
                <b>Patient</b>
              </TableCell>
              <TableCell align="center" width="20%">
                <b>Case Type</b>
              </TableCell>
              <TableCell align="center" width="25%">
                <b>Claim Number/Chart Id</b>
              </TableCell>
              <TableCell align="center" width="25%">
                <b>Injury Date</b>
              </TableCell>
              <TableCell width="5%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeList.length > 0 ? (
              filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={index}
                    index={index}
                    row={row}
                    isOpen={row.claimNumberChartId === openRow}
                    openRow={openRow}
                    setOpenRow={setOpenRow}
                    handleClickOpen={handleClickOpen}
                    handleClickAccordion={handleClickAccordion}
                    policyLimit={policyLimit}
                    accordionData={accordionData}
                    totalCharges={totalCharges}
                    upcomingAppointnemtsList={upcomingAppointnemtsList}
                    visitHistoryList={visitHistoryList}
                    handleDocPreview={handleDocPreview}
                    handleDocDownload={handleDocDownload}
                    classes={classes}
                  />
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box
                    minHeight={250}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">
                      No records to display
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      {isPdfModalOpen && (
        <PdfViewer pdfContent={pdfContent} onClose={handleClosePdfModal} />
      )}
    </div>
  );
}
