import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import HistoryIcon from '@material-ui/icons/History';
import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';

import {
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Button,
} from '@material-ui/core';
// import { green } from '@material-ui/core/colors';

import ClaimsHistory from '../claims-history.component';
import employeeServices from '../../../../services/employee/employee.services';
const EDEClaims = ({ formik, currentUser, employeeDetails, ...otherProps }) => {
  const [openClaimsHistory, setOpenClaimsHistory] = useState(false);
  const [claimsHistoryList, setClaimsHistoryList] = useState([]);
  const handleClaimsHistoryClose = () => {
    setOpenClaimsHistory(false);
  };
  const handleHistoryClaims = (value) => {
    employeeServices
      .getClaimsHistoryList(
        employeeDetails.employee.id,
        employeeDetails.claimNumber
      )
      .then((response) => {
        if (response && response.data) {
          setClaimsHistoryList(response.data);
        }
      })
      .catch();
    setOpenClaimsHistory(true);
  };
  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box}
      //  bgcolor={green[50]}
      bgcolor="#fffde7"
       >
        <CardHeader
          title="Claims"
          action={
            <>
              <Button
                startIcon={<HistoryIcon />}
                onClick={() => handleHistoryClaims()}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              >
                History
              </Button>
            </>
          }
          color="text.secondary"
          p={1}
          component={Box}
        />

        <Grid container spacing={1}>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Claim Number"
              name={`claimsDetail[0].claimNumber`}
              value={
                ifNullAndUndefined(formik.values.claimsDetail[0].claimNumber)
                  ? ""
                  : formik.values.claimsDetail[0].claimNumber
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER, ROLE.CLAIMEXAMINER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Injury Date"
              name={`claimsDetail[0].injuryDate`}
              value={moment(
                ifNullAndUndefined(formik.values.claimsDetail[0].injuryDate)
                  ? ""
                  : formik.values.claimsDetail[0].injuryDate
              ).format("YYYY-MM-DD")}
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER, ROLE.CLAIMEXAMINER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Accepted Body Parts"
              name={`claimsDetail[0].acceptedBodyParts`}
              value={
                ifNullAndUndefined(
                  formik.values.claimsDetail[0].acceptedBodyParts
                )
                  ? ""
                  : formik.values.claimsDetail[0].acceptedBodyParts
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER, ROLE.CLAIMEXAMINER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Disputed Body Parts"
              name={`claimsDetail[0].disputedBodyParts`}
              value={
                ifNullAndUndefined(
                  formik.values.claimsDetail[0].disputedBodyParts
                )
                  ? ""
                  : formik.values.claimsDetail[0].disputedBodyParts
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER, ROLE.CLAIMEXAMINER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={6} xs={6}>
            <TextField
              label="P&S/Anticipated Date"
              name={`claimsDetail[0].psAnticipatedDate`}
              value={moment(
                ifNullAndUndefined(
                  formik.values.claimsDetail[0].psAnticipatedDate
                )
                  ? ""
                  : formik.values.claimsDetail[0].psAnticipatedDate
              ).format("YYYY-MM-DD")}
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              disabled={!ifRole([ROLE.PROVIDER], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>

          {!ifRole([ROLE.EMPLOYEE]) && (
            <Grid item sm={6} xs={6}>
              <TextField
                label="Reason"
                name={`claimsDetail[0].reason`}
                value={
                  ifNullAndUndefined(formik.values.claimsDetail[0].reason)
                    ? ""
                    : formik.values.claimsDetail[0].reason
                }
                fullWidth
                variant="outlined"
                size="small"
                disabled={
                  !ifRole(
                    [ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER, ROLE.CLAIMEXAMINER],
                    currentUser.role
                  )
                }
                onChange={formik.handleChange}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <ClaimsHistory
        claimsHistoryList={claimsHistoryList}
        openClaimsHistory={openClaimsHistory}
        handleClaimsHistoryClose={handleClaimsHistoryClose}
        handleHistoryClaims={handleHistoryClaims}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEClaims);
