import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import {
  Dialog,
  // DialogTitle,
  DialogContent,

} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Animation } from '@devexpress/dx-react-chart';
import DialogTitleWithClose from "../../common/dialog-title-close.component";
// import { confidence as data } from '../../../demo-data/data-vizualization';

const format = () => tick => tick;
const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});
const legendLabelStyles = theme => ({
  label: {
    paddingTop: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
});
const legendItemStyles = () => ({
  item: {
    flexDirection: 'column',
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);
const demoStyles = () => ({
  chart: {
    paddingRight: '20px',
  },
  title: {
    whiteSpace: 'pre',
  },
});

const ValueLabel = (props) => {
  const { text } = props;
  return (
    <ValueAxis.Label
      {...props}
      text={`${text}%`}
    />
  );
};

const titleStyles = {
  title: {
    whiteSpace: 'pre',
  },
};
const TitleText = withStyles(titleStyles)(({ classes, ...props }) => (
  <Title.Text {...props} className={classes.title} />
));



class LineGraph extends React.PureComponent {
  constructor(props) {
    super(props);

   // this.state = {
   //   data,
   // };
  }

  render() {
   // const { data: chartData } = this.state;
   const chartData = this.props.workStatusChart;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          open={this.props.openLineGraph}
          onClose={this.props.handleLineGraphClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitleWithClose
            onClose={() => this.props.handleLineGraphClose()}
          ></DialogTitleWithClose>
          <DialogContent>
            <Paper>
              <Chart data={chartData} className={classes.chart}>
                <ArgumentAxis tickFormat={format} />
                <ValueAxis max={50} labelComponent={ValueLabel} />

                <LineSeries
                  name="Limited Lifting"
                  valueField="limitedLifting"
                  argumentField="date"
                />
                <LineSeries
                  name="Limited Carrying"
                  valueField="limitedCarrying"
                  argumentField="date"
                />
                <LineSeries
                  name="Limited Pulling"
                  valueField="limitedPulling"
                  argumentField="date"
                />
                <LineSeries
                  name="Limited Pushing"
                  valueField="limitedPushing"
                  argumentField="date"
                />
                <Legend
                  position="bottom"
                  rootComponent={Root}
                  itemComponent={Item}
                  labelComponent={Label}
                />
                <Title text={`Work Status Chart`} textComponent={TitleText} />
                <Animation />
              </Chart>
            </Paper>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(demoStyles, { name: 'LineGraph' })(LineGraph);
