import { WEB_URL } from './settings';

// Index

// form validation messages
// Date time | functional messages
// server | HTTP messages
// front-end URLs
// misc

export const PATTERN_EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const MESSAGE_SUCCESS = 'SUCCESS';

// form validation messages
export const REQUIRED_FIRST_NAME = 'First name is required';
export const REQUIRED_LAST_NAME = 'Last name is required';
export const REQUIRED_DOB = 'Date of birth is required';
export const REQUIRED_ROLE = 'Role is required';
export const REQUIRED_EMAIL = 'Email is required';
export const PROVIDE_VALID_EMAIL =
  "Provide a valid email. For example 'sometext@domain.ext'";
export const REQUIRED_MOBILE = 'Mobile is required';
export const PROVIDE_VALID_MOBILE = 'Provide a valid mobile';
export const REQUIRED_PASSWORD = 'Password is required';
export const REQUIRED_CONFIRM_PASSWORD = 'Confirm password is required';
export const PASSWORD_MISMATCH = 'Passwords must match';
export const REQUIRED_LOCATION = 'Location is required';
export const REQUIRED_PHYSICIAN = 'Physician is required';

// Date time | functional messages
export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'hh:mm a';

// server | HTTP messages
export const ERROR_REQUEST_NOT_COMPLETED =
  'Some error occured during communication with the server. Please try again later. Or reach to administrator of the application.';
export const USER_EXISTS = 'This user is already registered';

// user messages
export const USER_NOT_REGISTERED =
  'User not registered due to some error at server.';
export const USER_REGISTER_SUCCESSFUL = 'User registered successfully';
export const INVALID_CREDENTIAL = 'Invalid credentials';
export const PASSWORD_SET_SUCCESSFUL = 'Password set successfully';

// front-end URLs
export const FE_WEBSITE_PAGE_URL = `${WEB_URL}/welcome`;
export const FE_LOGIN_PAGE_URL = `${WEB_URL}/login`;
export const FE_ADMIN_PAGE_URL = `${WEB_URL}/admin`;
export const FE_EMPLOYER_PAGE_URL = `${WEB_URL}/employer`;
export const FE_PROVIDER_PAGE_URL = `${WEB_URL}/provider`;
export const FE_CLAIM_EXAMINER_PAGE_URL = `${WEB_URL}/claim-examiner`;
export const FE_ATTORNEY_PAGE_URL = `${WEB_URL}/attorney`;
export const FE_NURSE_CASE_MANAGER_PAGE_URL = `${WEB_URL}/nurse-case-manager`;
export const FE_SETPASSWORD_URL = `${WEB_URL}/set-password/:id/:email`;
export const FE_STAFF_PAGE_URL = `${WEB_URL}/staff`;
export const FE_EMPLOYEE_PAGE_URL = `${WEB_URL}/employee`;
export const FE_URO_PAGE_URL = `${WEB_URL}/uro`;
export const FE_TELEHEALTHMEETING_URL = `${WEB_URL}/telehealth`;

// misc
export const REQUIRED_RATING = 'Rating is required';
export const REVIEW_SUCCESSFUL = 'Thank you !! Data submitted successfully';
export const MAIL_RESENT_SUCCESSFUL = 'Mail resent successfully';
