import React from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Typography, Box, Button } from '@material-ui/core';
import commonServices from '../../../services/common/common.services';
import {
  FE_LOGIN_PAGE_URL,
  PASSWORD_SET_SUCCESSFUL,
  REQUIRED_CONFIRM_PASSWORD,
  REQUIRED_PASSWORD,
  PASSWORD_MISMATCH,
} from '../../../utils/strings';

const setPassword = (props) => {
  const { id, email } = props.match.params;

  return (
    <Box p={1}>
      <Typography variant="h6" gutterBottom>
        Set Password
      </Typography>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string().required(REQUIRED_PASSWORD),
          confirmPassword: Yup.string()
            .required(REQUIRED_CONFIRM_PASSWORD)
            .oneOf([Yup.ref('password'), null], PASSWORD_MISMATCH),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const payload = {
            encodeduserid: id,
            password: values.password,
            userEmail: email,
          };

          commonServices.registerUser(payload).then((response) => {
            console.log(response);
            alert(PASSWORD_SET_SUCCESSFUL);
            props.history.push(FE_LOGIN_PAGE_URL);
          });
          setSubmitting(false);
        }}
      >
        <Form>
          <Box mb={1}>
            <Field
              component={TextField}
              name="password"
              label="New Password"
              type="password"
              autoComplete="true"
              autoFocus={true}
              variant="filled"
            />
          </Box>
          <Box mb={1}>
            <Field
              component={TextField}
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              autoComplete="true"
              variant="filled"
            />
          </Box>
          <Button color="primary" type="submit" variant="contained">
            Submit
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default setPassword;
