import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import axios from "axios";

import {
  AppBar,
  Toolbar,
  withStyles,
  IconButton,
  Tooltip,
  Avatar,
  Button,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { STYLE_SETTINGS } from "../../../utils/settings";
// import Logo from '../../images/appLogo.png';
import appLogo from "../../../images/wcp-logo-40.png";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { setCurrentUser } from "../../../redux/user/user.actions";
import { setAxiosInterceptor } from "../../../redux/interceptor/interceptor.actions";
import { selectAxiosInterceptor } from "../../../redux/interceptor/interceptor.selectors";
import EmployeeRFA from "../../employee/employee-rfa.component";
const Header = ({ currentUser, ...otherProps }) => {
  const classes = otherProps.classes;
  const [openDetail, setOpenDetail] = useState(false);
  const handleopen = () => {
    setOpenDetail(true);
  };
  return (
    <div className={classes.appBarRoot}>
      <AppBar position="static" color="transparent">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Avatar alt="WORKER'S COMP PORTAL" src={appLogo} />
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {currentUser ? (
              <>
                {currentUser.role === "STAFF" && currentUser.role !== null ? (
                  <Tooltip title="Generate RFA Form">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        float: "right",
                        marginRight: "20px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                      onClick={handleopen}
                    >
                      Generate RFA
                    </Button>
                  </Tooltip>
                ) : null}
                <EmployeeRFA
                  openDetail={openDetail}
                  setOpenDetail={setOpenDetail}
                  handleopen={handleopen}
                />

                <Tooltip title="Logout">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      otherProps.dispatch(setCurrentUser(null));
                      otherProps.dispatch(
                        setAxiosInterceptor(
                          axios.interceptors.request.eject(
                            otherProps.AXIOS_INTERCEPTOR
                          )
                        )
                      );
                    }}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              ""
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  AXIOS_INTERCEPTOR: selectAxiosInterceptor,
});

export default withRouter(
  connect(mapStateToProps)(withStyles(STYLE_SETTINGS)(Header))
);
