import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';
import SmsManageInfo from './sms-manage-info.component';
import {
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
// import { indigo } from '@material-ui/core/colors';
import moment from 'moment';

const EDEDemographics = ({ formik, currentUser, ...otherProps }) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const handleSnackBarClose = (event, reason) => {
    setSnackBarOpen(false);
  };
  const convertSsnToXX = (ssn) => {
    return `XXX-XX-${ssn.substr(ssn.length - 4, ssn.length - 1)}`;
  };
  const handleSmsClickOpen = (mobile) => {
    setMobileNumber(mobile);
    setOpen(true);
  };
  const handleSmsClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box}
      //  bgcolor={indigo[50]}
      bgcolor="#e1f5fe"
       >
        <CardHeader
          title="Demographics"
          color="text.secondary"
          p={1}
          component={Box}
        />
        <Grid container spacing={1}>
          <Grid item sm={4}  xs={6}>
            <TextField
              label="First Name"
              name="employee.firstName"
              value={
                ifNullAndUndefined(formik.values.employee.firstName)
                  ? ""
                  : formik.values.employee.firstName
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole(
                  [ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER],
                  currentUser.role
                )
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Last Name"
              name="employee.lastName"
              value={
                ifNullAndUndefined(formik.values.employee.lastName)
                  ? ""
                  : formik.values.employee.lastName
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Date of Birth"
              name="employee.dob"
              value={moment(
                ifNullAndUndefined(formik.values.employee.dob)
                  ? ""
                  : formik.values.employee.dob
              ).format("YYYY-MM-DD")}
              type="date"
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Address"
              name="employee.street1"
              value={
                ifNullAndUndefined(formik.values.employee.street1)
                  ? ""
                  : formik.values.employee.street1
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <TextField
              label="City"
              name="employee.city"
              value={
                ifNullAndUndefined(formik.values.employee.city)
                  ? ""
                  : formik.values.employee.city
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <TextField
              label="State"
              name="employee.state"
              value={
                ifNullAndUndefined(formik.values.employee.state)
                  ? ""
                  : formik.values.employee.state
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <TextField
              label="Zip Code"
              name="employee.zipCode"
              value={
                ifNullAndUndefined(formik.values.employee.zipCode)
                  ? ""
                  : formik.values.employee.zipCode
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <TextField
              label="SSN #"
              name="employee.ssn"
              value={
                ifNullAndUndefined(formik.values.employee.ssn)
                  ? ""
                  : convertSsnToXX(formik.values.employee.ssn)
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Email"
              name="employee.emailId"
              value={
                ifNullAndUndefined(formik.values.employee.emailId)
                  ? ""
                  : formik.values.employee.emailId
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={true}
              // disabled={
              //   !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              // }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Cell"
              name="employee.phone"
              value={
                ifNullAndUndefined(formik.values.employee.phone)
                  ? ""
                  : formik.values.employee.phone
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Send SMS">
                    <InputAdornment position="end">
                      <label htmlFor="sms-send">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleSmsClickOpen(formik.values.employee.phone)
                          }
                          disabled={ifRole(
                            [ROLE.EMPLOYEE, ROLE.ADMIN],
                            currentUser.role
                          )}
                          edge="end"
                          component="span"
                        >
                          <SmsOutlinedIcon />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Work Phone"
              name="employee.workPhone"
              value={
                ifNullAndUndefined(formik.values.employee.workPhone)
                  ? ""
                  : formik.values.employee.workPhone
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Home Phone"
              name="employee.homePhone"
              value={
                ifNullAndUndefined(formik.values.employee.homePhone)
                  ? ""
                  : formik.values.employee.homePhone
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={
                !ifRole([ROLE.PROVIDER, ROLE.STAFF, ROLE.EMPLOYER], currentUser.role)
              }
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
          message={snackbarMessage}
        />
      </Paper>
      <SmsManageInfo
        open={open}
        handleSmsClose={handleSmsClose}
        mobileNumber={mobileNumber}
        setOpen={setOpen}
        setSnackBarOpen={setSnackBarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEDemographics);
