import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import userReducer from './user/user-reducer';
import loadingReducer from './loading/loading-reducer';
import interceptorReducer from './interceptor/interceptor-reducer.js';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  interceptor: interceptorReducer,
});

export default persistReducer(persistConfig, rootReducer);
