import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import StaffServices from '../../services/staff/staff.services';
import CommonServices from '../../services/common/common.services';

import StaffList from './staff-list.component';
import StaffManageInfo from './staff-manage-info.component';

const Staff = (props) => {
    const [staffInfo, setStaffInfo] = useState ({
      staffId : '',
      staffName : '',
      staffEmail : '',
      staffPhone : '',
      staffFax : '',
      staffAddress : '',
      staffTitle : '',
      staffUsers : [],
    });
    const [staffList, setStaffList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState(false);
    const [manageType, setManageType] = useState('ADD');

    const getStaffs = () => {
        StaffServices.getStaffList().then((response) => {
            setStaffList(response.data);
        }).catch();
    };

    const getUsers = () => {
      CommonServices.getUserListByRole('PROVIDER').then((response) => {
        setUserList(response.data);
      }).catch();
  };

    useEffect(() => {
        getStaffs();
    }, []);

    const handleClickOpen = (manageType, staffData) => {
      getUsers();

      if (manageType === 'EDIT') {
        const {
          id,
          name,
          email,
          phone,
          fax,
          title,
          userList,
          address,
        } = staffData;
  
        setStaffInfo({
          staffId : id,
          staffName : name,
          staffEmail : email,
          staffPhone : phone,
          staffFax : fax,
          staffAddress : address,
          staffTitle : title,
          staffUsers : userList != null && userList.length > 0 ? userList.map((item) => item.id) : [],
        });
      }
      
      setOpen(true);
      setManageType(manageType);
    };

    const handleClose = () => {
      setOpen(false);
      setStaffInfo({
        staffId : '',
        staffName : '',
        staffEmail : '',
        staffPhone : '',
        staffFax : '',
        staffAddress : '',
        staffTitle : '',
        staffUsers : [],
      });
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      setStaffInfo({
        ...staffInfo,
        [name]: value,
      });
    };

    const createStaff = () => {
      const {
        staffName,
        staffEmail,
        staffPhone,
        staffFax,
        staffAddress,
        staffTitle,
        staffUsers,
      } = staffInfo;
  
      const staffPayload = {
        name : staffName,
        email : staffEmail,
        phone : staffPhone,
        title : staffTitle,
        fax : staffFax,
        address : staffAddress,
        userIdVal : props.currentUser.id,
        userList : staffUsers.map((item) => ({
          id: item,
        })),
      };
  
      StaffServices.createStaff(staffPayload).then((response) => {
        window.alert(response.data.message);
        getStaffs();
        handleClose();
      });
    };

    const updateStaff = () => {
      const {
        staffId,
        staffName,
        staffEmail,
        staffPhone,
        staffFax,
        staffAddress,
        staffTitle,
        staffUsers,
      } = staffInfo;
  
      const staffPayload = {
        id : staffId,
        name : staffName,
        email : staffEmail,
        phone : staffPhone,
        title : staffTitle,
        fax : staffFax,
        address : staffAddress,
        userIdVal : props.currentUser.id,
        userList : staffUsers.map((item) => ({
          id: item,
        })),
      };
  
      StaffServices.updateStaff(staffPayload).then((response) => {
        window.alert(response.data.message);
        getStaffs();
        handleClose();
      });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
  
      if (manageType === 'ADD') {
        createStaff();
      } else {
        updateStaff();
      }
    };

    return (
      <div>
        <Card square>
          <CardHeader
            action={
              <Tooltip title="Add Staff">
                <IconButton
                  aria-label="Add Staff"
                  onClick={() => handleClickOpen("ADD", "")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
            title="HCP Staff"
          />

          <StaffList
            staffList={staffList}
            handleClickOpen={handleClickOpen}
            getStaffs={getStaffs}
            props={props}
          />
        </Card>
        <StaffManageInfo
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          staffInfo={staffInfo}
          userList={userList}
          manageType={manageType}
        />
      </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});
  
export default connect(mapStateToProps)(Staff);