import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { ifRole, ifNullAndUndefined } from "../../../../utils/common-utils";
import { ROLE } from "../../../../utils/settings";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Tooltip,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // Select,
  // Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  // FormLabel,
  IconButton
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
// import { green } from "@material-ui/core/colors";
import employeeServices from "../../../../services/employee/employee.services";
import MedicationAndOthersManageInfo from "./mediactions-others-manage-info.component";
const EDECurrentCare = ({ formik, currentUser, ...otherProps }) => {
  const [medicationsList, setMedicationsList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [medType, setMedType] = React.useState("Industrial");
  const [open, setOpen] = useState(false);
     const handleClickOpen = () => {
       setOpen(true);
     };
    const handleClose = () => {
      setOpen(false);
    };
  const handleMedType = (event, newMedType) => {
    setMedType(newMedType);
  };

  const getMedications = (medType) => {
    employeeServices
      .getMedicationsList(medType)
      .then((response) => {
        if (response && response.data) {
          setMedicationsList(response.data);
        }
      })
      .catch();
    employeeServices
      .getConditionlookupList(medType)
      .then((response) => {
        if (response && response.data) {
          setConditionList(response.data);
        }
      })
      .catch();
  };

  useEffect(() => {
    getMedications(medType);
  }, [medType]);

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={green[50]}
      bgcolor="#e9fafa"
      >
        <CardHeader
          title="Current Care"
          color="text.secondary"
          component={Box}
          action={
            <Tooltip title="Add Medications  / Conditions">
              <IconButton
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                aria-label="Add Medications  / Conditions"
                onClick={() => handleClickOpen()}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
        />

        <Grid container spacing={1}>
          <Grid item sm={12} xs={12} >
            <Box mb={2}>
              <RadioGroup
                row
                aria-label="medicationType"
                value={medType}
                size="small"
                onChange={handleMedType}
              >
                <FormControlLabel
                  value="Industrial"
                  control={<Radio />}
                  label="Industrial"
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.ADMIN],
                    currentUser.role
                  )}
                />
                <FormControlLabel
                  value="NonIndustrial"
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.ADMIN],
                    currentUser.role
                  )}
                  control={<Radio />}
                  label="Non Industrial"
                />
              </RadioGroup>
            </Box>

            <Autocomplete
              multiple
              disabled
              id="medications-auto-search"
              name={`currentCareDetails[0].medicationsLookUp`}
              value={
                ifNullAndUndefined(
                  formik.values.currentCareDetails[0].medicationsLookUp
                )
                  ? []
                  : formik.values.currentCareDetails[0].medicationsLookUp
              }
              getOptionSelected={(option, value) =>
                option.medicationType === value.name
              }
              getOptionLabel={(option) =>
                option.name +
                (option.medicationType === "Industrial" ? " (I)" : " (NI)")
              }
              options={medicationsList}
              filterSelectedOptions
              size="small"
              onChange={(event, value) => {
                formik.setFieldValue(
                  "currentCareDetails[0].medicationsLookUp",
                  value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Medications"
                  placeholder="Select Medications"
                />
              )}
              // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>
          <Grid item sm={12} xs={6}>
            <Autocomplete
              multiple
              disabled
              id="pastMedical-History-auto-search"
              name={`currentCareDetails[0].pastMedicalHistoryList`}
              value={
                ifNullAndUndefined(
                  formik.values.currentCareDetails[0].pastMedicalHistoryList
                )
                  ? []
                  : formik.values.currentCareDetails[0].pastMedicalHistoryList
              }
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) =>
                option.name +
                (option.conditionType === "Industrial" ? " (I)" : " (NI)")
              }
              options={conditionList}
              filterSelectedOptions
              size="small"
              onChange={(event, value) => {
                formik.setFieldValue(
                  "currentCareDetails[0].pastMedicalHistoryList",
                  value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Past Medical History"
                  placeholder="Select Past Medical History"
                />
              )}
              // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>

          <Grid item sm={4} xs={6}>
            <TextField
              label="Height"
              name={`currentCareDetails[0].height`}
              value={
                ifNullAndUndefined(formik.values.currentCareDetails[0].height)
                  ? ""
                  : formik.values.currentCareDetails[0].height
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="Weight"
              name={`currentCareDetails[0].weight`}
              value={
                ifNullAndUndefined(formik.values.currentCareDetails[0].weight)
                  ? ""
                  : formik.values.currentCareDetails[0].weight
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <TextField
              label="BMI"
              name={`currentCareDetails[0].bmi`}
              value={
                ifNullAndUndefined(formik.values.currentCareDetails[0].bmi)
                  ? ""
                  : formik.values.currentCareDetails[0].bmi
              }
              fullWidth
              size="small"
              variant="outlined"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={12} xs={6}>
            <Autocomplete
              multiple
              disabled
              id="fHistory-auto-search"
              name={`currentCareDetails[0].familyHistoryList`}
              value={
                ifNullAndUndefined(
                  formik.values.currentCareDetails[0].familyHistoryList
                )
                  ? []
                  : formik.values.currentCareDetails[0].familyHistoryList
              }
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) =>
                option.name +
                (option.conditionType === "Industrial" ? " (I)" : " (NI)")
              }
              options={conditionList}
              filterSelectedOptions
              size="small"
              onChange={(event, value) => {
                formik.setFieldValue(
                  "currentCareDetails[0].familyHistoryList",
                  value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Family History"
                  placeholder="Select Family History"
                />
              )}
              // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>
          <Grid item sm={12} xs={6}>
            <Autocomplete
              multiple
              disabled
              id="socialHistory-auto-search"
              name={`currentCareDetails[0].socialHistoryList`}
              value={
                ifNullAndUndefined(
                  formik.values.currentCareDetails[0].socialHistoryList
                )
                  ? []
                  : formik.values.currentCareDetails[0].socialHistoryList
              }
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) =>
                option.name +
                (option.conditionType === "Industrial" ? " (I)" : " (NI)")
              }
              options={conditionList}
              filterSelectedOptions
              size="small"
              onChange={(event, value) => {
                formik.setFieldValue(
                  "currentCareDetails[0].socialHistoryList",
                  value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Social History"
                  placeholder="Select Social History"
                />
              )}
              // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Autocomplete
              multiple
              disabled
              id="Comorbidities-auto-search"
              name={`currentCareDetails[0].comorbiditiesList`}
              value={
                ifNullAndUndefined(
                  formik.values.currentCareDetails[0].comorbiditiesList
                )
                  ? []
                  : formik.values.currentCareDetails[0].comorbiditiesList
              }
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) =>
                option.name +
                (option.conditionType === "Industrial" ? " (I)" : " (NI)")
              }
              options={conditionList}
              filterSelectedOptions
              size="small"
              onChange={(event, value) => {
                formik.setFieldValue(
                  "currentCareDetails[0].comorbiditiesList",
                  value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Comorbidities"
                  placeholder="Select Comorbidities"
                />
              )}
              // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
            />
          </Grid>
        </Grid>
      </Paper>
      <MedicationAndOthersManageInfo
        open={open}
        handleClose={handleClose}
        getMedications={getMedications}
        medType={medType}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDECurrentCare);
