import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

const PdfViewer = ({ pdfContent, onClose }) => {
  const blob = base64toBlob(pdfContent, "application/pdf");

  let pdfUrl;
  if (blob.type === "application/pdf") {
    pdfUrl = URL.createObjectURL(blob);
  } else {
    alert(
      "PDF Preview Only: This feature supports PDF files for preview. Please upload a valid PDF document."
    );
  }

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "none",
          backgroundColor: "#323639",
          boxShadow: "none",
          overflow: "hidden",
          margin: "40px",
        },
      }}
    >
      <DialogTitle style={{ display: "none" }}></DialogTitle>
      <DialogContent
        style={{ padding: 0, overflowY: "hidden", height: "100vh" }}
      >
        <iframe
          title="PDF Viewer"
          src={pdfUrl}
          style={{ width: "99.5%", height: "100%" }}
        />
      </DialogContent>
      <DialogActions style={{ padding: "10px" }}>
        <Button onClick={onClose} style={{ color: "white" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PdfViewer;
