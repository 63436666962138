import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip , Grid, Divider, Menu, MenuItem, List, ListItem, ListItemText} from '@material-ui/core';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import MessageManageInfo from '../message/message-manage-info.component';
import MessageServices from '../../services/message/message.services';
import SendIcon from '@material-ui/icons/Send';
import MessageList from '../message/message-list.component';
const Message = (props) => {
  const [messageList, setMessageList] = useState([]);
  const [showFlag, setShowFlag] = useState(false);
  const [open, setOpen] = useState(false);
 const [viewVal, setViewVal] = useState('RECEIVED');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleMessagesClose = () => {
    setOpen(false);
  };
    const settingRuFlag = (tid) => {
      MessageServices.readUnread(tid)
        .then((response) => {
          getMessages(viewVal);
        })
        .catch();

    };

  const getMessages = (value) => {
    if(value==="RECEIVED"){
      setShowFlag(false);
   MessageServices.getMessagesByUserId(props.currentUser.id)
      .then((response) => {
        setMessageList(response.data);
      })
      .catch();

    }else{
      setShowFlag(true);
    MessageServices.getMessagesBySenderUserId(props.currentUser.id)
      .then((response) => {
        setMessageList(response.data);
      })
      .catch();

    }
 
  };
    const options = ['Received', 'Sent'];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (options[index] !== undefined) {
            if (options[index] === 'Received') {
                setViewVal('RECEIVED');
            } else if (options[index] === 'Sent') {
                setViewVal('SENT');
            }
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


  useEffect(() => {
    getMessages(viewVal);
  }, [viewVal]);
  return (
    <div>
      <Card square>
        <CardHeader
          action={
            <div>
              <Grid container alignItems="center">
                <List component="nav" aria-label="Device settings">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="View Messages"
                    onClick={handleClickListItem}
                  >
                    <ListItemText
                      primary="View Messages"
                      secondary={options[selectedIndex]}
                    />
                  </ListItem>
                </List>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
                <Divider orientation="vertical" />
                <Tooltip title="Compose Message">
                  <IconButton
                    aria-label="Compose Message"
                    onClick={() => handleClickOpen()}
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </div>
          }
          title="Messages"
        />
        <MessageList
          messageList={messageList}
          showFlag={showFlag}
          settingRuFlag={settingRuFlag}
          currentUser={props.currentUser}
        />
      </Card>
      <MessageManageInfo
        open={open}
        handleMessagesClose={handleMessagesClose}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Message);
