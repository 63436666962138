import axios from "axios";
import {
  EMPLOYEE_TELEHEALTH_API_URL,
  EMPLOYEE_API_URL,
  MESSAGE_API_URL,
} from "../../utils/settings";

class EmployeeServices {
  getTelehealthPublisher(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_TELEHEALTH_API_URL}/publisher`,
      data,
    });
  }

  getTelehealthSubsciber(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_TELEHEALTH_API_URL}/subscriber`,
      data,
    });
  }

  getEmployeeList() {
    return axios.get(`${EMPLOYEE_API_URL}/employees`);
  }

  getEmployeeListByUser(userid,claimType) {
    const params = {
      claimType:claimType,
    };
    return axios.get(`${EMPLOYEE_API_URL}/${userid}/employees`,{params});
  }

  getEmployee(userid) {
    return axios.get(`${EMPLOYEE_API_URL}/${userid}/fetchemployee`);
  }

  getEmployeeReport(employeeid) {
    return axios.get(`${EMPLOYEE_API_URL}/${employeeid}/employeereport`);
  }

  searchEmployee(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/employeesearch`,
      data,
    });
  }

  addEmployee(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/addemployee`,
      data,
    });
  }

  editEmployee(data) {
    return axios({
      method: "PUT",
      url: `${EMPLOYEE_API_URL}/editemployee`,
      data,
    });
  }

  addReport(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/employeereport`,
      data,
    });
  }

  workstatusupdate(data, empid) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/workstatusupdate/${empid}`,
      data,
    });
  }

  rfafileupload(data, empid) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/rfafileupload/${empid}`,
      data,
    });
  }

  pr2fileupload(data, empid) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/pr2fileupload/${empid}`,
      data,
    });
  }

  rtwuploadpolicy(data, empid) {
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/rtwuploadpolicy/${empid}`,
      data,
    });
  }




  getWorkStatusHistoryList(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/workstatushistory`
    );
  }

  getTreatmentHistoryList(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/treatmenthistory`
    );
  }

  changeStatusFlag(usrid, empid) {
    return axios.get(`${EMPLOYEE_API_URL}/${usrid}/${empid}/updateworkstatus`);
  }

  getVisitsHistoryList(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/visitshistory`
    );
  }
  getClaimsHistoryList(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/claimshistory`
    );
  }
  getPriorCareHistoryList(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/priorinjurieshistory`
    );
  }
  getChartData(empid) {
    return axios.get(`${EMPLOYEE_API_URL}/${empid}/workstatuschart`);
  }

  getDiagCodeList() {
    return axios.get(`${EMPLOYEE_API_URL}/codeslookup`);
  }

  getMedicationsList(medType) {
    return axios.get(`${EMPLOYEE_API_URL}/${medType}/medicationlookup`);
  }

  SendSms(mobile, msg) {
    var data = {
      message: msg,
      mobileNumber: mobile,
    };
    return axios({
      method: "POST",
      url: `${MESSAGE_API_URL}/sendsms`,
      data,
    });
  }

  getDocumentHistory(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/documenthistory`
    );
  }

  getStakeholders(empId) {
    return axios.get(`${EMPLOYEE_API_URL}/${empId}/employeestakeholders`);
  }

  getStakeholdersForCalendar(userId) {
    return axios.get(`${EMPLOYEE_API_URL}/${userId}/stakeholdersforcalender`);
  }

  updateEmployeeStackholders(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/updateempstackholders`,
      data,
    });
  }

  getConditionlookupList(conditiontype) {
    return axios.get(`${EMPLOYEE_API_URL}/${conditiontype}/conditionlookup`);
  }

  addConditionlookup(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/conditionlookup`,
      data,
    });
  }

  updateConditionlookup(data, id) {
    return axios({
      method: "PUT",
      url: `${EMPLOYEE_API_URL}/${id}/conditionlookup`,
      data,
    });
  }

  addMedicationlookup(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/medicationlookup`,
      data,
    });
  }

  updateMedicationlookup(data, id) {
    return axios({
      method: "PUT",
      url: `${EMPLOYEE_API_URL}/${id}/medicationlookup`,
      data,
    });
  }

  getEmployeeChartData(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/employeechartdata`,
      data,
    });
  }
  getDiagnosisHistoryList(empid, claimNumber) {
    return axios.get(
      `${EMPLOYEE_API_URL}/${claimNumber}/${empid}/diagnosisthistory`
    );
  }

  getVendersList(vendor) {
    return axios.get(`${EMPLOYEE_API_URL}/${vendor}/vendors`);
  }

  addVendorlookup(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/vendors`,
      data,
    });
  }

  removeHistory(histroyname, historyid) {
    return axios.delete(
      `${EMPLOYEE_API_URL}/${histroyname}/${historyid}/removehistory`
    );
  }

  removeDiagnosisHistory(id, message) {
    return axios.delete(
      `${EMPLOYEE_API_URL}/${id}/${message}/removedocumenthistory`
    );
  }

  removeStackholder(id) {
    return axios.delete(`${EMPLOYEE_API_URL}/${id}/stackholder`);
  }

  removeInsurance(id) {
    return axios.delete(`${EMPLOYEE_API_URL}/${id}/insurance`);
  }

  getMPNListBySearchStr(searchStr) {
    return axios.get(`${EMPLOYEE_API_URL}/${searchStr}/mpnlookup`);
  }

  addMPNlookup(data) {
    return axios({
      method: "POST",
      url: `${EMPLOYEE_API_URL}/mpnlookup`,
      data,
    });
  }
}
export default new EmployeeServices();
