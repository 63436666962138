import React, { useState, forwardRef } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import TaskManageInfo from './task-manage-info.component';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';

import MaterialTable from 'material-table';

function TaskList({ taskList, getTaskList, ...otherProps }) {
    const [taskDetailInfo, setTaskDetailInfo] = useState();
    const [openDetail, setOpenDetail] = useState(false);

    const handleTaskManageClickOpen = (taskData) => {
        setTaskDetailInfo(taskData);
        setOpenDetail(true);
    };

    const tableIcons = {
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        
    };
    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={[
                    { title: 'Name' , weight:'fontWeightBold', field: 'toUser.userName', render: (taskList) => taskList.toUser != null ? taskList.toUser.userName : '' },
                    { title: 'Role', field: 'toUser.role', render: (taskList) => taskList.toUser != null ? taskList.toUser.role : '' },
                    { title: 'Created At', field: 'createdAt', render: (taskList) => moment(taskList.createdAt).format('MM-DD-YYYY HH:mm') },
                    { title: 'Action Item', field: 'actionItem', render: (taskList) => taskList.actionItem},
                    { title: 'Due Date', field: 'dueDate', render: (taskList) => moment(taskList.dueDate).format('MM-DD-YYYY')},
                    { title: 'Priority', field: 'priority', render: (taskList) => taskList.priority},
                    { title: 'Status', field: 'status', render: (taskList) => taskList.status},
                ]}
                data={taskList}        
                options={{
                    search: true,
                    actionsColumnIndex: -1,
                    headerStyle: {
                          fontWeight: 'bold'
                    }
                      
                }}
                actions={[
                    {
                    icon: EditIcon,
                    tooltip: 'Edit Task',
                    onClick: (event, rowData) => handleTaskManageClickOpen(rowData)
                    }
                ]}
            />
            {openDetail && <TaskManageInfo
                taskManageOpen={openDetail}
                setTaskManageOpen={setOpenDetail}
                getTaskList={getTaskList}
                taskDetailInfo={taskDetailInfo}
            />}
        </div>
    );
}

export default TaskList;