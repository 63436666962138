import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../../redux/user/user.selectors';

import {
  TextField,
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  // Grid,
  // Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';

import ProviderManageInfo from '../../../../provider/provider-manage-info.component';
import ProviderServices from '../../../../../services/provider/provider.services';

const EdePrimaryTpSh = ({ formik, currentUser, ...otherProps }) => {
  const [providerSearchVal, setProviderSearchVal] = useState([]);
  const [providerInfo, setProviderInfo] = useState({
    asActive: 1,
    asProfilePicId: null,
    createdBy: currentUser.id,
    asUserId: null,
    asFirstName: '',
    asLastName: '',
    asProTitle: '',
    asProSpeciality: '',
    asProType: '',
    asPreferredName: '',
    asGender: 'M',
    asNpin: '',
    asStreet1: '',
    asStreet2: '',
    asCity: '',
    asState: '',
    asCountry: '',
    asZipCode: '',
    asEmail: '',
    asPhone: '',
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setProviderInfo({
      asActive: 1,
      asProfilePicId: null,
      createdBy: currentUser.id,
      asUserId: null,
      asFirstName: '',
      asLastName: '',
      asProTitle: '',
      asProSpeciality: '',
      asProType: '',
      asPreferredName: '',
      asGender: 'M',
      asNpin: '',
      asStreet1: '',
      asStreet2: '',
      asCity: '',
      asState: '',
      asCountry: '',
      asZipCode: '',
      asEmail: '',
      asPhone: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProviderInfo({
      ...providerInfo,
      [name]: value,
    });
  };

  const createProvider = () => {
    const {
      asActive,
      asProfilePicId,
      createdBy,
      asFirstName,
      asLastName,
      asProTitle,
      asProSpeciality,
      asProType,
      asPreferredName,
      asGender,
      asNpin,
      asStreet1,
      asStreet2,
      asCity,
      asState,
      asCountry,
      asZipCode,
      asEmail,
      asPhone,
    } = providerInfo;

    const providerPayload = {
      active: asActive,
      city: asCity,
      country: asCountry,
      firstName: asFirstName,
      lastName: asLastName,
      providerTitle: asProTitle,
      specialty: asProSpeciality,
      providerType: asProType,
      preferredName: asPreferredName,
      phone: asPhone,
      email: asEmail,
      profilePicId: asProfilePicId,
      gender: asGender,
      npin: asNpin,
      state: asState,
      street1: asStreet1,
      street2: asStreet2,
      zipcode: asZipCode,
      userId: createdBy,
    };

    ProviderServices.createProvider(providerPayload).then((response) => {
      window.alert(response.data.message);
      getProviderList();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createProvider();
  };

  const getProviderList = () => {
    ProviderServices.getProviderList()
      .then((response) => {
        if (response && response.data) {
          setProviderSearchVal(response.data);
        }
      })
      .catch();
  };

  useEffect(() => {
    getProviderList();
  }, []);

  return (
    <>
     {/*  <Grid item md={3} component={Box} bgcolor={green[50]}>
        <Paper elevation={0} square p={0.5} component={Box} bgcolor={green[50]}> */}
          <CardHeader
            title="Primary TP"
            color="text.secondary"
            p={1}
            component={Box}
            action={
              <Tooltip title="Add Primary TP">
                <IconButton
                  // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                  aria-label="Add Primary TP"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <Autocomplete
            name="ptp"
            value={formik.values.ptp}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            options={providerSearchVal}
            onChange={(event, value) => {
              formik.setFieldValue('ptp', value);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Primary TP" />
            )}
            size="small"
          />
      {/*   </Paper>
      </Grid> */}
      <ProviderManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        providerInfo={providerInfo}
        manageType="ADD"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EdePrimaryTpSh);
