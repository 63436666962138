import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MessageServices from '../../services/message/message.services';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

function MessageManageInfo({
  currentUser,
  open,
  handleMessagesClose,
  ...otherProps
}) {
  const [messageInfo, setMessageInfo] = useState({
    miId: null,
    miReceiversUser: null,
    miSenderUser: currentUser.id,
    miMessage: '',
    mirole: '',
  });
  const { miReceiversUser, miMessage, mirole } = messageInfo;
  const [userListForSearch, setUserListForSearch] = useState([]);
  const getUserListForSearch = (role) => {
    MessageServices.getUserByRole(role)
      .then((response) => {
        setUserListForSearch(response.data);
      })
      .catch();
  };

  const handleRoleChange = (event) => {
    const { name, value } = event.target;
    setMessageInfo({
      ...messageInfo,
      miReceiversUser: null,
      [name]: value,
    });
    getUserListForSearch(event.target.value);
  };

  const handleUserListAutoCompleteChange = (event, value) => {
    setMessageInfo({
      ...messageInfo,
      miReceiversUser: value,
    });
  };

  const handleMessageChange = (event) => {
    const { name, value } = event.target;
    setMessageInfo({
      ...messageInfo,
      [name]: value,
    });
  };

  const handleMessageSubmit = (event) => {
    event.preventDefault();
    const { miReceiversUser, miSenderUser, miMessage } = messageInfo;

    const messagePayload = {
      senderUser: { id: miSenderUser },
      receiversUser: { id: miReceiversUser.id },
      message: miMessage,
    };
    MessageServices.sendMessages(messagePayload).then((res) => {
      alert(res.data.response);
      setMessageInfo({
        ...messageInfo,
        miReceiversUser: null,
        miMessage: '',
        mirole: '',
      });
    });
  };
    return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleMessagesClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Compose Message</DialogTitle>
        <DialogContent>
          <form id="send-messages" onSubmit={handleMessageSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="role-select-label">Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    name="mirole"
                    value={mirole}
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="PROVIDER">PROVIDER</MenuItem>
                    <MenuItem value="EMPLOYER">EMPLOYER</MenuItem>
                    <MenuItem value="EMPLOYEE">EMPLOYEE</MenuItem>
                    <MenuItem value="STAFF">STAFF</MenuItem>
                    <MenuItem value="ATTORNEY">ATTORNEY</MenuItem>
                    <MenuItem value="CLAIMEXAMINER">CLAIM EXAMINER</MenuItem>
                    <MenuItem value="NURSECASEMANAGER">
                      NURSE CASE MANAGER
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="reciver-auto-search"
                  name="miReceiversUser"
                  value={miReceiversUser}
                  getOptionSelected={(option, value) =>
                    option.userName === value.userName
                  }
                  getOptionLabel={(option) => option.userName}
                  options={userListForSearch}
                  onChange={handleUserListAutoCompleteChange}
                  renderInput={(params) => <TextField {...params} label="To" />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="miMessage"
                  label="Message (Max 255 characters)"
                  multiline
                  rowsMax={4}
                  fullWidth
                  value={miMessage}
                  onChange={handleMessageChange}
                />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleMessagesClose}>Close</Button>
              <Button type="submit" variant="contained" color="primary">
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(MessageManageInfo);
