import React, { useState } from "react";
import employeeServices from "../../../../services/employee/employee.services";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";

function SmsManageInfo({
  currentUser,
  open,
  handleSmsClose,
  mobileNumber,
  setOpen,
  setSnackBarOpen,
  setSnackbarMessage,
  ...otherProps
}) {
  const [messageInfo, setMessageInfo] = useState({
    smsMessage: "",
  });
  const { smsMessage } = messageInfo;
  const handleSmsSent = () => {
    if (messageInfo.smsMessage === "") {
      alert("Please type Messsage.");
      return;
    }
    employeeServices
      .SendSms(mobileNumber, messageInfo.smsMessage)
      .then((res) => {
        if (res) {
          setOpen(false);
          setSnackbarMessage(res.data.response);
          setSnackBarOpen(true);
              setMessageInfo({
                ...messageInfo,
                smsMessage: "",
              });
        }
      })
      .catch();
  };
  const handleSmsChange = (event) => {
    const { name, value } = event.target;
    setMessageInfo({
      ...messageInfo,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleSmsClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Compose Message</DialogTitle>
        <DialogContent>
          <form id="send-sms">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="smsMessage"
                  label="Message"
                  multiline
                  rowsMax={4}
                  fullWidth
                  value={smsMessage}
                  onChange={handleSmsChange}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleSmsClose}>Close</Button>
              <Button
                onClick={() => {
                  handleSmsSent();
                }}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(SmsManageInfo);
