import React, {useState} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';

import CommonService from "../../../services/common/common.services";

function ChangePassword({
  openChangePassword,
  setOpenChangePassword,
  userId,
  ...otherProps
}) {

  const [changePasswordInfo, setChangePasswordInfo] = useState({
    password : '',
    confirmPassword : '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setChangePasswordInfo({
      ...changePasswordInfo,
      [name]: value,
    });
  };

  const handleClose = () => {
    setOpenChangePassword(false);
  };

  const handleSubmit = () => {
    if (changePasswordInfo.password === '' || changePasswordInfo.password === undefined) {
      alert("Password is required");
      return;
    }
    if (changePasswordInfo.confirmPassword === '' || changePasswordInfo.confirmPassword === undefined) {
      alert("Value of Confirm Password field is required");
      return;
    }
    if (changePasswordInfo.password !== changePasswordInfo.confirmPassword) {
      alert("Password values must match");
      return;
    }

    CommonService.changePassword({id : userId, password:changePasswordInfo.password}).then((response) => {
      if (response && response.data) {
        alert(response.data.message);
      }
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openChangePassword}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <TextField
                margin="dense"
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={changePasswordInfo.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={12}>
            <TextField
                margin="dense"
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                fullWidth
                value={changePasswordInfo.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ChangePassword;