import axios from "axios";

import { EMPLOYEE_API_URL, PATIENT_DEMOGRAPHICS } from "../../utils/settings";

class PatientDemographicsServices {
  getPatientList() {
    return axios.get(`${PATIENT_DEMOGRAPHICS}/all`);
  }

  editPatientInfo(data){
    return axios({
      method:"PUT",
      url:`${PATIENT_DEMOGRAPHICS}/update-form`,
      data
    })
  }

  getPatientById(chartId) {
    return axios.get(`${PATIENT_DEMOGRAPHICS}/${chartId}`);
  }
  getPatientDetails(data)
  {
    return axios({
      method:"POST",
      url:`${EMPLOYEE_API_URL}/employee-details`,
      data
    })
  }

  getDownloadReport(userId)
  {
    return axios.get(`${PATIENT_DEMOGRAPHICS}/generate-excel/${userId}`) 
  }
  
  getPatientDocDownload(fileTypeId,fileType) {
    const params={
      fileTypeId:fileTypeId,
      fileType:fileType
    }
    return axios.get(`${PATIENT_DEMOGRAPHICS}/patient-document`,{params});
  }
  getPatientDocuments(chartId){
    return axios.get(`${EMPLOYEE_API_URL}/patient-document/${chartId}`)
  }
}

export default new PatientDemographicsServices();
