import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';

function StaffManageInfo({
    open,
    handleClose,
    handleChange,
    handleSubmit,
    staffInfo,
    userList,
    manageType,
    ...otherProps
  }) {
    const {
        staffName,
        staffEmail,
        staffPhone,
        staffFax,
        staffAddress,
        staffTitle,
        staffUsers,
      } = staffInfo;

      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {manageType === "EDIT" ? "Manage HCP Staff" : "Create HCP Staff"}
            </DialogTitle>
            <DialogContent>
              <form id="staff_manage_form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="staffName"
                      label="Name"
                      type="text"
                      fullWidth
                      value={staffName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="staff-title-select-label">
                        Title
                      </InputLabel>
                      <Select
                        labelId="staff-title-select-label"
                        id="staff-title-select"
                        name="staffTitle"
                        value={staffTitle}
                        onChange={handleChange}
                      >
                        <MenuItem value="PROVIDERSTAFF">
                          Provider Staff
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="staffEmail"
                      label="Email"
                      type="text"
                      fullWidth
                      value={staffEmail}
                      onChange={handleChange}
                      disabled={manageType === "EDIT"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="staffPhone"
                      label="Phone"
                      type="text"
                      fullWidth
                      value={staffPhone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="staffFax"
                      label="Fax"
                      type="text"
                      fullWidth
                      value={staffFax}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl required fullWidth>
                      <InputLabel id="users-select-label">
                        Select Users
                      </InputLabel>
                      <Select
                        labelId="users-select-label"
                        id="users-select"
                        multiple
                        name="staffUsers"
                        value={staffUsers}
                        onChange={handleChange}
                        input={<Input />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {userList != null && userList.length > 0
                          ? userList.map((user) => (
                              <MenuItem value={user.id} key={user.id}>
                                {user.userName}
                              </MenuItem>
                            ))
                          : ""}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="staffAddress"
                      label="Address"
                      type="text"
                      fullWidth
                      value={staffAddress}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      );
  }

  export default StaffManageInfo;