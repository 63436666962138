import axios from 'axios';
import { BASE_PK, OAUTH_API_URL } from '../../utils/settings';

const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
const USER_ID_SESSION_ATTRIBUTE_NAME = 'authenticatedUserId';
const USER_EMAIL_SESSION_ATTRIBUTE_NAME = 'authenticatedUserEmail';
const USER_MOBILE_SESSION_ATTRIBUTE_NAME = 'authenticatedUserMobile';
let AXIOS_INTERCEPTOR = '';

class AuthenticationServices {
  setUpAxiosIntercepters() {
    AXIOS_INTERCEPTOR = axios.interceptors.request.use((config) => {
      return config;
    });
  }

  executeOAuthAuthenicationService(username, password) {
    return axios({
      method: 'post',
      url: `${OAUTH_API_URL}?grant_type=password&username=${username}&password=${password}`,
      auth: {
        username: `${BASE_PK}-client`,
        password: 'secret',
      },
    });
  }

  registerSuccessfulLogin(userId, email, mobile, userName) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, userName);
    sessionStorage.setItem(USER_ID_SESSION_ATTRIBUTE_NAME, userId);
    sessionStorage.setItem(USER_EMAIL_SESSION_ATTRIBUTE_NAME, email);
    sessionStorage.setItem(USER_MOBILE_SESSION_ATTRIBUTE_NAME, mobile);
  }

  logout() {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    sessionStorage.removeItem(USER_ID_SESSION_ATTRIBUTE_NAME);
    axios.interceptors.request.eject(AXIOS_INTERCEPTOR);
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);

    if (user === null) return false;
    return true;
  }

  getLoggedInUsername() {
    let userName = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);

    if (userName === null) return '';
    return userName;
  }

  getLoggedInUserid() {
    let userId = sessionStorage.getItem(USER_ID_SESSION_ATTRIBUTE_NAME);

    if (userId === null) return '';
    return userId;
  }
}

export default new AuthenticationServices();
