import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';

import wcpLogo from '../../images/wcp-logo.png';
import wcpImage from '../../images/website-img-2-min.jpg';

import { FE_LOGIN_PAGE_URL } from '../../utils/strings';

const WCPWebsite = () => {
  return (
    <>
      <Box width={1}>
        <Box p={2} textAlign="center" width="40%" style={{ margin: '0 auto' }}>
          <Box>
            <img src={wcpLogo} alt="logo.jpeg" />
          </Box>
          <Box><Typography variant='subtitle1'> Powered By Boomerang Healthcare</Typography></Box>
          <Box m={1}>
            <Button
              to={FE_LOGIN_PAGE_URL}
              component={Link}
              variant="outlined"
              color="primary"
            >
              Login to the Portal Here
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          height: '400px',
          overflow: 'hidden',
          border: '10px solid aliceblue',
          boxShadow: '0px 0px 3px 0px #607d8b',
          width: '70%',
          margin: '0 auto',
          marginBottom:"20px"
        }}
      >
        <img
          src={wcpImage}
          style={{ width: '100%', objectFit: 'cover', marginTop: '-70px' }}
           alt="wcpimage.jpeg"
        />
      </Box>
    </>
  );
};

export default WCPWebsite;
