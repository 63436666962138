import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import AddIcon from '@material-ui/icons/Add';

import InsuranceCompanyList from './insurance-company-list.component';
import InsuranceCompanyManageInfo from './insurance-company-manage-info.component';
import InsuranceCompanyServices from '../../services/insurance-company/insurance-company.services';

const InsuranceCompany = (props) => {
    const [insuranceCompanyInfo , setInsuranceCompanyInfo] = useState({
        icId : null,
        icName : '',
        icEmail : '',
        icPhone : '',
        icAddress : '',
    });
    const [insuranceCompanyList, setInsuranceCompanyList] = useState([]);
    const [open, setOpen] = useState(false);
    const [manageType, setManageType] = useState('ADD');

    const getInsuranceCompanies = () => {
        InsuranceCompanyServices.getInsuranceCompanyList()
          .then((response) => {
            setInsuranceCompanyList(response.data);
          })
          .catch();
    };

    useEffect(() => {
        getInsuranceCompanies();
    }, []);

    const handleClickOpen = (manageType, icData) => {
        if (manageType === 'EDIT') {
            const {
                id,
                email,
                name,
                phone,
                address,
            } = icData;

            setInsuranceCompanyInfo({
                icId : id,
                icEmail : email,
                icName : name,
                icPhone : phone,
                icAddress : address,
            });
        }
        setOpen(true);
        setManageType(manageType);
    };

    const handleClose = () => {
        setOpen(false);
        setInsuranceCompanyInfo({
            icId : null,
            icName : '',
            icEmail : '',
            icPhone : '',
            icAddress : '',
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInsuranceCompanyInfo({
          ...insuranceCompanyInfo,
          [name]: value,
        });
    };

    const createInsuranceCompany = () => {
        const {
            icName,
            icEmail,
            icPhone,
            icAddress,
        } = insuranceCompanyInfo;
    
        const insuranceCompanyPayload = {
            email : icEmail,
            name : icName,
            phone : icPhone,
            address : icAddress,
        };
    
        InsuranceCompanyServices.createInsuranceCompany(insuranceCompanyPayload).then((response) => {
            getInsuranceCompanies();
            handleClose();
        });
    };

    const updateInsuranceCompany = () => {
        const {
            icId,
            icName,
            icEmail,
            icPhone,
            icAddress,
        } = insuranceCompanyInfo;
    
        const insuranceCompanyPayload = {
            id : icId,
            email : icEmail,
            name : icName,
            phone : icPhone,
            address : icAddress,
        };
    
        InsuranceCompanyServices.updateInsuranceCompany(insuranceCompanyPayload).then((response) => {
            getInsuranceCompanies();
            handleClose();
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (manageType === 'ADD') {
            createInsuranceCompany();
        } else {
            updateInsuranceCompany();
        }
    };

    return (
      <div>
        <Card square>
          <CardHeader
            action={
              <Tooltip title="Add Insurance Company">
                <IconButton
                  aria-label="Add Insurance Company"
                  onClick={() => handleClickOpen("ADD", "")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
            title="Insurance"
          />

          <InsuranceCompanyList
            insuranceCompanyList={insuranceCompanyList}
            handleClickOpen={handleClickOpen}
            getInsuranceCompanies={getInsuranceCompanies}
          />
          <InsuranceCompanyManageInfo
            handleClose={handleClose}
            open={open}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            insuranceCompanyInfo={insuranceCompanyInfo}
            manageType={manageType}
          />
        </Card>
      </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});
  
export default connect(mapStateToProps)(InsuranceCompany);