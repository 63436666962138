import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import EmployeeServices from "../../../../services/employee/employee.services";

function MPNManageInfo({
  open,
  handleClose,
  ...otherProps
}) {

    const [mpnDetails, setMpnDetails] = useState({
        name: '',
        address: '',
        applicantName: '',
        applicantType: '',
        website: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setMpnDetails({
            ...mpnDetails,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        if (mpnDetails.name === '') {
            window.alert("Enter name.");
            return;
        }

        if (mpnDetails.address === '') {
            window.alert("Enter address.");
            return;
        }

        if (mpnDetails.website === '') {
            window.alert("Enter website.");
            return;
        }

        EmployeeServices.addMPNlookup(mpnDetails).then((response) => {
            if (response && response.data) {
                window.alert(response.data.message);
            }
            handleClose();
        });
    };

    return (
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth="xs" open={open}
                onClose={handleClose} aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    Add MPN
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                size="small"
                                fullWidth
                                value={mpnDetails.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="applicantName"
                                label="Applicant Name"
                                type="text"
                                size="small"
                                fullWidth
                                value={mpnDetails.applicantName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="type-select-label">Applicant Type</InputLabel>
                                <Select
                                    labelId="type-select-label"
                                    name="applicantType"
                                    value={mpnDetails.applicantType}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Entity that provides physician network services">
                                        Entity that provides physician network services
                                    </MenuItem>
                                    <MenuItem value="Group of Self-Insured Employers">
                                        Group of Self-Insured Employers
                                    </MenuItem>
                                    <MenuItem value="Joint Powers Authority">Joint Powers Authority</MenuItem>
                                    <MenuItem value="Self-Insured Employer">Self-Insured Employer</MenuItem>
                                    <MenuItem value="Insurer">Insurer</MenuItem>
                                    <MenuItem value="State">State</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="address"
                                label="Address"
                                type="text"
                                size="small"
                                fullWidth
                                value={mpnDetails.address}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="website"
                                label="Website"
                                type="text"
                                size="small"
                                fullWidth
                                value={mpnDetails.website}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}> Close </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>    
        </React.Fragment>
    );

}

export default MPNManageInfo;