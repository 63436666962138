import React from 'react';
import {
  OTPublisher,
  OTSubscriber,
  OTSession,
  OTStreams,
  preloadScript,
} from 'opentok-react';
import { uniqueId } from 'lodash';

import EmployeeService from '../../../services/employee/employee.services';

class EmployeeTelehealth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      connected: false,
      sessionCreds: {
        apiKey: '',
        sessionId: '',
        token: '',
      },
    };
    this.sessionEvents = {
      sessionConnected: () => {
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      },
    };
  }

  UNSAFE_componentWillMount() {
    let URL_ID = this.props.match.params.id,
        URL_ROLE = this.props.match.params.role;

    if (URL_ROLE === 'publisher') {
      EmployeeService.getTelehealthPublisher({
        encodedStr: URL_ID,
      }).then((response) => {
        this.setState({
          ...this.state.sessionCreds,
          sessionCreds: {
            apiKey: response.data.apiKey,
            sessionId: response.data.sessionId,
            token: response.data.token,
          },
        });
      });
    } else {
      EmployeeService.getTelehealthSubsciber({
        encodedStr: URL_ID,
      }).then((response) => {
        this.setState({
          ...this.state.sessionCreds,
          sessionCreds: {
            apiKey: response.data.apiKey,
            sessionId: response.data.sessionId,
            token: response.data.token,
          },
        });
      });
    }
  }

  onError = (err) => {
    this.setState({ error: `Failed to connect: ${err.message}` });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {this.state.sessionCreds.apiKey && (
          <OTSession
            apiKey={this.state.sessionCreds.apiKey}
            sessionId={this.state.sessionCreds.sessionId}
            token={this.state.sessionCreds.token}
            eventHandlers={this.sessionEvents}
            onError={this.onError}
          >
            {this.state.error ? <div id="error">{this.state.error}</div> : null}
            <ConnectionStatus connected={this.state.connected} />
            <Publisher />
            <OTStreams>
              <Subscriber />
            </OTStreams>
          </OTSession>
        )}
      </div>
    );
  }
}
export default preloadScript(EmployeeTelehealth);

class ConnectionStatus extends React.Component {
  render() {
    let status = this.props.connected ? 'Connected' : 'Disconnected';
    return (
      <div className="connectionStatus">
        <strong>Status:</strong> {status}
      </div>
    );
  }
}

class Publisher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      audio: false,
      video: false,
      videoSource: 'camera',
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  changeVideoSource = (videoSource) => {
    this.state.videoSource !== 'camera'
      ? this.setState({ videoSource: 'camera' })
      : this.setState({ videoSource: 'screen' });
  };

  onError = (err) => {
    this.setState({ error: `Failed to publish: ${err.message}` });
  };

  render() {
    return (
      <div className="publisher">
        Publisher
        {this.state.error ? <div id="error">{this.state.error}</div> : null}
        <OTPublisher
          properties={{
            publishAudio: this.state.audio,
            publishVideo: this.state.video,
            videoSource:
              this.state.videoSource === 'screen' ? 'screen' : undefined,
          }}
          onError={this.onError}
        />
        <CheckBox label="Share Screen" onChange={this.changeVideoSource} />
        <CheckBox
          label="Publish Audio"
          initialChecked={this.state.audio}
          onChange={this.setAudio}
        />
        <CheckBox
          label="Publish Video"
          initialChecked={this.state.video}
          onChange={this.setVideo}
        />
      </div>
    );
  }
}

class Subscriber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true,
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  onError = (err) => {
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  };

  render() {
    return (
      <div className="subscriber">
        Subscriber
        {this.state.error ? <div id="error">{this.state.error}</div> : null}
        <OTSubscriber
          properties={{
            subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
          }}
          onError={this.onError}
        />
        <CheckBox
          label="Subscribe to Audio"
          initialChecked={this.state.audio}
          onChange={this.setAudio}
        />
        <CheckBox
          label="Subscribe to Video"
          initialChecked={this.state.video}
          onChange={this.setVideo}
        />
      </div>
    );
  }
}

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: uniqueId('Checkbox'),
      isChecked: this.props.initialChecked,
    };
  }

  onChange = (event) => {
    let isChecked = event.currentTarget.checked;
    this.setState({ isChecked });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isChecked !== this.state.isChecked &&
      typeof this.props.onChange === 'function'
    ) {
      this.props.onChange(this.state.isChecked);
    }
  }

  render() {
    return (
      <div>
        <label htmlFor={this.state.id}>{this.props.label}</label>
        <input
          type="checkbox"
          checked={this.state.isChecked}
          id={this.state.id}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
