import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

import CommonService from '../../services/common/common.services';
import TaskService from '../../services/task/task.services';

const TaskManageInfo = ({
    taskManageOpen,
    setTaskManageOpen,
    getTaskList,
    taskDetailInfo,
    currentUser,
    ...otherProps
}) => {
    const [taskInfo, setTaskInfo] = useState({
        taskId: taskDetailInfo !== undefined ? (taskDetailInfo.id !== null ? taskDetailInfo.id : null) : null,
        tsDueDate: taskDetailInfo !== undefined ? (taskDetailInfo.dueDate != null ? 
            taskDetailInfo.dueDate : moment(new Date()).format('YYYY-MM-DD')) : moment(new Date()).format('YYYY-MM-DD'),
        tsPriority: taskDetailInfo !== undefined ? taskDetailInfo.priority : 'MEDIUM',
        tsStatus: taskDetailInfo !== undefined ? taskDetailInfo.status : 'INPROCESS',
        tsActionItem: taskDetailInfo !== undefined ? taskDetailInfo.actionItem : '',
        tsNotes: taskDetailInfo !== undefined ? taskDetailInfo.notes : '',
        tsToUser: taskDetailInfo !== undefined ? (taskDetailInfo.toUser !== null ? taskDetailInfo.toUser : 
          {id : currentUser.id, userName : currentUser.userName}) : 
          {id : currentUser.id, userName : currentUser.userName},
        tsToUserRole: taskDetailInfo !== undefined ? (taskDetailInfo.toUser !== null ? 
          taskDetailInfo.toUser.role : 'SELF') : 'SELF',
        tsAssignee: taskDetailInfo !== undefined ? (taskDetailInfo.assignee !== null ? taskDetailInfo.assignee : 
          {id : currentUser.id, userName : currentUser.userName}): 
          {id : currentUser.id, userName : currentUser.userName},
        tsAssigneeRole: taskDetailInfo !== undefined ? (taskDetailInfo.assignee !== null ? 
          taskDetailInfo.assignee.role : 'SELF') : 'SELF',
        creatorId: currentUser.id,
        isToUserSelf: true,
        isAssigneeSelf: true,
    });

    const [toUserListForSearch, setToUserListForSearch] = useState([]);
    useEffect(() => {
        if (taskInfo.tsToUserRole !== '') {
            if (taskInfo.tsToUserRole === 'SELF') {
                setToUserListForSearch([{id : currentUser.id, userName : currentUser.userName}]);
                setTaskInfo({
                    ...taskInfo,
                    tsToUser: {
                        id : currentUser.id,
                        userName : currentUser.userName,
                    },
                    isToUserSelf : true,
                });
            } else {
                CommonService.getUserListByRole(taskInfo.tsToUserRole).then((response) => {
                    setToUserListForSearch(response.data);
                    setTaskInfo({
                        ...taskInfo,
                        isToUserSelf : false,
                    });
                }).catch();
            }
        }
    }, [taskInfo.tsToUserRole]);

    const [assigneeListForSearch, setAssigneeListForSearch] = useState([]);
    useEffect(() => {
        if (taskInfo.tsAssigneeRole !== '') {
            if (taskInfo.tsAssigneeRole === 'SELF') {
                setAssigneeListForSearch([{id : currentUser.id, userName : currentUser.userName}]);
                setTaskInfo({
                    ...taskInfo,
                    tsAssignee: {
                        id : currentUser.id,
                        userName : currentUser.userName,
                    },
                    isAssigneeSelf : true,
                });
            } else {
                CommonService.getUserListByRole(taskInfo.tsAssigneeRole).then((response) => {
                    setAssigneeListForSearch(response.data);
                    setTaskInfo({
                        ...taskInfo,
                        isAssigneeSelf : false,
                    });
                }).catch();
            }
        }
    }, [taskInfo.tsAssigneeRole]);

    const handleClose = () => {
        setTaskInfo({
          taskId: null,
          tsDueDate: moment(new Date()).format('YYYY-MM-DD'),
          tsPriority: 'MEDIUM',
          tsStatus: 'INPROCESS',
          tsActionItem: '',
          tsNotes: '',
          tsToUser: {id : currentUser.id, userName : currentUser.userName},
          tsAssignee: {id : currentUser.id, userName : currentUser.userName},
          tsToUserRole: 'SELF',
          tsAssigneeRole: 'SELF',
          creatorId: currentUser.id,
          isToUserSelf: true,
          isAssigneeSelf: true,
        });
        
        if (typeof getTaskList === "function") { 
          getTaskList();
        }
        setTaskManageOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTaskInfo({
          ...taskInfo,
          [name]: value,
        });
    };

    const handleToUserRoleChange = (event) => {
        const { name, value } = event.target;
        setTaskInfo({
          ...taskInfo,
          [name]: value,
          tsToUser: null,
        });
    };

    const handleToUserAutoCompleteChange = (event, value) => {
        setTaskInfo({
          ...taskInfo,
          tsToUser: value,
        });
    };

    const handleAssigneeRoleChange = (event) => {
        const { name, value } = event.target;
        setTaskInfo({
          ...taskInfo,
          [name]: value,
          tsAssignee: null,
        });
    };

    const handleAssigneeAutoCompleteChange = (event, value) => {
        setTaskInfo({
          ...taskInfo,
          tsAssignee: value,
        });
    };

    const createTask = () => {
        const {
          tsDueDate,
          tsPriority,
          tsStatus,
          tsActionItem,
          tsNotes,
          tsToUser,
          tsAssignee,
          creatorId,
        } = taskInfo;
    
        const taskPayload = {
          dueDate: tsDueDate,
          priority: tsPriority,
          status: tsStatus,
          actionItem: tsActionItem,
          notes: tsNotes,
          toUser: {
            id: tsToUser.id,
          },
          assignee: {
            id: tsAssignee.id,
          },
          createdBy: {
            id: creatorId,
          },
        };
    
        TaskService.addTask(taskPayload).then((response) => {
            // getTaskList();
            handleClose();
        });
    };

    const updateTask = () => {
      const {
        taskId,
        tsDueDate,
        tsPriority,
        tsStatus,
        tsActionItem,
        tsNotes,
        tsToUser,
        tsAssignee,
        creatorId,
      } = taskInfo;
  
      const taskPayload = {
        id: taskId,
        dueDate: tsDueDate,
        priority: tsPriority,
        status: tsStatus,
        actionItem: tsActionItem,
        notes: tsNotes,
        toUser: {
          id: tsToUser.id,
        },
        assignee: {
          id: tsAssignee.id,
        },
        updatedBy: {
          id: creatorId,
        },
        createdBy: {
          id: taskDetailInfo !== undefined ? taskDetailInfo.createdBy.id : null,
        },
        createdAt: taskDetailInfo !== undefined ? taskDetailInfo.createdAt : null,
      };
  
      TaskService.updateTask(taskPayload).then((response) => {
          // getTaskList();
          handleClose();
      });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (taskInfo.taskId === null) {
          createTask();
        } else {
          updateTask();
        }
    };

    return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={taskManageOpen}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">Manage Task</DialogTitle>
            <DialogContent>
              <form id="task_manage_form" onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="user-role-select-label">TO</InputLabel>
                        <Select
                          labelId="user-role-select-label"
                          id="user-role-select"
                          name="tsToUserRole"
                          value={taskInfo.tsToUserRole}
                          onChange={handleToUserRoleChange}
                        >
                          <MenuItem value="SELF">Self</MenuItem>
                          <MenuItem value="EMPLOYEE">Employee</MenuItem>
                          <MenuItem value="PROVIDER">Provider</MenuItem>
                          <MenuItem value="STAFF">Staff</MenuItem>
                          <MenuItem value="ATTORNEY">Attorney</MenuItem>
                          <MenuItem value="CLAIMEXAMINER">Claim Examiner</MenuItem>
                          <MenuItem value="NURSECASEMANAGER">Nurse Case Manager</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
    
                    <Grid item xs={6}>
                      <Autocomplete
                        id="user-auto-search"
                        name="tsToUser"
                        value={taskInfo.tsToUser}
                        getOptionSelected={(option, value) => option.userName === value.userName}
                        getOptionLabel={(option) => option.userName}
                        options={toUserListForSearch}
                        onChange={handleToUserAutoCompleteChange}
                        disabled={taskInfo.isToUserSelf}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Individual" />
                        )}
                      />
                    </Grid>
    
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="tsActionItem"
                      label="Action Item"
                      type="text"
                      fullWidth
                      value={taskInfo.tsActionItem}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="dense"
                      name="tsDueDate"
                      label="Due Date"
                      type="date"
                      fullWidth
                      value={taskInfo.tsDueDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="priority-select-label">Priority</InputLabel>
                      <Select
                        labelId="priority-select-label"
                        id="priority-select"
                        name="tsPriority"
                        value={taskInfo.tsPriority}
                        onChange={handleChange}
                      >
                        <MenuItem value="HIGH">HIGH</MenuItem>
                        <MenuItem value="MEDIUM">MEDIUM</MenuItem>
                        <MenuItem value="LOW">LOW</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="status-select-label">Status</InputLabel>
                      <Select
                        labelId="status-select-label"
                        id="status-select"
                        name="tsStatus"
                        value={taskInfo.tsStatus}
                        onChange={handleChange}
                      >
                        <MenuItem value="INPROCESS">In Process</MenuItem>
                        <MenuItem value="AWAITING">Awaiting Response</MenuItem>
                        <MenuItem value="COMPLETE">Complete</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
    
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="assignee-role-select-label">
                        FROM
                      </InputLabel>
                      <Select
                        labelId="assignee-role-select-label"
                        id="assignee-role-select"
                        name="tsAssigneeRole"
                        value={taskInfo.tsAssigneeRole}
                        onChange={handleAssigneeRoleChange}
                      >
                        <MenuItem value="SELF">Self</MenuItem>
                        <MenuItem value="PROVIDER">Provider</MenuItem>
                        <MenuItem value="STAFF">Staff</MenuItem>
                        <MenuItem value="ATTORNEY">Attorney</MenuItem>
                        <MenuItem value="CLAIMEXAMINER">Claim Examiner</MenuItem>
                        <MenuItem value="NURSECASEMANAGER">Nurse Case Manager</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="assignee-auto-search"
                      name="tsAssignee"
                      value={taskInfo.tsAssignee}
                      getOptionSelected={(option, value) => option.userName === value.userName}
                      getOptionLabel={(option) => option.userName}
                      options={assigneeListForSearch}
                      onChange={handleAssigneeAutoCompleteChange}
                      disabled={taskInfo.isAssigneeSelf}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Individual" />
                      )}
                    />
                  </Grid>
    
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      name="tsNotes"
                      label="Notes"
                      multiline
                      rowsMax={4}
                      fullWidth
                      value={taskInfo.tsNotes}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(TaskManageInfo);