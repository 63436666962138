import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';

import 'fontsource-roboto';
import { Backdrop, CircularProgress } from '@material-ui/core';
import RoutesList from './utils/routes-list';

import { toggleLoadingFlag } from './redux/loading/loading.actions';
import { selectLoadingFlag } from './redux/loading/loading.selectors';
import { selectCurrentUser } from './redux/user/user.selectors';
import { setAxiosInterceptor } from './redux/interceptor/interceptor.actions';

function App({
  currentUser,
  setAxiosInterceptor,
  toggleLoadingFlag,
  loadingFlag,
  ...props
}) {
  const [interceptorsSetup, setInterceptorsSetup] = useState(false);
useEffect(() => {
  const setupInterceptors = async () => {
    const AXIOS_INTERCEPTOR = axios.interceptors.request.use((config) => {
      if (currentUser) {
        config.url = config.url + currentUser.token;
      }
      return config;
    });

    setAxiosInterceptor(AXIOS_INTERCEPTOR);

    axios.interceptors.request.use((config) => {
      toggleLoadingFlag(true);
      return config;
    });

    axios.interceptors.response.use(
      (config) => {
        toggleLoadingFlag(false);
        return config;
      },
      (error) => {
        toggleLoadingFlag(false);
        return Promise.reject(error);
      }
    );
    setInterceptorsSetup(true);
  }

    setupInterceptors();
}, []);

  return (
    <>
      <Backdrop
        open={loadingFlag}
        style={{
          zIndex: 1400,
          color: '#fff',
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {interceptorsSetup && <RoutesList />}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loadingFlag: selectLoadingFlag,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleLoadingFlag: (loadingFlag) => dispatch(toggleLoadingFlag(loadingFlag)),
  setAxiosInterceptor: (interceptor) =>
    dispatch(setAxiosInterceptor(interceptor)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
