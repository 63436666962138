import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import DialogTitleWithClose from "../../common/dialog-title-close.component";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import employeeServices from "../../../services/employee/employee.services";
function TreatmentHistory({
  treatmentHistoryList,
  openTreatmentHistory,
  handleTreatmentHistoryClose,
  handleHistoryTreatment,
  ...otherProps
}) {
  const handleClickDelete = (id) => {
    employeeServices
      .removeHistory("treatment", id)
      .then((response) => {
        if (response && response.data) {
          handleHistoryTreatment();
          alert(response.data);
        }
      })
      .catch();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openTreatmentHistory}
        onClose={handleTreatmentHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <u>{"Treatment History"}</u>
        </DialogTitle>
        <DialogTitleWithClose
          onClose={() => handleTreatmentHistoryClose()}
        ></DialogTitleWithClose>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Requested Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Requested Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Approved Date </b>
                  </TableCell>
                  <TableCell>
                    <b>Utilization Review</b>
                  </TableCell>
                  <TableCell>
                    <b>Denied Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {treatmentHistoryList != null &&
                treatmentHistoryList.length > 0 ? (
                  treatmentHistoryList.map((treatmentHistory) => (
                    <TableRow key={treatmentHistory.id}>
                      <TableCell>
                        {treatmentHistory.requestedType !== null &&
                        treatmentHistory.requestedType !== ""
                          ? treatmentHistory.requestedType
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {treatmentHistory.requestedDate !== null
                          ? moment(treatmentHistory.requestedDate).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {treatmentHistory.approvedDate !== null
                          ? moment(treatmentHistory.approvedDate).format(
                              "YYYY-MM-DD"
                            )
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {treatmentHistory.utilizationReview !== null &&
                        treatmentHistory.utilizationReview !== ""
                          ? treatmentHistory.utilizationReview
                          : "--"}
                      </TableCell>
                      <TableCell>
                        {treatmentHistory.deniedDate !== null &&
                        treatmentHistory.deniedDate !== ""
                          ? treatmentHistory.deniedDate
                          : "--"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove">
                          <IconButton
                            aria-label="Edit"
                            onClick={() =>
                              handleClickDelete(treatmentHistory.id)
                            }
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default TreatmentHistory;
