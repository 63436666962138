import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';

import {
  Tooltip,
  CardHeader,
  TextField,
  Box,
  Grid,
  InputAdornment,
  Button,
  Paper,
  IconButton
} from '@material-ui/core';
// import { teal } from '@material-ui/core/colors';
import HistoryIcon from '@material-ui/icons/History';
import RefreshIcon from '@material-ui/icons/Refresh';

import VisitsHistory from '../visits-history.component';
import employeeServices from '../../../../services/employee/employee.services';

const EDEVisits = ({ formik, currentUser, employeeDetails, ...otherProps }) => {
  const [visitsHistoryList, setVisitsHistoryList] = useState([]);
  const [openVisitsHistory, setOpenVisitsHistory] = useState(false);
  const [visitDateDiff, setVisitDateDiff] = useState('');

  const handleHistoryVisits = (value) => {
    employeeServices
      .getVisitsHistoryList(
        employeeDetails.employee.id,
        employeeDetails.claimNumber
      )
      .then((response) => {
        if (response && response.data) {
          setVisitsHistoryList(response.data);
        }
      })
      .catch();
    setOpenVisitsHistory(true);
  };

  const handleVisitsHistoryClose = () => {
    setOpenVisitsHistory(false);
  };

  const calculateDateDiff = (fov, nov) => {
    if (fov === '' || fov === null || nov === '' || nov === null) return;

    let a = moment(nov);
    let b = moment(fov);

    let years = a.diff(b, 'year');
    b.add(years, 'years');

    let months = a.diff(b, 'months');
    b.add(months, 'months');

    let days = a.diff(b, 'days');

    setVisitDateDiff(
      `${
        years === 0 || Math.sign(years) === -1
          ? ''
          : `${years}year${years === 1 ? '' : 's'}`
      } ${
        months === 0 || Math.sign(months) === -1
          ? ''
          : `${months}month${months === 1 ? '' : 's'}`
      } ${
        days === 0 || Math.sign(days) === -1
          ? ''
          : `${days}day${days === 1 ? '' : 's'}`
      }`
    );
  };

  useEffect(() => {
    calculateDateDiff(
      formik.values.visitDetails[0].firstOfficeVisit,
      formik.values.visitDetails[0].nextOfficeVisit
    );
  }, [
    formik.values.visitDetails[0].firstOfficeVisit,
    formik.values.visitDetails[0].nextOfficeVisit,
  ]);

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={teal[50]}
      bgcolor="#e9fafa"

      >
        <CardHeader
          title="Visits (Treatment)"
          subheader={visitDateDiff}
          color="text.secondary"
          component={Box}
          action={
            <>
              <Button
                startIcon={<HistoryIcon />}
                onClick={() => handleHistoryVisits()}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN,ROLE.CLAIMEXAMINER], currentUser.role)}
              >
                History
              </Button>
            </>
          }
        />
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <TextField
              label="First Office Visit"
              name={`visitDetails[0].firstOfficeVisit`}
              value={moment(
                ifNullAndUndefined(
                  formik.values.visitDetails[0].firstOfficeVisit
                )
                  ? ""
                  : formik.values.visitDetails[0].firstOfficeVisit
              ).format("YYYY-MM-DD")}
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
              disabled={
                ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role) ||
                !ifNullAndUndefined(
                  formik.values.visitDetails[0].firstOfficeVisit
                )
              }
              InputProps={{
                endAdornment: (
                  <>
                    {ifNullAndUndefined(
                      formik.values.visitDetails[0].firstOfficeVisit
                    ) ? (
                      ""
                    ) : (
                      <>
                        <InputAdornment position="end">
                          <Tooltip title="Reset">
                            <IconButton
                              onClick={() =>
                                formik.setFieldValue(
                                  "visitDetails[0].firstOfficeVisit",
                                  null
                                )
                              }
                              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN,ROLE.CLAIMEXAMINER], currentUser.role)}

                            >
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      </>
                    )}
                  </>
                ),
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <TextField
              label="Next Office Visit"
              name={`visitDetails[0].nextOfficeVisit`}
              value={moment(
                ifNullAndUndefined(
                  formik.values.visitDetails[0].nextOfficeVisit
                )
                  ? ""
                  : formik.values.visitDetails[0].nextOfficeVisit
              ).format("YYYY-MM-DD")}
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Paper>

      <VisitsHistory
        visitsHistoryList={visitsHistoryList}
        openVisitsHistory={openVisitsHistory}
        handleVisitsHistoryClose={handleVisitsHistoryClose}
        handleHistoryVisits={handleHistoryVisits}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEVisits);
