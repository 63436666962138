import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import React, { useState } from "react";
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';
import PriorInjuriesHistory from "../prior-injuries-history.component";
import {
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Button
} from "@material-ui/core";
// import { indigo } from '@material-ui/core/colors';
import employeeServices from "../../../../services/employee/employee.services";
import HistoryIcon from "@material-ui/icons/History";

const EDEPriorInjuries = ({ formik, currentUser,employeeDetails, ...otherProps }) => {
  const [priorInjuriesHistoryList, setPriorInjuriesHistoryList] = useState([]);
  const [openPcdHistory, setOpenPcdHistory] = useState(false);
  const fetchPriorCareHistoryList = (value) => {
    employeeServices
      .getPriorCareHistoryList(
        employeeDetails.employee.id,
        employeeDetails.claimNumber
      )
      .then((response) => {
        if (response && response.data) {
          setPriorInjuriesHistoryList(response.data);
        }
      })
      .catch();
    setOpenPcdHistory(true);
  };
  const handlePcdHistoryClose = () => {
    setOpenPcdHistory(false);
  };

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={indigo[50]}
      bgcolor="#e1f5fe"
      >
        <CardHeader
          title="Prior Injuries"
          color="text.secondary"
          component={Box}
          action={
            <>
              <Button
                startIcon={<HistoryIcon />}
                onClick={() => fetchPriorCareHistoryList()}
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              >
                History
              </Button>
            </>
          }
        />

        <Grid container spacing={1}>
          <Grid item sm={12} xs={6}>
            <TextField
              label="Body Part"
              name={`priorCareDetails[0].priorInjuryBodyPart`}
              value={
                ifNullAndUndefined(
                  formik.values.priorCareDetails[0].priorInjuryBodyPart
                )
                  ? ""
                  : formik.values.priorCareDetails[0].priorInjuryBodyPart
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item sm={12} xs={6}>
            <TextField
              label="Date"
              name={`priorCareDetails[0].priorInjuryDate`}
              value={moment(
                ifNullAndUndefined(
                  formik.values.priorCareDetails[0].priorInjuryDate
                )
                  ? ""
                  : formik.values.priorCareDetails[0].priorInjuryDate
              ).format("YYYY-MM-DD")}
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Paper>
      <PriorInjuriesHistory
        priorInjuriesHistoryList={priorInjuriesHistoryList}
        openPcdHistory={openPcdHistory}
        handlePcdHistoryClose={handlePcdHistoryClose}
        fetchPriorCareHistoryList={fetchPriorCareHistoryList}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEPriorInjuries);
