import axios from 'axios';

import { CLAIM_ADMIN_API_URL } from '../../utils/settings';

class ClaimsAdministratorServices {
  getClaimsAdministratorList(userType) {
    return axios.get(`${CLAIM_ADMIN_API_URL}/${userType}/claimsadministrators`);
  }

  createClaimsAdministrator(data) {
    return axios({
      method: 'POST',
      url: `${CLAIM_ADMIN_API_URL}/claimsadministrator`,
      data,
    });
  }

  updateClaimsAdministrator(data) {
    return axios({
      method: 'PUT',
      url: `${CLAIM_ADMIN_API_URL}/claimsadministrator`,
      data,
    });
  }
}

export default new ClaimsAdministratorServices();
