import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import AddIcon from '@material-ui/icons/Add';

import ClaimsExaminerManageInfo from './claims-examiner-manage-info.component';
import ClaimsExaminerList from './claims-examiner-list.component';
import ClaimsAdministratorServices from '../../services/claims-administrator/claims-administrator.services';
import InsuranceCompanyServices from '../../services/insurance-company/insurance-company.services';

const ClaimsExaminer = (props) => {
    const [claimsExaminerInfo, setClaimsExaminerInfo] = useState({
        caId : null,
        caEmail : '',
        caFax : '',
        caName : '',
        caPhone : '',
        caAddress: '',
        caInsuranceCompanies: [],
    });

    const [claimsExaminerList, setClaimsExaminerList] = useState([]);
    const [open, setOpen] = useState(false);
    const [manageType, setManageType] = useState('ADD');
    const [insuranceList, setInsuranceList] = useState([]);

    const getClaimsExaminers = () => {
        ClaimsAdministratorServices.getClaimsAdministratorList('CLAIMEXAMINER').then((response) => {
            setClaimsExaminerList(response.data);
        }).catch();
    };

    const getInsuranceCompanies = () => {
        InsuranceCompanyServices.getInsuranceCompanyList().then((response) => {
            setInsuranceList(response.data);
        }).catch();
    };

    useEffect(() => {
        getClaimsExaminers();
    }, []);

    const handleClickOpen = (manageType, caData) => {
        getInsuranceCompanies();

        if (manageType === 'EDIT') {
          const {
            id,
            email,
            name,
            phone,
            fax,
            address,
            insuranceCompanyList,
          } = caData;
    
          setClaimsExaminerInfo({
            caId : id,  
            caEmail : email,
            caName : name,
            caPhone : phone,
            caFax : fax,
            caAddress: address,
            caInsuranceCompanies : insuranceCompanyList != null && insuranceCompanyList.length > 0 
                ? insuranceCompanyList.map((item) => item.id) : [],
          });
        }
    
        setOpen(true);
        setManageType(manageType);
    };

    const handleClose = () => {
        setOpen(false);
        setClaimsExaminerInfo({
            caId : null,  
            caEmail : '',
            caName : '',
            caPhone : '',
            caFax : '',
            caAddress: '',
            caInsuranceCompanies: [],
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setClaimsExaminerInfo({
          ...claimsExaminerInfo,
          [name]: value,
        });
    };

    const createClaimsExaminer = () => {
        const {
            caEmail,
            caName,
            caPhone,
            caFax,
            caAddress,
            caInsuranceCompanies,
        } = claimsExaminerInfo;
    
        const claimsExaminerPayload = {
            email : caEmail,
            name : caName,
            phone : caPhone,
            fax : caFax,
            title : 'CLAIMEXAMINER',
            address: caAddress,
            userId: props.currentUser.id,
            insuranceCompanyList: caInsuranceCompanies.map((item) => ({
                id: item,
            })),
        };
    
        ClaimsAdministratorServices.createClaimsAdministrator(claimsExaminerPayload).then((response) => {
            window.alert(response.data.message);
            getClaimsExaminers();
            handleClose();
        });
    };

    const updateClaimsExaminer = () => {
        const {
            caId,
            caEmail,
            caName,
            caPhone,
            caFax,
            caAddress,
            caInsuranceCompanies,
        } = claimsExaminerInfo;
    
        const claimsExaminerPayload = {
            id : caId,
            email : caEmail,
            name : caName,
            phone : caPhone,
            title : 'CLAIMEXAMINER',
            fax : caFax,
            address: caAddress,
            userId: props.currentUser.id,
            insuranceCompanyList: caInsuranceCompanies.map((item) => ({
                id: item,
            })),
        };
    
        ClaimsAdministratorServices.updateClaimsAdministrator(claimsExaminerPayload).then((response) => {
            window.alert(response.data.message);
            getClaimsExaminers();
            handleClose();
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (manageType === 'ADD') {
            createClaimsExaminer();
        } else {
            updateClaimsExaminer();
        }
    };

    return (
      <div>
        <Card square>
          <CardHeader
            action={
              <Tooltip title="Add Claims Administrator">
                <IconButton
                  aria-label="Add Claims Administrator"
                  onClick={() => handleClickOpen("ADD", "")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
            title="Claims Administrator"
          />

          <ClaimsExaminerList
            claimsExaminerList={claimsExaminerList}
            handleClickOpen={handleClickOpen}
            getClaimsExaminers={getClaimsExaminers}
            props={props}
          />
        </Card>

        <ClaimsExaminerManageInfo
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          claimsExaminerInfo={claimsExaminerInfo}
          insuranceList={insuranceList}
          manageType={manageType}
        />
      </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
  });
  
  export default connect(mapStateToProps)(ClaimsExaminer);