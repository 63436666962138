import axios from 'axios';
import { CALENDAR_API_URL } from "../../utils/settings";

class CalendarServices {
  getEventList(userid) {
    return axios.get(`${CALENDAR_API_URL}/${userid}/calenderevent`);
  }
  getCalenderEventById(calId) {
    return axios.get(`${CALENDAR_API_URL}/${calId}/calendereventbyid`);
  }
  createEvent(data) {
    return axios({
      method: "POST",
      url: `${CALENDAR_API_URL}/calenderevent`,
      data,
    });
  }

  editEvent(data, calId) {
    return axios({
      method: "PUT",
      url: `${CALENDAR_API_URL}/${calId}/calenderevent`,
      data,
    });
  }
}
export default new CalendarServices();