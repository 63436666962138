import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EmployeeServices from "../../../../services/employee/employee.services";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function EDEDocumentHistory({
    openDocumentHistory,
    documentList,
    handleDocumentHistoryClose,
    handleDocumentHistory,
    currentUser,
    ...otherProps
}) {
  
  const classes = useStyles();
  const jobDescList = documentList.filter(document => document.message === 'JOB DESCRIPTION');
  const rtwPolicyList = documentList.filter(document => document.message === 'RTW POLICY');
  const pcdReportList = documentList.filter(document => document.message === 'PCD REPORT'); 
  const rfaReportList = documentList.filter(document => document.message === 'RFA REPORT'); 
  const authorizationList = documentList.filter(document => document.message === 'AUTHORIZATIONS'); 
  const pr2List = documentList.filter(document => document.message === 'PR2'); 
  const denialsList = documentList.filter(document => document.message === 'DENIALS'); 
  const rfiList = documentList.filter(document => document.message === 'RFI'); 
  const miscList = documentList.filter(document => document.message === 'MISCELLANEOUS'); 
  const workStatusDocsList = documentList.filter(document => document.message === 'WORK STATUS DOCUMENTS'); 



  const handleDocumentDownload = (documentData) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf;base64,${documentData.content}`;
    downloadLink.download = documentData.name;
    downloadLink.click();
  };  

  const handleClickDelete = (id, message) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      EmployeeServices.removeDiagnosisHistory(id, message).then((response) => {
        if (response && response.data) {
          handleDocumentHistory();
          alert(response.data);
        }
      }).catch();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDocumentHistory}
        onClose={handleDocumentHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Document History</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  RU90 / Job Description
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {jobDescList !== null && jobDescList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobDescList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {jobDescList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>RTW Policy</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {rtwPolicyList !== null && rtwPolicyList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rtwPolicyList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {rtwPolicyList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.heading}>
                  Prior Care Detail Reports
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {pcdReportList !== null && pcdReportList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Type Of Service </b>
                          </TableCell>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pcdReportList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              {document.typeOfService !== null &&
                              document.typeOfService !== ""
                                ? document.typeOfService
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {pcdReportList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography className={classes.heading}>Work Status Documents</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {workStatusDocsList !== null && workStatusDocsList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workStatusDocsList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {workStatusDocsList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Typography className={classes.heading}>RFA Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {rfaReportList !== null && rfaReportList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rfaReportList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {rfaReportList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

          { currentUser.role!=="EMPLOYER"? <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6a-content"
                id="panel6a-header"
              >
                <Typography className={classes.heading}>PR2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {pr2List !== null && pr2List.length > 0 && (
                  <TableContainer component={Paper} >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pr2List.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {pr2List.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>:null
            }

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7a-content"
                id="panel7a-header"
              >
                <Typography className={classes.heading}>Authorizations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {authorizationList !== null && authorizationList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {authorizationList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {authorizationList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8a-content"
                id="panel8a-header"
              >
                <Typography className={classes.heading}>Denials</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {denialsList !== null && denialsList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {denialsList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {denialsList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9a-content"
                id="panel9a-header"
              >
                <Typography className={classes.heading}>RFI</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {rfiList !== null && rfiList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rfiList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {rfiList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10a-content"
                id="panel10a-header"
              >
                <Typography className={classes.heading}>Miscellaneous</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {miscList !== null && miscList.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Document</b>
                          </TableCell>
                          <TableCell>
                            <b>Added On</b>
                          </TableCell>
                          <TableCell>
                            <b>Description </b>
                          </TableCell>
                          <TableCell>
                            <b>Action </b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {miscList.map((document) => (
                          <TableRow key={document.id}>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => handleDocumentDownload(document)}
                              >
                                {document.name !== null && document.name !== ""
                                  ? document.name
                                  : "--"}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {document.addedOn !== null
                                ? moment(document.uploadDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {document.description !== null &&
                              document.description !== ""
                                ? document.description
                                : "--"}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    handleClickDelete(document.id, document.message)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {miscList.length === 0 && (
                  <Typography>No Document Found...</Typography>
                )}
              </AccordionDetails>
            </Accordion>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDocumentHistoryClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EDEDocumentHistory;
