import React from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/common/tab-panel.component';
import { a11yProps } from '../../utils/common-utils';
import Employee from '../../components/employee/employee.component';
import Message from '../../components/message/message.component';
import Task from '../../components/task/task.component';
import Dashboard from '../../components/common/dashboard.component';
import Header from '../../components/common/header/header.component';

const UroHomePage = () => {
  const [value, setValue] = React.useState(0);
  const idString = 'home';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Dashboard" {...a11yProps(idString, 0)} />
          <Tab label="Employee" {...a11yProps(idString, 1)} />
          <Tab label="Messages" {...a11yProps(idString, 2)} />
          <Tab label="Task" {...a11yProps(idString, 3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} idString={idString}>
        <Dashboard />
      </TabPanel>
      <TabPanel value={value} index={1} idString={idString}>
        <Employee />
      </TabPanel>
      <TabPanel value={value} index={2} idString={idString}>
        <Message />
      </TabPanel>
      <TabPanel value={value} index={3} idString={idString}>
        <Task />
      </TabPanel>
    </>
  );
};

export default UroHomePage;
