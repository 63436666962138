import { makeStyles } from '@material-ui/core/styles';

export const BASE_PK = 'wcp'; // Base Project Keyword
export const BASE_API_URL = `https://worcflo.net/${BASE_PK}api/v1`;
export const OAUTH_API_URL = `${BASE_API_URL}/oauth/token`;
export const USER_API_URL = `${BASE_API_URL}/rest/user`;
export const VALIDATE_API_URL = `${BASE_API_URL}/rest/validate`;
export const EMPLOYER_API_URL = `${BASE_API_URL}/rest/employer`;
export const URO_API_URL = `${BASE_API_URL}/rest/uro`;
export const EMPLOYEE_API_URL = `${BASE_API_URL}/rest/employee`;
export const EMPLOYEE_TELEHEALTH_API_URL = `${BASE_API_URL}/rest/telehealth`;
export const CLAIM_ADMIN_API_URL = `${BASE_API_URL}/rest/claimadmin`;
export const PROVIDER_API_URL = `${BASE_API_URL}/rest/provider`;
export const INSURANCE_COMPANY_API_URL = `${BASE_API_URL}/rest/inscompany`;
export const STAFF_API_URL = `${BASE_API_URL}/rest/staff`;
export const MESSAGE_API_URL = `${BASE_API_URL}/rest/messaging`;
export const TASK_API_URL = `${BASE_API_URL}/rest/task`;
export const CALENDAR_API_URL = `${BASE_API_URL}/rest/calendar`;
export const WEB_URL = `/${BASE_PK}`;
export const RFA_API_URL=`${BASE_API_URL}/rest/rfa`;
export const PATIENT_DEMOGRAPHICS=`${BASE_API_URL}/rest/patient`;
export const BRAND_NAME = 'Comprehensive Spine & Sports Center';
export const BRAND_NAME_SHORT = 'CSSCTR';
export const MODULE_NAME = "WORKER'S COMP PORTAL";
export const MODULE_NAME_SHORT = 'WCP';
export const ROLE = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  EMPLOYER: 'EMPLOYER',
  PROVIDER: 'PROVIDER',
  CLAIMEXAMINER: 'CLAIMEXAMINER',
  ATTORNEY: 'ATTORNEY',
  NURSECASEMANAGER: 'NURSECASEMANAGER',
  STAFF: 'STAFF',
  EMPLOYEE: 'EMPLOYEE',
  URO: 'URO',
};

export const STYLE_SETTINGS = (theme) => {
  return {
    appBarRoot: {
      flexGrow: 1,
    },
    appBarMenuButton: {
      marginRight: theme.spacing(2),
    },
    appBarTitle: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
    },
  };
};

export const useStyles = makeStyles((theme) => ({
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
