import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import TabPanel from "../../components/common/tab-panel.component";
import { a11yProps } from "../../utils/common-utils";
import Employee from "../../components/employee/employee.component";
import Header from "../../components/common/header/header.component";
import Message from "../../components/message/message.component"
const AttorneyHomePage = () => {
  const [value, setValue] = React.useState(0);
  const idString = "home";
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Patient" {...a11yProps(idString, 0)} />
          <Tab label="Messages" {...a11yProps(idString, 1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} idString={idString}>
        <Employee />
      </TabPanel>
      <TabPanel value={value} index={1} idString={idString}>
        <Message />
      </TabPanel>
    </>
  );
};

export default AttorneyHomePage;
