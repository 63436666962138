import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../../redux/user/user.selectors';

import {
  TextField,
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  // Grid,
  // Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { indigo } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';

import ClaimsAdministratorServices from '../../../../../services/claims-administrator/claims-administrator.services';
import AttorneyManageInfo from '../../../../claims-administrator/attorney-manage-info.component';

const EdeAttorney = ({ formik, currentUser, ...otherProps }) => {
  const [attorneyInfo, setAttorneyInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
  });

  const [attorneyList, setAttorneyList] = useState([]);
  const [open, setOpen] = useState(false);

  const getAttorneys = () => {
    ClaimsAdministratorServices.getClaimsAdministratorList('ATTORNEY')
      .then((response) => {
        setAttorneyList(response.data);
      })
      .catch();
  };

  const handleClose = () => {
    setOpen(false);
    setAttorneyInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAttorneyInfo({
      ...attorneyInfo,
      [name]: value,
    });
  };

  const createAttorney = () => {
    const { caEmail, caName, caPhone, caFax, caAddress } = attorneyInfo;

    const attorneyPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      title: 'ATTORNEY',
      fax: caFax,
      address: caAddress,
      userId: currentUser.id,
    };

    ClaimsAdministratorServices.createClaimsAdministrator(attorneyPayload).then(
      (response) => {
        window.alert(response.data.message);
        getAttorneys();
        handleClose();
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    createAttorney();
  };

  useEffect(() => {
    getAttorneys();
  }, []);

  return (
    <>
    {/*   <Grid item md={3} component={Box} bgcolor={indigo[50]}>
        <Paper
          elevation={0}
          square
          p={0.5}
          component={Box}
          bgcolor={indigo[50]}
        > */}
          <CardHeader
            title="Attorney"
            color="text.secondary"
            p={1}
            component={Box}
            action={
              <Tooltip title="Add Attorney">
                <IconButton
                  // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                  aria-label="Add Attorney"
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <Autocomplete
            name="attorney"
            value={formik.values.attorney}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={attorneyList}
            onChange={(event, value) => {
              formik.setFieldValue('attorney', value);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Attorney" />
            )}
            size="small"
          />
        {/* </Paper>
      </Grid> */}

      <AttorneyManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        attorneyInfo={attorneyInfo}
        manageType="ADD"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EdeAttorney);
