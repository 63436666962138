import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MessageServices from "../../services/message/message.services";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import EmployeeServices from "../../services/employee/employee.services";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";

function MessageSendingStakeHolders({
  currentUser,
  empDetails,
  open,
  handleMessagesClose,
  ...otherProps
}) {
  const [messageInfo, setMessageInfo] = useState({
    miId: null,
    miReceiversUser: null,
    miSenderUser: currentUser.id,
    miMessage: "",
  });
  const { miReceiversUser, miMessage } = messageInfo;
  const [stakeholdersList, setStakeholdersList] = useState([]);
  const [stakeHolder, setStakeHolder] = useState([]);
  const getStakeholdersList = (empId) => {
    EmployeeServices.getStakeholders(empId)
      .then((response) => {
        setStakeholdersList(response.data);
      })
      .catch();
  };

  const handleUserListAutoCompleteChange = (event, value) => {
    setStakeHolder(value);
  };

  const handleMessageChange = (event) => {
    const { name, value } = event.target;
    setMessageInfo({
      ...messageInfo,
      [name]: value,
    });
  };

  const handleMessageSubmit = (event) => {
    event.preventDefault();
    const { miReceiversUser, miSenderUser, miMessage } = messageInfo;
    const messagePayload = {
      senderUser: { id: miSenderUser },
      receiversList: stakeHolder,
      message: miMessage,
    };
    MessageServices.sendMessagesToStakeHolders(messagePayload).then((res) => {
      alert(res.data.response);
      setMessageInfo({
        ...messageInfo,
        miMessage: "",

      });
    });
  };
  const handleReset = (event) => {
      event.preventDefault();
        handleMessagesClose();
    setMessageInfo({
      ...messageInfo,
      miReceiversUser: null,
      miMessage: "",
    });

  };
  useEffect(() => {
    if (empDetails !== undefined) {
      getStakeholdersList(empDetails.id);
    }
  }, [empDetails]);
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleReset}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Compose Message</DialogTitle>
        <DialogContent>
          <form id="send-messages" onSubmit={handleMessageSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  name="stakeHolder"
                  id="stakeHolders-id"
                  options={stakeholdersList}
                  getOptionSelected={(option, value) =>
                    option.userId === value.userId
                  }
                  getOptionLabel={(option) =>
                    option.name + " - " + option.providerType
                  }
                  onChange={handleUserListAutoCompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Stake Holders"
                      placeholder="Select"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="miMessage"
                  label="Message (Max 255 characters)"
                  multiline
                  rowsMax={4}
                  fullWidth
                  value={miMessage}
                  onChange={handleMessageChange}
                />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={handleReset}
              >
                Close
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(MessageSendingStakeHolders);
