import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import {  ifNullAndUndefined } from '../../../../utils/common-utils';
// import { ROLE } from '../../../../utils/settings';

import { CardHeader, Box, Grid, Paper } from '@material-ui/core';
// import { green } from '@material-ui/core/colors';

const EDEPrimaryTP = ({ formik, currentUser, ...otherProps }) => {
  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={green[50]}
      bgcolor="#fffde7"
      >
        <CardHeader
          title="Primary TP"
          subheader={
            <>
              <Box fontWeight={600}>
                {ifNullAndUndefined(formik.values.ptp.firstName)
                  ? ''
                  : formik.values.ptp.firstName}

                {ifNullAndUndefined(formik.values.ptp.lastName)
                  ? ''
                  : ` ${formik.values.ptp.lastName}`}
              </Box>
              <Grid container>
                {ifNullAndUndefined(formik.values.ptp.specialty) ? (
                  <Grid item></Grid>
                ) : (
                  <>
                    <Grid item>Speciality: {formik.values.ptp.specialty}</Grid>
                  </>
                )}
              </Grid>
            </>
          }
          color="text.secondary"
          component={Box}
        />
      </Paper>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEPrimaryTP);
