import { UserActionTypes } from './user.types';

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setUserPic = (profilePic) => ({
  type: UserActionTypes.SET_USER_PIC,
  payload: profilePic,
});
