import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import DashboardCalendar from '../common/dashboard-calendar.component';
import CalendarServices from '../../services/calendar/calendar.services';
import moment from 'moment';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { FE_TELEHEALTHMEETING_URL } from '../../utils/strings';
import {
  Typography,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Divider,
  Grid,
  Box,
  ListItemText,
  List,
  ListItem,
  Fab,
  ListItemSecondaryAction,
  Tooltip,
  Snackbar,
  IconButton,
} from '@material-ui/core';

import EventIcon from '@material-ui/icons/Event';

import EmployerAvatar from '../../images/avatar1.png';
import PhysicianAvatar from '../../images/avatar2.png';
import ClaimsAdjAvatar from '../../images/avatar3.png';
import AttorneyAvatar from '../../images/avatar4.png';
import NurseManagerAvatar from '../../images/avatar5.png';

const Dashboard = (props) => {
  const [openManageEvent, setOpenManageEvent] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [events, setEvents] = useState([]);

 const headerToolbar = {
   center: "dayGridMonth,listMonth",
 };

  const handleEventClose = () => {
    fetchEventListForCalendar(props.currentUser);
    setOpenManageEvent(false);
  };

  const fetchEventListForCalendar = (user) => {
    CalendarServices.getEventList(user.id)
      .then((response) => {
        if (response && response.data) {
          setEvents(response.data);
        }
      })
      .catch();
  };

  useEffect(() => {
    fetchEventListForCalendar(props.currentUser);
  }, [props.currentUser]);

  return (
    <>
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item md={4}>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Employer" src={EmployerAvatar} />
                </ListItemAvatar>
                <ListItemText
                  primary="Employer"
                  secondary="This is where the patient works and was injured at location to seek care of
his/her injury."
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Physician" src={PhysicianAvatar} />
                </ListItemAvatar>
                <ListItemText
                  primary="Physician"
                  secondary="These are the providers involved in the care of a patient. These could be
primary care physicians, ancillary care providers including psychologists, chiropractors,
acupuncturists and specialists like orthopedic surgeons."
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Claims Administrator" src={ClaimsAdjAvatar} />
                </ListItemAvatar>
                <ListItemText
                  primary="Claims Administrator"
                  secondary="A claims administrator investigates worker's compensation claims to
determine whether the claimant can receive care. Claims administrators handle many cases,
answer many telephone calls and emails, and sift through volumes of paperwork."
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Nurse Case Manager" src={NurseManagerAvatar} />
                </ListItemAvatar>
                <ListItemText
                  primary="Nurse Case Manager"
                  secondary="A nurse case manager is responsible for helping an
injured worker to obtain the medical care that he or she needs."
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Attorney" src={AttorneyAvatar} />
                </ListItemAvatar>
                <ListItemText
                  primary="Attorney"
                  secondary="Works towards getting good care keeping the patient interests in mind."
                />
              </ListItem>
            </List>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item md={4}>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
                listPlugin,
              ]}
              height="auto"
              dayMaxEvents="true"
              themeSystem="bootstrap"
              initialView="dayGridMonth"
              events={events}
              headerToolbar={headerToolbar}
              selectable="true"
              // dateClick={function (info) {
              //   setEvent({
              //     ...event,
              //     start:
              //       moment(info.dateStr).format("YYYY-MM-DD") +
              //       "T" +
              //       moment().format("HH:mm"),
              //   });
              // }}
              // select={function (info) {
              //   alert("selected " + info.startStr + " to " + info.endStr);
              // }}
              // eventClick={handleEventClick}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />

          <Grid item md={3}>
            <Typography variant="h6" gutterBottom>
              Your appointments ({`${events.length}`})
            </Typography>
            <List>
              {
                <ListItem>
                  <ListItemSecondaryAction>
                    <Tooltip title="Add Event">
                      <Fab
                        color="primary"
                        onClick={() => setOpenManageEvent(true)}
                      >
                        <EventIcon />
                      </Fab>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              }
              {events.length !== 0 && (
                <>
                  {events.map((event, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        {event.telehealthCheck ? (
                          <Tooltip title="Join Video Call">
                            <IconButton
                              style={{ color: event.color, fontSize: 28 }}
                              edge="end"
                              aria-label="TELEHEALTH_MEETING"
                              onClick={() =>
                                // moment(event.start).isBefore(moment(), "day")
                                //   ? alert("Can not access meeting older then today.")
                                //   : window.open(
                                //       `${FE_TELEHEALTHMEETING_URL}/` +
                                //         event.encodedCalenderEventId
                                //     )
                                event.color === "red"
                                  ? window.open(
                                      `${FE_TELEHEALTHMEETING_URL}/publisher/` +
                                        event.encodedCalenderEventId
                                    )
                                  : window.open(
                                      `${FE_TELEHEALTHMEETING_URL}/subscriber/` +
                                        event.encodedCalenderEventId
                                    )
                              }
                            >
                              <VideoCallIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </ListItemIcon>

                      <ListItemText
                        primary={event.title}
                        secondary={moment(event.start).format(
                          "YYYY-MM-DD hh:mm a"
                        )}
                      />
                    </ListItem>
                  ))}
                </>
              )}
            </List>
          </Grid>
        </Grid>
      </Box>

      {openManageEvent && (
        <DashboardCalendar
          openManageEvent={openManageEvent}
          handleEventClose={handleEventClose}
          loginUser={props.currentUser}
        />
      )}

      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message="Both title and date are required"
      />
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Dashboard);
