import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../../redux/user/user.selectors';

import {
  TextField,
  Tooltip,
  IconButton,
  CardHeader,
  Box,
  // Grid,
  // Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { teal } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';

import ClaimsExaminerManageInfo from '../../../../claims-administrator/claims-examiner-manage-info.component';
import ClaimsAdministratorServices from '../../../../../services/claims-administrator/claims-administrator.services';
import InsuranceCompanyServices from '../../../../../services/insurance-company/insurance-company.services';

const EdeClaimExaminer = ({ formik, currentUser, ...otherProps }) => {
  const [claimsExaminerInfo, setClaimsExaminerInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
    caInsuranceCompanies: [],
  });

  const [claimsExaminerList, setClaimsExaminerList] = useState([]);
  const [open, setOpen] = useState(false);
  const [insuranceList, setInsuranceList] = useState([]);

  const getClaimsExaminers = () => {
    ClaimsAdministratorServices.getClaimsAdministratorList('CLAIMEXAMINER')
      .then((response) => {
        setClaimsExaminerList(response.data);
      })
      .catch();
  };

  const getInsuranceCompanies = () => {
    InsuranceCompanyServices.getInsuranceCompanyList()
      .then((response) => {
        setInsuranceList(response.data);
      })
      .catch();
  };

  const handleClose = () => {
    setOpen(false);
    setClaimsExaminerInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
      caInsuranceCompanies: [],
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClaimsExaminerInfo({
      ...claimsExaminerInfo,
      [name]: value,
    });
  };

  const createClaimsExaminer = () => {
    const { caEmail, caName, caPhone, caFax, caAddress, caInsuranceCompanies } =
      claimsExaminerInfo;

    const claimsExaminerPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      fax: caFax,
      title: 'CLAIMEXAMINER',
      address: caAddress,
      userId: currentUser.id,
      insuranceCompanyList: caInsuranceCompanies.map((item) => ({
        id: item,
      })),
    };

    ClaimsAdministratorServices.createClaimsAdministrator(
      claimsExaminerPayload
    ).then((response) => {
      window.alert(response.data.message);
      getClaimsExaminers();
      handleClose();
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    createClaimsExaminer();
  };

  useEffect(() => {
    getClaimsExaminers();
  }, []);

  return (
    <>
     {/*  <Grid item md={3} component={Box} bgcolor={teal[50]}>
        <Paper elevation={0} square p={0.5} component={Box} bgcolor={teal[50]}> */}
          <CardHeader
            title="Claims Administrator"
            color="text.secondary"
            p={1}
            component={Box}
            action={
              <Tooltip title="Add Claims Administrator">
                <IconButton
                  // disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                  aria-label="Add Claims Administrator"
                  onClick={() => {
                    setOpen(true);
                    getInsuranceCompanies();
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <Autocomplete
            name="claimExaminer"
            value={formik.values.claimExaminer}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={claimsExaminerList}
            onChange={(event, value) => {
              formik.setFieldValue('claimExaminer', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Claims Administrator"
              />
            )}
            size="small"
          />
       {/*  </Paper>
      </Grid> */}
      <ClaimsExaminerManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        claimsExaminerInfo={claimsExaminerInfo}
        insuranceList={insuranceList}
        manageType="ADD"
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EdeClaimExaminer);
