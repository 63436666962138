import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

function ClaimsExaminerManageInfo({
    open,
    handleClose,
    handleChange,
    handleSubmit,
    claimsExaminerInfo,
    insuranceList,
    manageType,
    ...otherProps
  }) {
    const {
        caEmail,
        caName,
        caPhone,
        caFax,
        caAddress,
        caInsuranceCompanies,
    } = claimsExaminerInfo;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {" "}
            {manageType === "EDIT"
              ? "Manage Claims Administrator"
              : "Create Claims Administrator"}
          </DialogTitle>
          <DialogContent>
            <form id="claimsExaminer_manage_form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="caName"
                    label="Name"
                    type="text"
                    fullWidth
                    value={caName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="caEmail"
                    label="Email"
                    type="text"
                    fullWidth
                    value={caEmail}
                    onChange={handleChange}
                    disabled={manageType === "EDIT"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="caPhone"
                    label="Phone"
                    type="text"
                    fullWidth
                    value={caPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="caFax"
                    label="Fax"
                    type="text"
                    fullWidth
                    value={caFax}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caAddress"
                    label="Address"
                    type="text"
                    fullWidth
                    value={caAddress}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="ca-insurance-select-label">
                      Insurance
                    </InputLabel>
                    <Select
                      labelId="ca-insurance-select-label"
                      id="ca-insurance-select"
                      name="caInsuranceCompanies"
                      multiple
                      value={caInsuranceCompanies}
                      onChange={handleChange}
                    >
                      <MenuItem value="">None</MenuItem>
                      {insuranceList != null && insuranceList.length > 0
                        ? insuranceList.map((insurance) => (
                            <MenuItem value={insurance.id} key={insurance.id}>
                              {insurance.name}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  export default ClaimsExaminerManageInfo;