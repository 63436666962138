import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import moment from "moment";
import employeeServices from "../../services/employee/employee.services";
import EmployeeService from "../../services/employee/employee.services";
import CalendarServices from "../../services/calendar/calendar.services";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

const DashboardCalendar = ({
  openManageEvent,
  handleEventClose,
  loginUser,
  ...otherProps
}) => {
  const [stakeholdersList, setStakeholdersList] = useState([]);
  const [stakeHolder, setStakeHolder] = useState([]);
  const [event, setEvent] = useState({
    title: "",
    start: "",
    allDay: false,
    // textColor: "white",
    // borderColor: "black",
    //  color: "green", // an option!
  });
  const [telehealthCheck, setTelehealthCheck] = useState(true);
  const [editEvent, setEditEvent] = useState(false);
  const [editEventId, setEditEventId] = useState(0);
  const [events, setEvents] = useState([]);
  const [employees,setemployees]=useState([])
  const [empid,setempid]=useState()
  const [selectemp,setselectemp]=useState("")


  const getEmployees = () => {
    if (loginUser.role === 'ADMIN') {
      EmployeeService.getEmployeeList().then((response) => {
        setemployees(response.data);
      }).catch();
    } else {
      EmployeeService.getEmployeeListByUser(loginUser.id).then((response) => {
        setemployees(response.data);
      }).catch();
    }
  };

const handlestakeholder=(item)=>{
  if(item!==undefined)
  {
    setempid(item.employee.id)
  }
  
}

const getstakelist=()=>{
  employeeServices.getStakeholders(empid).then(res=>{
    setStakeholdersList(res.data)
  })
}

useEffect(()=>{
  getstakelist()
},[empid])


useEffect(()=>{
  getEmployees();

},[])



  const headerToolbar = {
    center: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
  };
  const handleDoneEventClick = (e) => {
    // setEvents([...events, event]);
    if (event.title === "") {
      alert("Enter Title.");
      return;
    }

    if (event.start === "") {
      alert("Select date time.");
      return;
    }
  
    if (stakeHolder.length === 0) {
      alert("Select atleast one Reciever");
      return;
    }
    CalendarServices.createEvent({
      listOfReciever: stakeHolder,
      telehealthCheck: telehealthCheck,
      start: event.start,
      loginUser: loginUser,
      title: event.title,
    }).then((response) => {
      fetchEventListForCalendar(loginUser);
      handleCalenderEventClose();
      alert(response.data.message);
    });
    e.preventDefault();
  };
  const handleEditEventClick = (e) => {
    // setEvents([...events, event]);
    if (event.title === "") {
      alert("Enter Title.");
      return;
    }
    if (event.start === "") {
      alert("Select date time.");
      return;
    }
    if (stakeHolder.length === 0) {
      alert("Select atleast one Reciever");
      return;
    }

    CalendarServices.editEvent({
      listOfReciever: stakeHolder,
      telehealthCheck: telehealthCheck,
      start: event.start,
      loginUser: loginUser,
      title: event.title,
    },editEventId).then((response) => {
      fetchEventListForCalendar(loginUser);
      handleCalenderEventClose();
      alert(response.data.message);
    });
    e.preventDefault();
  };
  const handleEventClick = (info) => {
    if (info.event._def.ui.backgroundColor !== "green") {
      setEditEvent(true);
      fetchCalenderEventById(info.event.extendedProps.calendereventId);
    }
  };
  const handleEventChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };
  const handleCheckBoxEventChange = (e) => {
    setTelehealthCheck(e.target.checked);
  };
  const handleUserListAutoCompleteChange = (event, value) => {
    setStakeHolder(value);
  };
  // const fetchStakeholdersForCalendar = (user) => {
  //   employeeServices.getStakeholdersForCalendar(user.id).then((response) => {
  //     if (response && response.data) {
  //       setStakeholdersList(response.data);
  //     }
  //   }).catch();
  // };
  const fetchEventListForCalendar = (user) => {
    CalendarServices.getEventList(user.id)
      .then((response) => {
        if (response && response.data) {
          setEvents(response.data);
        }
      })
      .catch();
  };
  const fetchCalenderEventById = (calId) => {
    setEditEventId(calId);
    CalendarServices.getCalenderEventById(calId)
      .then((response) => {
        if (response && response.data) {
            setEvent({
              ...event,
              title: response.data.title,
              start: response.data.start,
            });
            setTelehealthCheck(response.data.telehealthCheck);
            setStakeHolder(response.data.listOfReciever);
         
        
        }
      })
      .catch();
  };
  const handleCalenderEventClose = () => {
    setEditEvent(false);
         setEvent({
           ...event,
           title: "",
           start: "",
         });
         setTelehealthCheck(true);
         setStakeHolder([]);
  };
  
  useEffect(() => {
    // fetchStakeholdersForCalendar(loginUser);
    fetchEventListForCalendar(loginUser);
  }, [loginUser]);



  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={openManageEvent}>
        <DialogTitle>Meeting</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                label="Title"
                name="title"
                value={event.title}
                type="text"
                margin="dense"
                autoFocus
                fullWidth
                onChange={handleEventChange}
              />



              <TextField
                label="Date"
                name="start"
                value={event.start}
                type="datetime-local"
                margin="dense"
                fullWidth
                onChange={handleEventChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              
{/* working on employee select part */}
<br />
<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
  <Select 
  fullWidth
  value={selectemp}
  onChange={(e)=>setselectemp(e.target.value)}
 >
  <MenuItem disabled></MenuItem>
   {
     employees.length>0?
     employees.map((item)=>{
       return(
         <MenuItem key={item.employee.id} value={item.employee.user.userName} onClick={()=>handlestakeholder(item)}>{item.employee.user.userName}</MenuItem>
       )
     }):<MenuItem disabled>Loading Employees...</MenuItem>
   }
   

 </Select>


</FormControl>

              <Autocomplete
                multiple
                filterSelectedOptions
                name="stakeHolder"
                id="stakeHolders-id"
                options={stakeholdersList}
                value={stakeHolder.length > 0 ? stakeHolder : []}
                getOptionSelected={(option, value) =>
                  option.userId === value.userId
                }
                getOptionLabel={(option) =>
                  option.name + " - " + option.providerType
                }
                onChange={handleUserListAutoCompleteChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="To"
                    placeholder="Select"
                  />
                )}
              />
              <FormControlLabel
                name="telehealthCheck"
                checked={telehealthCheck}
                control={<Checkbox color="primary" />}
                label="Set Video Conference"
                labelPlacement="end"
                onChange={handleCheckBoxEventChange}
              />
              <DialogActions>
                <Button
                  onClick={() => {
                    handleCalenderEventClose();

                    handleEventClose();
                  }}
                >
                  Close
                </Button>
                {editEvent ? (
                  <Button onClick={handleEditEventClick} color="primary">
                    Edit
                  </Button>
                ) : (
                  <Button onClick={handleDoneEventClick} color="primary">
                    Create
                  </Button>
                )}
              </DialogActions>
            </Grid>
            <Grid item md>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  listPlugin,
                ]}
                height="auto"
                dayMaxEvents="true"
                themeSystem="bootstrap"
                initialView="dayGridMonth"
                events={events}
                headerToolbar={headerToolbar}
                selectable="true"
                dateClick={function (info) {
                  setEvent({
                    ...event,
                    start:
                      moment(info.dateStr).format("YYYY-MM-DD") +
                      "T" +
                      moment().format("HH:mm"),
                  });
                }}
                // select={function (info) {
                //   alert("selected " + info.startStr + " to " + info.endStr);
                // }}
                eventClick={handleEventClick}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default DashboardCalendar;
