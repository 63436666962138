import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@material-ui/core";
import employeeServices from "../../../../services/employee/employee.services";
function InterpretersAndTransporterManageInfo({
  open,
  handleClose,
  venType,
fetchVendors,
  ...otherProps
}) {
  const [vendor, setVendor] = useState({
    name: "",
    address: "",
    phone: "",
    vendorType:"",
  });
  const handleChangeVendor = (event) => {
    const { name, value } = event.target;
    setVendor({
      ...vendor,
      [name]: value,
    });
  };
  const handleReset = () => {
    setVendor({
      ...vendor,
      name: "",
      address: "",
      phone: "",
    });
  };
  const handleSubmitVendor = (event) => {
    if (vendor.name === "") {
      window.alert("Enter name.");
      return;
    }
    if (vendor.address === "") {
      window.alert("Enter address");
      return;
    }
    employeeServices.addVendorlookup(vendor).then((response) => {
      window.alert(response.data.message);
    //  fetchVendors(venType);
      handleReset();
    });
    event.preventDefault();
  };
    useEffect(() => {
      setVendor({
        ...vendor,
        vendorType: venType,
      });
    }, [venType]);
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {venType === "interpreters"
            ? "Create Interpreters"
            : "Create Transporters"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={vendor.name}
                onChange={handleChangeVendor}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="address"
                label="Address"
                type="text"
                fullWidth
                value={vendor.address}
                onChange={handleChangeVendor}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="phone"
                label="Phone"
                type="text"
                fullWidth
                value={vendor.phone}
                onChange={handleChangeVendor}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={function (event) {
                handleClose();
                handleReset();
              }}
            >
              Close
            </Button>
            <Button
              onClick={handleSubmitVendor}
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default InterpretersAndTransporterManageInfo;
