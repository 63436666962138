import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Paper,
} from '@material-ui/core';

function UroManageInfo({
  open,
  handleClose,
  handleChange,
  handleSubmit,
  uroInfo,
  manageType,
  ...otherProps
}) {
  const {
    urName='',
    urEmail='',
    urPhone='',
    urTitle='',
    urFax='',
    urAddress='',
  } = uroInfo;

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {manageType === "EDIT" ? "Manage Uro" : "Create Uro"}
        </DialogTitle>
        <DialogContent>
          <form id="uro_maanage_form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <Grid container spacing={1}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="urTitle"
                          label="Title"
                          type="text"
                          fullWidth
                          value={urTitle}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="urName"
                          label="Name"
                          type="text"
                          fullWidth
                          value={urName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="emailid"
                          margin="dense"
                          name="urEmail"
                          label="Email"
                          type="email"
                          fullWidth
                          value={urEmail}
                          onChange={handleChange}
                          disabled={manageType === "EDIT"}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="urPhone"
                          label="Phone"
                          type="text"
                          fullWidth
                          value={urPhone}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <Grid container spacing={1}>
                      
                  
                      <Grid item md={12} xs={12}>
                        <TextField
                          margin="dense"
                          name="urFax"
                          label="Fax"
                          type="text"
                          fullWidth
                          value={urFax}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          margin="dense"
                          name="urAddress"
                          label="Address"
                          type="text"
                          fullWidth
                          value={urAddress}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UroManageInfo;
