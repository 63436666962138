import axios from 'axios';

import {EMPLOYER_API_URL } from '../../utils/settings';

class EmployerServices {
  getEmployerList() {
    return axios.get(`${EMPLOYER_API_URL}/fetchemployers`);
  }

  createEmployer(data) {
    return axios({
      method: 'POST',
      url: `${EMPLOYER_API_URL}/addemployer`,
      data,
    });
  }

  updateEmployer(data) {
    return axios({
      method: 'POST',
      url: `${EMPLOYER_API_URL}/updateemployer`,
      data,
    });
  }
}

export default new EmployerServices();
