import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';

import {
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CardHeader,
  TextField,
  Box,
  Grid,
  Paper,
  Snackbar,
  Button,
} from '@material-ui/core';
// import { teal } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';
import FlagIcon from '@material-ui/icons/Flag';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import HistoryIcon from '@material-ui/icons/History';

import LineGraph from '../line-graph.component';
import WorkStatusHistory from '../work-status-history.component';
import employeeServices from '../../../../services/employee/employee.services';

const EDEWorkStatus = ({
  formik,
  currentUser,
  employeeDetails,
  setFlagCheck,
  ...otherProps
}) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [openWorkStatusHistory, setOpenWorkStatusHistory] = useState(false);
  const [workStatusHistoryList, setWorkStatusHistory] = useState([]);
  const [workStatusChart, setWorkStatusChart] = useState([]);
  const [openLineGraph, setOpenLineGraph] = useState(false);

  const handleHistoryWorkStatus = (value) => {
    employeeServices
      .getWorkStatusHistoryList(
        employeeDetails.employee.id,
        employeeDetails.claimNumber
      )
      .then((response) => {
        if (response && response.data) {
          setWorkStatusHistory(response.data);
        }
      })
      .catch();
    setOpenWorkStatusHistory(true);
  };

  const handleFlagWorkStatus = (workStatusDetails) => {
    var msg = updateMessageString(workStatusDetails);
    var updateMessage =
      'This message is to notify work status change for ' +
      employeeDetails.employee.firstName +
      ' ' +
      employeeDetails.employee.lastName +
      " 's" +
      ' Claim Number: ' +
      employeeDetails.claimNumber +
      '  \n' +
      msg +'Employee Id:' +employeeDetails.employee.id;
    var employeeId = employeeDetails.employee.id;
    const messagePayload = {
      senderUser: { id: currentUser.id },
      message: updateMessage,
    };

    if (msg !== '' && msg !== null && msg !== undefined) {
      employeeServices
        .workstatusupdate(messagePayload, employeeId)
        .then((response) => setSnackBarOpen(true));
      setFlagCheck('Y');
    }
  };

  function updateMessageString(workStatusDetails) {
    var workStatusObj = workStatusDetails[0];
    var limitedCarrying = '',
      limitedPulling = '',
      limitedLifting = '',
      limitedPushing = '',
      others = '',
      others1 = '',
      others2 = '';
    var messageToSteckHolders = '';
    if (workStatusObj.limitedCarrying != '') {
      limitedCarrying =
        'Limited Carrying = ' + workStatusObj.limitedCarrying + ', ';
    }
    if (workStatusObj.limitedPulling != '') {
      limitedPulling =
        'Limited Pulling = ' + workStatusObj.limitedPulling + ', ';
    }
    if (workStatusObj.limitedLifting != '') {
      limitedLifting =
        'Limited Lifting = ' + workStatusObj.limitedLifting + ', ';
    }
    if (workStatusObj.limitedPushing != '') {
      limitedPushing =
        'Limited Pushing = ' + workStatusObj.limitedPushing + ', ';
    }
    if (workStatusObj.others != '' && workStatusObj.others != null) {
      others = 'Others = ' + workStatusObj.others + ', ';
    }
    if (workStatusObj.others1 != '' && workStatusObj.others1 != null) {
      others1 = 'Others 1 = ' + workStatusObj.others1 + ', ';
    }
    if (workStatusObj.others2 != '' && workStatusObj.others2 != null) {
      others2 = 'Others 2= ' + workStatusObj.others2;
    }
    messageToSteckHolders =
      limitedCarrying +
      limitedPulling +
      limitedLifting +
      limitedPushing +
      others +
      others1 +
      others2;
    return messageToSteckHolders;
  }

  const handleLineGraph = (event, reason) => {
    employeeServices
      .getChartData(employeeDetails.employee.id)
      .then((response) => {
        if (response && response.data) {
          setWorkStatusChart(response.data);
        }
      })
      .catch();
    setTimeout(() => {
      setOpenLineGraph(true);
    }, 1000);
  };

  const handleSnackBarClose = (event) => {
    setSnackBarOpen(false);
  };

  const handleLineGraphClose = () => {
    setOpenLineGraph(false);
  };

  const handleWorkStatusHistoryClose = () => {
    setOpenWorkStatusHistory(false);
  };

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={teal[50]}
      bgcolor="#e1f5fe"

      >
        <CardHeader
          title="Work Status"
          color="text.secondary"
          component={Box}
          action={
            <>
              <Button
                startIcon={<HistoryIcon />}
                onClick={() => handleHistoryWorkStatus()}
                disabled={ifRole(
                  [ROLE.EMPLOYEE, ROLE.NURSECASEMANAGER, ROLE.ADMIN,ROLE.CLAIMEXAMINER],
                  currentUser.role
                )}
              >
                History
              </Button>

              {employeeDetails.workStatusFlag != 1 ? (
                <Button
                  startIcon={<FlagIcon color="secondary" />}
                  onClick={() =>
                    handleFlagWorkStatus(formik.values.workStatusDetails)
                  }
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.NURSECASEMANAGER, ROLE.ADMIN,ROLE.CLAIMEXAMINER],
                    currentUser.role
                  )}
                >
                  Flag
                </Button>
              ) : (
                <Button
                  startIcon={<FlagIcon style={{ color: green[500] }} />}
                  onClick={() =>
                    handleFlagWorkStatus(formik.values.workStatusDetails)
                  }
                  disabled={ifRole(
                    [ROLE.EMPLOYEE, ROLE.NURSECASEMANAGER, ROLE.ADMIN,ROLE.CLAIMEXAMINER],
                    currentUser.role
                  )}
                >
                  Flag
                </Button>
              )}

              <Button
                startIcon={<TrendingUpIcon />}
                onClick={handleLineGraph}
                disabled={ifRole(
                  [ROLE.EMPLOYEE, ROLE.NURSECASEMANAGER, ROLE.ADMIN,ROLE.CLAIMEXAMINER],
                  currentUser.role
                )}
              >
                Charts
              </Button>
            </>
          }
        />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Current Lifting Ability"
              name={`workStatusDetails[0].limitedLifting`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].limitedLifting
                )
                  ? ""
                  : formik.values.workStatusDetails[0].limitedLifting
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Target Lifting Ability"
              name={`workStatusDetails[0].targetLifting`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].targetLifting
                )
                  ? ""
                  : formik.values.workStatusDetails[0].targetLifting
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
            >
              <InputLabel htmlFor="hrWorkedLifting-select">
                Duration (Hours)
              </InputLabel>
              <Select
                native
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].hoursWorkedLifting
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].hoursWorkedLifting
                }
                onChange={formik.handleChange}
                label="Duration (Hours)"
                inputProps={{
                  name: "workStatusDetails[0].hoursWorkedLifting",
                  id: "hrWorkedLifting-select",
                }}
              >
                <option value="" />
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Accomodate Lifting</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].liftingAcc`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].liftingAcc
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].liftingAcc
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Employer Restriction Response Lifting"
              name={`workStatusDetails[0].liftingErr`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].liftingErr
                )
                  ? ""
                  : formik.values.workStatusDetails[0].liftingErr
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              label="Current Carrying Ability"
              name={`workStatusDetails[0].limitedCarrying`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].limitedCarrying
                )
                  ? ""
                  : formik.values.workStatusDetails[0].limitedCarrying
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Target Carrying Ability"
              name={`workStatusDetails[0].targetCarrying`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].targetCarrying
                )
                  ? ""
                  : formik.values.workStatusDetails[0].targetCarrying
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
            >
              <InputLabel htmlFor="hrWorkedCarrying-select">
                Duration (Hours)
              </InputLabel>
              <Select
                native
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].hoursWorkedCarrying
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].hoursWorkedCarrying
                }
                onChange={formik.handleChange}
                label="Duration (Hours)"
                inputProps={{
                  name: "workStatusDetails[0].hoursWorkedCarrying",
                  id: "hrWorkedCarrying-select",
                }}
              >
                <option value="" />
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Accomodate Carrying</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].carryingAcc`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].carryingAcc
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].carryingAcc
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Employer Restriction Response Carrying"
              name={`workStatusDetails[0].carryingErr`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].carryingErr
                )
                  ? ""
                  : formik.values.workStatusDetails[0].carryingErr
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              label="Current Pulling Ability"
              name={`workStatusDetails[0].limitedPulling`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].limitedPulling
                )
                  ? ""
                  : formik.values.workStatusDetails[0].limitedPulling
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Target Pulling Ability"
              name={`workStatusDetails[0].targetPulling`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].targetPulling
                )
                  ? ""
                  : formik.values.workStatusDetails[0].targetPulling
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
            >
              <InputLabel htmlFor="hrWorkedPulling-select">
                Duration (Hours)
              </InputLabel>
              <Select
                native
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].hoursWorkedPulling
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].hoursWorkedPulling
                }
                onChange={formik.handleChange}
                label="Duration (Hours)"
                inputProps={{
                  name: "workStatusDetails[0].hoursWorkedPulling",
                  id: "hrWorkedPulling-select",
                }}
              >
                <option value="" />
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Accomodate Pulling</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].pullingAcc`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].pullingAcc
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].pullingAcc
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Employer Restriction Response Pulling"
              name={`workStatusDetails[0].pullingErr`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].pullingErr
                )
                  ? ""
                  : formik.values.workStatusDetails[0].pullingErr
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              label="Current Pushing Ability"
              name={`workStatusDetails[0].limitedPushing`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].limitedPushing
                )
                  ? ""
                  : formik.values.workStatusDetails[0].limitedPushing
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Target Pushing Ability"
              name={`workStatusDetails[0].targetPushing`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].targetPushing
                )
                  ? ""
                  : formik.values.workStatusDetails[0].targetPushing
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
            >
              <InputLabel htmlFor="hrWorkedPushing-select">
                Duration (Hours)
              </InputLabel>
              <Select
                native
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].hoursWorkedPushing
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].hoursWorkedPushing
                }
                onChange={formik.handleChange}
                label="Duration (Hours)"
                inputProps={{
                  name: "workStatusDetails[0].hoursWorkedPushing",
                  id: "hrWorkedPushing-select",
                }}
              >
                <option value="" />
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Accomodate Pushing</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].pushingAcc`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].pushingAcc
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].pushingAcc
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Employer Restriction Response Pushing"
              name={`workStatusDetails[0].pushingErr`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].pushingErr
                )
                  ? ""
                  : formik.values.workStatusDetails[0].pushingErr
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Other Ability"
              name={`workStatusDetails[0].others`}
              value={
                ifNullAndUndefined(formik.values.workStatusDetails[0].others)
                  ? ""
                  : formik.values.workStatusDetails[0].others
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Others Accomodation</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].othersAcc`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].othersAcc
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].othersAcc
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Others Employer Restriction Response"
              name={`workStatusDetails[0].othersErr`}
              value={
                ifNullAndUndefined(formik.values.workStatusDetails[0].othersErr)
                  ? ""
                  : formik.values.workStatusDetails[0].othersErr
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Other Ability 1"
              name={`workStatusDetails[0].others1`}
              value={
                ifNullAndUndefined(formik.values.workStatusDetails[0].others1)
                  ? ""
                  : formik.values.workStatusDetails[0].others1
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Others 1 Accomodation</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].othersAcc1`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].othersAcc1
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].othersAcc1
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Others 1 Employer Restriction Response"
              name={`workStatusDetails[0].othersErr1`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].othersErr1
                )
                  ? ""
                  : formik.values.workStatusDetails[0].othersErr1
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Other Ability 2"
              name={`workStatusDetails[0].others2`}
              value={
                ifNullAndUndefined(formik.values.workStatusDetails[0].others2)
                  ? ""
                  : formik.values.workStatusDetails[0].others2
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              component="fieldset"
              disabled={ifRole(
                [
                  ROLE.EMPLOYEE,
                  ROLE.CLAIMEXAMINER,
                  ROLE.NURSECASEMANAGER,
                  ROLE.ADMIN,
                ],
                currentUser.role
              )}
            >
              <FormLabel component="legend">Others 2 Accomodation</FormLabel>
              <RadioGroup
                row
                name={`workStatusDetails[0].othersAcc2`}
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].othersAcc2
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].othersAcc2
                }
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Others 2 Employer Restriction Response"
              name={`workStatusDetails[0].othersErr1`}
              value={
                ifNullAndUndefined(
                  formik.values.workStatusDetails[0].othersErr1
                )
                  ? ""
                  : formik.values.workStatusDetails[0].othersErr1
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              disabled={!ifRole([ROLE.PROVIDER, ROLE.STAFF], currentUser.role)}
            >
              <InputLabel htmlFor="disability-select">Disability</InputLabel>
              <Select
                native
                value={
                  ifNullAndUndefined(
                    formik.values.workStatusDetails[0].disability
                  )
                    ? ""
                    : formik.values.workStatusDetails[0].disability
                }
                onChange={formik.handleChange}
                label="Disability"
                inputProps={{
                  name: "workStatusDetails[0].disability",
                  id: "disability-select",
                }}
              >
                <option value="" />
                <option value="SDI">SDI</option>
                <option value="LTD">LTD</option>
                <option value="SSDI">SSDI</option>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Attach Work Status Document"
              type="file"
              InputLabelProps={{ shrink: true }}
              name={`workStatusDetails[0].workStatusFileupload`}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event) => {
                formik.setFieldValue(
                  "workStatusDetails[0].workStatusFileupload",
                  event.target.files[0]
                );
              }}
              disabled={ifRole([ROLE.EMPLOYEE,ROLE.CLAIMEXAMINER,  ROLE.ADMIN], currentUser.role)}
            />
          </Grid>
        </Grid>
      </Paper>

      <WorkStatusHistory
        workStatusHistoryList={workStatusHistoryList}
        openWorkStatusHistory={openWorkStatusHistory}
        handleWorkStatusHistoryClose={handleWorkStatusHistoryClose}
        handleHistoryWorkStatus={handleHistoryWorkStatus}
      />

      <LineGraph
        openLineGraph={openLineGraph}
        handleLineGraphClose={handleLineGraphClose}
        workStatusChart={workStatusChart}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message="Successfully."
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEWorkStatus);
