import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { ifRole, ifNullAndUndefined } from '../../../../utils/common-utils';
import { ROLE } from '../../../../utils/settings';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import FlagIcon from "@material-ui/icons/Flag";

import {
  Button,
  Tooltip,
  IconButton,
  InputAdornment,
  CardHeader,
  TextField,
  Box,
  Grid,
  Snackbar,
  Paper,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { teal } from '@material-ui/core/colors';
import EmployerServices from '../../../../services/employer/employer.services';
import employeeServices from "../../../../services/employee/employee.services";


const EDEEmployer = ({ formik, currentUser, employeeDetails ,setFlagCheck,...otherProps }) => {
  const [employerList, setEmployerList] = useState([]);
  const [RTWupload,setRTWupload]=useState(false)
  const [snackBarOpen,setSnackBarOpen]=useState(false)

  const getEmployers = () => {
    EmployerServices.getEmployerList().then((response) => {
      setEmployerList(response.data);
    }).catch();
  };

  const handleSnackBarClose=()=>{
    setSnackBarOpen(false)
      }
  const handleRTW=()=>{
    var employeeId= employeeDetails.employee.id
    const messagePayload = {
      senderUser: { id: currentUser.id },
      message: 'This message is to notify RTW File Uploaded for ' +
      employeeDetails.employee.firstName +
      ' ' +
      employeeDetails.employee.lastName +
      " 's" +
      ' Claim Number: ' +
      employeeDetails.claimNumber +
      '  \n' +
      'Employee Id:' +employeeDetails.employee.id
        };
      employeeServices
      .rtwuploadpolicy(messagePayload, employeeId)
      .then((response) => setSnackBarOpen("Notification sent successfully"));
      setFlagCheck('Y');

  }


  useEffect(() => {
    getEmployers();
  }, []);

  return (
    <>
      <Paper elevation={0} square p={0.5} component={Box} 
      // bgcolor={teal[50]}
      bgcolor="#e9fafa"
      >
        <CardHeader
          title="Employer"
          color="text.secondary"
          component={Box}
          action={
            <>
            <Tooltip title="Click on RTW flag to send notification">
            <Button
                  startIcon={<FlagIcon color="secondary"/>}
            onClick={handleRTW}
            disabled={RTWupload===false}            
            >RTW </Button>
            </Tooltip>
              <Tooltip title="Accommodate RTW Policy Upload">
                <label htmlFor="rtw-file-upload">
                  <Button
                    edge="end"
                    component="span"
                    startIcon={<CloudUploadOutlinedIcon />}
                    disabled={ifRole(
                      [ROLE.EMPLOYEE, ROLE.ADMIN],
                      currentUser.role
                    )}
                  >
                    RTW Upload
                  </Button>
                </label>
              </Tooltip>

              <input
                style={{ display: 'none' }}
                id="rtw-file-upload"
                name="jobDetails[0].rtwPolicyfileUpload"
                type="file"
                disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                onChange={(event) => {
                  formik.setFieldValue(
                    'jobDetails[0].rtwPolicyfileUpload',
                    event.target.files[0]
                  );
                  setRTWupload(true)
                }}
              />
              {/* </Button> */}
            </>
          }
        />
        <Grid container spacing={1}>
          <Grid item sm={6} xs={6}>
            <Autocomplete
              name="employer"
              value={formik.values.employer}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                `${option.companyName}`
              }
              options={employerList}
              onChange={(event, value) => {
                formik.setFieldValue("employer", value);
              }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Employer" />
              )}
              size="small"
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Department"
              name={`jobDetails[0].department`}
              value={
                ifNullAndUndefined(formik.values.jobDetails[0].department)
                  ? ''
                  : formik.values.jobDetails[0].department
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Job Title"
              name={`jobDetails[0].jobTitle`}
              value={
                ifNullAndUndefined(formik.values.jobDetails[0].jobTitle)
                  ? ''
                  : formik.values.jobDetails[0].jobTitle
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Upload RU90 / Job Description">
                    <InputAdornment position="end">
                      <label htmlFor="job-description-file">
                        <IconButton
                          // onClick={handleClickShowPassword}
                          disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
                          edge="end"
                          component="span"
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />

            <input
              style={{ display: 'none' }}
              id="job-description-file"
              name="jobDetails[0].fileUpload"
              type="file"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={(event) => {
                formik.setFieldValue(
                  'jobDetails[0].fileUpload',
                  event.target.files[0]
                );
              }}
            />
          </Grid>

          <Grid item sm={6} xs={6}>
            <TextField
              label="Date of Hire"
              name={`jobDetails[0].dateOfHire`}
              value={moment(
                ifNullAndUndefined(formik.values.jobDetails[0].dateOfHire)
                  ? ''
                  : formik.values.jobDetails[0].dateOfHire
              ).format('YYYY-MM-DD')}
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Supervisor"
              name={`jobDetails[0].supervisor`}
              value={
                ifNullAndUndefined(formik.values.jobDetails[0].supervisor)
                  ? ''
                  : formik.values.jobDetails[0].supervisor
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextField
              label="Contact Info"
              name={`jobDetails[0].hrContact`}
              value={
                ifNullAndUndefined(formik.values.jobDetails[0].hrContact)
                  ? ''
                  : formik.values.jobDetails[0].hrContact
              }
              fullWidth
              variant="outlined"
              size="small"
              disabled={ifRole([ROLE.EMPLOYEE, ROLE.ADMIN], currentUser.role)}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message="Notification sent successfully."
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EDEEmployer);
