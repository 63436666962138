import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';

function InsuranceCompanyManageInfo({
    open,
    handleClose,
    handleChange,
    handleSubmit,
    insuranceCompanyInfo,
    manageType,
    ...otherProps
  }) {
    const {
        icName,
        icEmail,
        icPhone,
        icAddress
    } = insuranceCompanyInfo;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {manageType === "EDIT"
              ? "Manage Insurance Company"
              : "Create Insurance Company"}
          </DialogTitle>
          <DialogContent>
            <form id="insurance_company_manage_form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="icName"
                    label="Name"
                    type="text"
                    fullWidth
                    value={icName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="icEmail"
                    label="Email"
                    type="text"
                    fullWidth
                    value={icEmail}
                    onChange={handleChange}
                    disabled={manageType === "EDIT"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="icPhone"
                    label="Phone"
                    type="text"
                    fullWidth
                    value={icPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="icAddress"
                    label="Address"
                    type="text"
                    fullWidth
                    value={icAddress}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  export default InsuranceCompanyManageInfo;