import axios from 'axios';
import { USER_API_URL, VALIDATE_API_URL } from '../../utils/settings';

class CommonServices {
  getUserByEmail(email) {
    return axios.get(`${USER_API_URL}/${email}/fetchuser`);
  }

  getUserListByRole(role) {
      return axios.get(`${USER_API_URL}/${role}/fetchuserbyrole`);
  }

  uploadFile(data) {
    return axios({
      method: 'POST',
      url: `${USER_API_URL}/upload`,
      data,
    });
  }

  getFile(id) {
    return axios.get(`${USER_API_URL}/files/${id}`);
  }

  getUserListForSearch(role) {
    return axios.get(`${USER_API_URL}/usersearch/${role}`);
  }

  fetchPhysicianUserList() {
    return axios.get(`${USER_API_URL}/physiciansearch`);
  }

  fetchPatientUserList() {
    return axios.get(`${USER_API_URL}/patientsearch`);
  }

  fetchClaimsAdministratorList() {
    return axios.get(`${USER_API_URL}/claimsadministrators`);
  }

  PatientProgressNotesfrompdf(data) {
    return axios({
      method: 'POST',
      url: `${USER_API_URL}/PatientProgressNotesfrompdf`,
      data,
    });
  }

  getActionItems(id, type) {
    return axios.get(`${USER_API_URL}/actionitems/${id}/${type}`);
  }

  setActionItemStatus(data) {
    return axios({
      method: 'PUT',
      url: `${USER_API_URL}/actionitem`,
      data,
    });
  }

  registerUser(data) {
    return axios({
      method: 'POST',
      url: `${VALIDATE_API_URL}/register`,
      data,
    });
  }

  changePassword(data) {
    return axios({
      method: 'POST',
      url: `${USER_API_URL}/changepassword`,
      data,
    });
  }
}

export default new CommonServices();
