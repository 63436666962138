import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../redux/user/user.selectors';
import { connect } from 'react-redux';
import { FE_WEBSITE_PAGE_URL } from './strings';
import NotAuthorised from '../components/common/not-authorised';

const AuthenticatedRoute = ({ currentUser, role, path, ...otherProps }) => {
  if (currentUser) {
    if (role === currentUser.role) {
      // it will open only logged in role's URL
      return <Route {...otherProps} />;
    } else {
      return <NotAuthorised />;
    }
  } else {
    return <Redirect to={FE_WEBSITE_PAGE_URL} />;
  }
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(AuthenticatedRoute);
