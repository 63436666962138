import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Paper,
  /* FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input, */
} from '@material-ui/core';

function ProviderManageInfo({
  open,
  handleClose,
  handleChange,
  handleSubmit,
  providerInfo,
  manageType,
  //facilityList,
  ...otherProps
}) {
  const {
    asFirstName,
    asLastName,
    asProTitle,
    asProSpeciality,
    asProType,
    asPreferredName,
    asGender,
    asNpin,
    asStreet1,
    asStreet2,
    asCity,
    asState,
    asCountry,
    asZipCode,
    asEmail,
    asPhone,
    //asFacility,
  } = providerInfo;

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {manageType === "EDIT" ? "Manage Provider" : "Create Provider"}
        </DialogTitle>
        <DialogContent>
          <form id="provider_maanage_form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <Grid container spacing={1}>
                      <Grid item md={5} xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="asFirstName"
                          label="First Name"
                          type="text"
                          fullWidth
                          value={asFirstName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <TextField
                          margin="dense"
                          name="asLastName"
                          label="Last Name"
                          type="text"
                          fullWidth
                          value={asLastName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          margin="dense"
                          name="asProTitle"
                          label="Title"
                          type="text"
                          fullWidth
                          value={asProTitle}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asPreferredName"
                          label="Preferred Name"
                          type="text"
                          fullWidth
                          value={asPreferredName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup
                          row
                          name="asGender"
                          defaultValue="M"
                          value={asGender}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="M"
                            control={<Radio color="primary" />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="F"
                            control={<Radio color="primary" />}
                            label="Female"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          margin="dense"
                          name="asNpin"
                          label="NPIN"
                          type="text"
                          fullWidth
                          value={asNpin}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          margin="dense"
                          name="asProSpeciality"
                          label="Speciality"
                          type="text"
                          fullWidth
                          value={asProSpeciality}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          margin="dense"
                          name="asProType"
                          label="Type"
                          type="text"
                          fullWidth
                          value={asProType}
                          onChange={handleChange}
                        />
                      </Grid>
                      {/*         <Grid item xs={12}>
                        <FormControl required fullWidth>
                          <InputLabel id="facility-select-label">
                            Select Facility
                          </InputLabel>
                          <Select
                            labelId="facility-select-label"
                            id="facility-select"
                            multiple
                            name="asFacility"
                            value={asFacility}
                            onChange={handleChange}
                            input={<Input />}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {
                              ((facilityList != null && facilityList.length > 0) ? (facilityList.map((facility) => (
                                <MenuItem value={facility.id} key={facility.id}>
                                  {facility.facilityName}
                                </MenuItem>
                              ))) : '')
                            }
                          </Select>
                        </FormControl>
                      </Grid> */}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <FormLabel component="legend">Address</FormLabel>

                    <Grid container spacing={1}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asStreet1"
                          label="Street1"
                          type="text"
                          fullWidth
                          value={asStreet1}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asStreet2"
                          label="Unit, Apt., Suite"
                          type="text"
                          fullWidth
                          value={asStreet2}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          margin="dense"
                          name="asCity"
                          label="City"
                          type="text"
                          fullWidth
                          value={asCity}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          margin="dense"
                          name="asState"
                          label="State"
                          type="text"
                          fullWidth
                          value={asState}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          margin="dense"
                          name="asCountry"
                          label="Country"
                          type="text"
                          fullWidth
                          value={asCountry}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          margin="dense"
                          name="asZipCode"
                          label="Zip Code"
                          type="text"
                          fullWidth
                          value={asZipCode}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asEmail"
                          label="Email"
                          type="email"
                          fullWidth
                          value={asEmail}
                          onChange={handleChange}
                          disabled={manageType === "EDIT"}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          margin="dense"
                          name="asPhone"
                          label="Phone"
                          type="tel"
                          fullWidth
                          value={asPhone}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ProviderManageInfo;
