import React from 'react';
import { Redirect } from 'react-router-dom';

import { ROLE } from './settings';
import {
  FE_ADMIN_PAGE_URL,
  FE_ATTORNEY_PAGE_URL,
  FE_CLAIM_EXAMINER_PAGE_URL,
  FE_EMPLOYER_PAGE_URL,
  FE_NURSE_CASE_MANAGER_PAGE_URL,
  FE_PROVIDER_PAGE_URL,
  FE_STAFF_PAGE_URL,
  FE_EMPLOYEE_PAGE_URL,
  FE_URO_PAGE_URL,
} from './strings';

export const ifRole = (rolesArray, currentUserRole) => {
  return rolesArray.indexOf(currentUserRole) !== -1;
};

export const ifNullAndUndefined = (value) => {
  return value === null || value === undefined ? true : false;
};

export const conditionalRedirect = (currentUser, otherwiseRedirectHere) => {
  if (currentUser) {
    switch (currentUser.role) {
      case ROLE.ADMIN:
        return <Redirect to={FE_ADMIN_PAGE_URL} />;
      case ROLE.EMPLOYER:
        return <Redirect to={FE_EMPLOYER_PAGE_URL} />;
      case ROLE.PROVIDER:
        return <Redirect to={FE_PROVIDER_PAGE_URL} />;
      case ROLE.CLAIMEXAMINER:
        return <Redirect to={FE_CLAIM_EXAMINER_PAGE_URL} />;
      case ROLE.ATTORNEY:
        return <Redirect to={FE_ATTORNEY_PAGE_URL} />;
      case ROLE.NURSECASEMANAGER:
        return <Redirect to={FE_NURSE_CASE_MANAGER_PAGE_URL} />;
      case ROLE.STAFF:
        return <Redirect to={FE_STAFF_PAGE_URL} />;
      case ROLE.EMPLOYEE:
        return <Redirect to={FE_EMPLOYEE_PAGE_URL} />;
        case ROLE.URO:
          return <Redirect to={FE_URO_PAGE_URL} />;
      default:
        return '';
    }
  } else {
    return otherwiseRedirectHere;
  }
};

export const a11yProps = (idString, index) => {
  return {
    id: `${idString}-tab-${index}`,
  };
};
