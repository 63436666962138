import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UroManageInfo from './uro-manage-info.component';
import UroList from './uro-list.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import UroServices from '../../services/uro/uro.services';

const Uro = (props) => {
  const [uroInfo, setUroInfo] = useState({
    asEmpId:0,
    asName: '',
    asEmail: '',
    asPhone: '',
    asTitle: '',
    asFax: '',
    asAddress: '',
  });
  const [uroList, setUroList] = useState([]);
  const [open, setOpen] = useState(false);
  const [manageType, setManageType] = useState('ADD');

  const getUros = () => {
    UroServices.getUroList()
      .then((response) => {
        setUroList(response.data);
      })
      .catch();
  };
  
  useEffect(() => {
    getUros();
  }, []);

  const handleClickOpen = (manageType, uroData) => {
    if (manageType === 'EDIT') {
      const {
        id,
        name,
        email,
        phone,
        title,
        fax,
        address,
      } = uroData;

      setUroInfo({
        asEmpId:id,
        asName: name,
        asEmail: email,
        asPhone: phone,
        asTitle: title,
        asFax: fax,
        asAddress: address,
      });
    }

    setOpen(true);
    setManageType(manageType);
  };

  const handleClose = () => {
    setOpen(false);
    setUroInfo({
      asName: '',
      asEmail: '',
      asPhone: '',
      asTitle: '',
      asFax: '',
      asAddress: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUroInfo({
      ...uroInfo,
      [name]: value,
    });
  };

  const createUro = () => {
    const {
      asName,
      asEmail,
      asPhone,
      asTitle,
      asFax,
      asAddress,
    } = uroInfo;

    const uroPayload = {
      name:asName,
      email:asEmail,
      phone:asPhone,
      title:asTitle,
      fax:asFax,
      address:asAddress,
      userId: props.currentUser.id
    };

    UroServices.createUro(uroPayload).then((response) => {
      window.alert(response.data.message);
      getUros();
      handleClose();
    });
  };

  const updateUro = () => {
    const {
      asEmpId,
      asName,
    asEmail,
    asPhone,
    asTitle,
    asFax,
    asAddress,
    } = uroInfo;

    const uroPayload = {
     id:asEmpId,
     name:asName,
     email:asEmail,
     phone:asPhone,
     title:asTitle,
     fax:asFax,
     address:asAddress,
     userId: props.currentUser.id
    };

    UroServices.updateUro(uroPayload).then((response) => {
      window.alert(response.data.message);
      getUros();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (manageType === 'ADD') {
      createUro();
    } else {
      updateUro();
    }
  };

  return (
    <div>
      <Card square>
        <CardHeader
          action={
            <Tooltip title="Add Uro">
              <IconButton
                aria-label="Add Uro"
                onClick={() => handleClickOpen("ADD", "")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
          title="Uro"
        />

        <UroList
          uroList={uroList}
          handleClickOpen={handleClickOpen}
          getUros={getUros}
          props={props}
        />
      </Card>

      <UroManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        uroInfo={uroInfo}
        manageType={manageType}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Uro);
