import React from 'react';
import { Box, Card, CardHeader, Avatar } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { red } from '@material-ui/core/colors';

const NotAuthorised = () => {
  return (
    <Box m={1}>
      <Card>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: red[500] }}>
              <BlockIcon />
            </Avatar>
          }
          title="Not Authorised"
          subheader="You are not authorised to access this URL. Please login with correct user."
        />
      </Card>
    </Box>
  );
};

export default NotAuthorised;
