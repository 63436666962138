import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

function AttorneyManageInfo({
    open,
    handleClose,
    handleChange,
    handleSubmit,
    attorneyInfo,
    manageType,
    ...otherProps
  }) {
    const {
        caEmail,
        caName,
        caPhone,
        caFax,
        caAddress,
        caTitleType,
    } = attorneyInfo;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="attorney-max-width-dialog-title">
            {manageType === "EDIT" ? "Manage Attorney" : "Create Attorney"}
          </DialogTitle>
          <DialogContent>
            <form id="attorney_manage_form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caName"
                    label="Name"
                    type="text"
                    fullWidth
                    value={caName}
                    onChange={handleChange}
                  />
                </Grid>
                {/*     <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="ca-title-select-label">Title</InputLabel>
                                    <Select labelId="ca-title-select-label" id="ca-title-select"
                                        name="caTitle" value={caTitle} onChange={handleChange} >
                                        <MenuItem value="ATTORNEY">ATTORNEY</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caEmail"
                    label="Email"
                    type="text"
                    fullWidth
                    value={caEmail}
                    onChange={handleChange}
                    disabled={manageType === "EDIT"}
                  />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="titleType-select-label">Type</InputLabel>
                      <Select
                        labelId="titleType-select-label"
                        id="titleType-select"
                        name="caTitleType"
                        value={caTitleType}
                        onChange={handleChange}
                      >
                        <MenuItem value="Applicant">Applicant Attorney</MenuItem>
                        <MenuItem value="Defense">Defense Attorney</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caPhone"
                    label="Phone"
                    type="text"
                    fullWidth
                    value={caPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caFax"
                    label="Fax"
                    type="text"
                    fullWidth
                    value={caFax}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="caAddress"
                    label="Address"
                    type="text"
                    fullWidth
                    value={caAddress}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  export default AttorneyManageInfo;