import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EmployerManageInfo from './employer-manage-info.component';
import EmployerList from './employer-list.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import EmployerServices from '../../services/employer/employer.services';

const Employer = (props) => {
  const [employerInfo, setEmployerInfo] = useState({
    asEmpId:0,
    asName: '',
    asEmail: '',
    asPhone: '',
    asCompanyName:'',
    asTitle: '',
    asFax: '',
    asAddress: '',
  });
  const [employerList, setEmployerList] = useState([]);
  const [open, setOpen] = useState(false);
  const [manageType, setManageType] = useState('ADD');

  const getEmployers = () => {
    EmployerServices.getEmployerList()
      .then((response) => {
        setEmployerList(response.data);
      })
      .catch();
  };
  
  useEffect(() => {
    getEmployers();
  }, []);

  const handleClickOpen = (manageType, employerData) => {
    if (manageType === 'EDIT') {
      const {
        id,
        name,
        email,
        phone,
        companyName,
        title,
        fax,
        address,
      } = employerData;

      setEmployerInfo({
        asEmpId:id,
        asName: name,
        asEmail: email,
        asPhone: phone,
        asCompanyName:companyName,
        asTitle: title,
        asFax: fax,
        asAddress: address,
      });
    }

    setOpen(true);
    setManageType(manageType);
  };

  const handleClose = () => {
    setOpen(false);
    setEmployerInfo({
      asName: '',
      asEmail: '',
      asPhone: '',
      asCompanyName:'',
      asTitle: '',
      asFax: '',
      asAddress: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmployerInfo({
      ...employerInfo,
      [name]: value,
    });
  };

  const createEmployer = () => {
    const {
      asName,
      asEmail,
      asPhone,
      asCompanyName,
      asTitle,
      asFax,
      asAddress,
    } = employerInfo;

    const employerPayload = {
      name:asName,
      email:asEmail,
      phone:asPhone,
      companyName:asCompanyName,
      title:asTitle,
      fax:asFax,
      address:asAddress,
      userId: props.currentUser.id
    };

    EmployerServices.createEmployer(employerPayload).then((response) => {
      window.alert(response.data.message);
      getEmployers();
      handleClose();
    });
  };

  const updateEmployer = () => {
    const {
      asEmpId,
      asName,
    asEmail,
    asPhone,
    asCompanyName,
    asTitle,
    asFax,
    asAddress,
    } = employerInfo;

    const employerPayload = {
     id:asEmpId,
     name:asName,
     email:asEmail,
     phone:asPhone,
     companyName:asCompanyName,
     title:asTitle,
     fax:asFax,
     address:asAddress,
     userId: props.currentUser.id
    };

    EmployerServices.updateEmployer(employerPayload).then((response) => {
      window.alert(response.data.message);
      getEmployers();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (manageType === 'ADD') {
      createEmployer();
    } else {
      updateEmployer();
    }
  };

  return (
    <div>
      <Card square>
        <CardHeader
          action={
            <Tooltip title="Add Employer">
              <IconButton
                aria-label="Add Employer"
                onClick={() => handleClickOpen("ADD", "")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
          title="Employer"
        />

        <EmployerList
          employerList={employerList}
          handleClickOpen={handleClickOpen}
          getEmployers={getEmployers}
          props={props}
        />
      </Card>

      <EmployerManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        employerInfo={employerInfo}
        manageType={manageType}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Employer);
