import axios from 'axios';

import {URO_API_URL } from '../../utils/settings';

class UroServices {
  getUroList() {
    return axios.get(`${URO_API_URL}/fetchuros`);
  }

  createUro(data) {
    return axios({
      method: 'POST',
      url: `${URO_API_URL}/adduro`,
      data,
    });
  }

  updateUro(data) {
    return axios({
      method: 'POST',
      url: `${URO_API_URL}/updateuro`,
      data,
    });
  }
}

export default new UroServices();
