import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import NurseCaseManagerManageInfo from './nurse-case-manager-manage-info.component';
import NurseCaseManagerList from './nurse-case-manager-list.component';
import ClaimsAdministratorServices from '../../services/claims-administrator/claims-administrator.services';

const NurseCaseManager = (props) => {
  const [nurseCaseManagerInfo, setNurseCaseManagerInfo] = useState({
    caId: null,
    caEmail: '',
    caFax: '',
    caName: '',
    caPhone: '',
    caAddress: '',
  });

  const [nurseCaseManagerList, setNurseCaseManagerList] = useState([]);
  const [open, setOpen] = useState(false);
  const [manageType, setManageType] = useState('ADD');

  const getNurseCaseManagers = () => {
    ClaimsAdministratorServices.getClaimsAdministratorList('NURSECASEMANAGER')
      .then((response) => {
        setNurseCaseManagerList(response.data);
      })
      .catch();
  };

  useEffect(() => {
    getNurseCaseManagers();
  }, []);

  const handleClickOpen = (manageType, caData) => {
    if (manageType === 'EDIT') {
      const { id, email, name, phone, fax, address } = caData;

      setNurseCaseManagerInfo({
        caId: id,
        caEmail: email,
        caName: name,
        caPhone: phone,
        caFax: fax,
        caAddress: address,
      });
    }

    setOpen(true);
    setManageType(manageType);
  };

  const handleClose = () => {
    setOpen(false);
    setNurseCaseManagerInfo({
      caId: null,
      caEmail: '',
      caName: '',
      caPhone: '',
      caFax: '',
      caAddress: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNurseCaseManagerInfo({
      ...nurseCaseManagerInfo,
      [name]: value,
    });
  };

  const createNurseCaseManager = () => {
    const { caEmail, caName, caPhone, caFax, caAddress } = nurseCaseManagerInfo;

    const nurseCaseManagerPayload = {
      email: caEmail,
      name: caName,
      phone: caPhone,
      title: 'NURSECASEMANAGER',
      fax: caFax,
      address: caAddress,
      userId: props.currentUser.id,
    };

    ClaimsAdministratorServices.createClaimsAdministrator(
      nurseCaseManagerPayload
    ).then((response) => {
      window.alert(response.data.message);
      getNurseCaseManagers();
      handleClose();
    });
  };

  const updateNurseCaseManager = () => {
    const { caId, caEmail, caName, caPhone, caFax, caAddress } =
      nurseCaseManagerInfo;

    const nurseCaseManagerPayload = {
      id: caId,
      email: caEmail,
      name: caName,
      phone: caPhone,
      title: 'NURSECASEMANAGER',
      fax: caFax,
      address: caAddress,
      userId: props.currentUser.id,
    };

    ClaimsAdministratorServices.updateClaimsAdministrator(
      nurseCaseManagerPayload
    ).then((response) => {
      window.alert(response.data.message);
      getNurseCaseManagers();
      handleClose();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (manageType === 'ADD') {
      createNurseCaseManager();
    } else {
      updateNurseCaseManager();
    }
  };

  return (
    <div>
      <Card square>
        <CardHeader
          action={
            <Tooltip title="Add NurseCaseManager">
              <IconButton
                aria-label="Add NurseCaseManager"
                onClick={() => handleClickOpen("ADD", "")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
          title="Nurse Case Manager"
        />

        <NurseCaseManagerList
          nurseCaseManagerList={nurseCaseManagerList}
          handleClickOpen={handleClickOpen}
          getNurseCaseManagers={getNurseCaseManagers}
          props={props}
        />
      </Card>

      <NurseCaseManagerManageInfo
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        nurseCaseManagerInfo={nurseCaseManagerInfo}
        manageType={manageType}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(NurseCaseManager);
